import { useEffect } from "react";
import $ from "jquery";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js"; // Importez Bootstrap + Popper.js
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5";

const useDataTable = ({
  tableRef,
  columns,
  data = [],
  url = null,
  actionCallback,
  isColumnHidden = false,
  isColumnHiddenClass = ".toggle-vis",
  isFilterColumn = false,
  isFooter = false,
  isMultilang = false,
}) => {
  useEffect(() => {
    // Gestionnaire d'événements pour les actions
    const handleActionClick = (event) => {
      const target = event.target;

      // Vérifier si l'élément cliqué a un ID structuré
      if (target.id && target.id.startsWith("action-row-")) {
        event.preventDefault();

        // Extraire les informations de l'ID
        const [rowId, action, method] = target.id.split("-").slice(2);

        // Appeler actionCallback avec les données de l'élément cliqué
        if (typeof actionCallback === "function") {
          actionCallback({ id: rowId, method });
        }
      }
    };

    // Attacher handleActionClick au document
    document.body.addEventListener("click", handleActionClick);

    // Initialiser DataTable
    setTimeout(() => {
      let datatableObj = {
        dom: '<"row align-items-center"<"col-md-6" l><"col-md-6" f>><"table-responsive my-3" rt><"row align-items-center" <"col-md-6" i><"col-md-6" p>><"clear">',
        autoWidth: false,
        columns: columns,
        destroy: true,
      };

      if (url) {
        datatableObj = {
          ...datatableObj,
          processing: true,
          serverSide: true,
          ajax: {
            url: url,
          },
        };
      }

      if (data) {
        datatableObj = {
          ...datatableObj,
          data: data,
        };
      }

      if (isFooter) {
        datatableObj = {
          ...datatableObj,
          initComplete: function () {
            const footerRow = document.createElement("tr");
            columns.forEach((column) => {
              const footerCell = document.createElement("th");
              footerCell.append(column.title);
              footerRow.append(footerCell);
            });

            $(tableRef.current).append($("<tfoot>").append(footerRow));
          },
        };
      }

      if (isFilterColumn) {
        datatableObj = {
          ...datatableObj,
          initComplete: function () {
            const footerRow = document.createElement("tr");
            const table = $(tableRef.current).DataTable();
            columns.forEach((column) => {
              const footerCell = document.createElement("td");
              const input = document.createElement("input");
              input.type = "text";
              input.className = "form-control form-control-sm";
              input.placeholder = column.title;
              input.addEventListener("keyup", (event) => {
                const columnIndex = columns.findIndex(
                  (c) => c.title === column.title
                );
                table.columns(columnIndex).search(event.target.value).draw();
              });
              footerCell.append(input);
              footerRow.append(footerCell);
            });

            $(tableRef.current).append($("<tfoot>").append(footerRow));
          },
        };
      }

      function languageSelect() {
        if (tableRef.current) {
          return Array.from(document.querySelector("#langSelector").options)
            .filter((option) => option.selected)
            .map((option) => option.getAttribute("data-path"));
        }
      }

      const setMultiLang = () => {
        datatableObj = {
          ...datatableObj,
          language: {
            url: languageSelect(),
          },
        };
      };

      if (isMultilang) {
        setMultiLang();
      }

      // Initialiser DataTable
      let datatable = $(tableRef.current).DataTable(datatableObj);

      // Gérer les événements de clic sur les actions dans le tableau
      if (typeof actionCallback === "function") {
        $(datatable.table().body()).on(
          "click",
          '[id^="action-row-"]',
          function (event) {
            event.preventDefault();
            const id = this.id; // Récupérer l'ID de l'élément cliqué
            const [rowId, action, method] = id.split("-").slice(2);

            if (typeof actionCallback === "function") {
              actionCallback({ id: rowId, method });
            }
          }
        );

        $(datatable.table().header()).on(
          "click",
          '[id^="action-row-"]',
          function (event) {
            event.preventDefault();
            const id = this.id; // Récupérer l'ID de l'élément cliqué
            const [rowId, action, method] = id.split("-").slice(2);

            if (typeof actionCallback === "function") {
              actionCallback({ id: rowId, method });
            }
          }
        );
      }

      // Initialiser les popovers
      const popoverTriggerList = document.querySelectorAll(
        '[data-bs-toggle="popover"]'
      );
      const popoverList = [...popoverTriggerList].map(
        (popoverTriggerEl) =>
          new bootstrap.Popover(popoverTriggerEl, {
            trigger: "focus",
            html: true,
            container: "body",
          })
      );

      // Attacher les événements après l'affichage du popover
      const handlePopoverShown = (event) => {
        const popoverContent = document.querySelector(".popover-body");
        if (popoverContent) {
          // Attacher les événements de clic aux éléments du popover
          popoverContent.addEventListener("click", (event) => {
            const target = event.target;
            if (target.id && target.id.startsWith("action-row-")) {
              event.preventDefault();
              const [rowId, action, method] = target.id.split("-").slice(2);

              if (typeof actionCallback === "function") {
                actionCallback({ id: rowId, method });
              }
            }
          });
        }
      };

      // Ajouter un écouteur pour l'événement shown.bs.popover
      popoverTriggerList.forEach((trigger) => {
        trigger.addEventListener("shown.bs.popover", handlePopoverShown);
      });

      if (isColumnHidden) {
        $(isColumnHiddenClass).on("click", function (e) {
          e.preventDefault();
          const column = datatable.column($(this).attr("data-column"));
          column.visible(!column.visible());
        });
      }

      if (isMultilang) {
        document
          .querySelector("#langSelector")
          .addEventListener("change", () => {
            $(tableRef.current).DataTable().destroy();
            setMultiLang();
            datatable = $(tableRef.current).DataTable(datatableObj);
          });
      }

      // Nettoyer les écouteurs d'événements
      return () => {
        popoverList.forEach((popover) => popover.dispose());
        popoverTriggerList.forEach((trigger) => {
          trigger.removeEventListener("shown.bs.popover", handlePopoverShown);
        });
        document.body.removeEventListener("click", handleActionClick);
        if ($.fn.DataTable.isDataTable(tableRef.current)) {
          $(tableRef.current).DataTable().destroy();
        }
        $(tableRef.current).empty();
      };
    }, 0);
  }, [
    tableRef,
    columns,
    data,
    url,
    actionCallback,
    isColumnHidden,
    isColumnHiddenClass,
    isFilterColumn,
    isFooter,
    isMultilang,
  ]);
};

export default useDataTable;
