import React, { memo, Fragment, useRef, useState, useEffect } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Card from "../../../components/bootstrap/card";
import ActivityIndicator from "../../../components/partials/dashboard/mainstyle/activity-indicator";
import { Sweetalert } from "../../../components/partials/dashboard/mainstyle/sweet-alert";
import useDataTable from "../../../components/hooks/useDatatable";

const DeviceList = memo(() => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [showBanModal, setShowBanModal] = useState(false);
  const [banType, setBanType] = useState("ip");
  const [banReason, setBanReason] = useState("");
  const [banDuration, setBanDuration] = useState("1h");
  const [banValue, setBanValue] = useState("");
  const [validated, setValidated] = useState(false);

  const handleCloseBanModal = () => setShowBanModal(false);
  const handleShowBanModal = () => setShowBanModal(true);

  const handleBanTypeChange = (event) => {
    setBanType(event.target.value);
  };

  const handleBanReasonChange = (event) => {
    setBanReason(event.target.value);
  };

  const handleBanDurationChange = (selectedOption) => {
    setBanDuration(selectedOption.value);
  };

  const handleBanValueChange = (event) => {
    setBanValue(event.target.value);
  };

  const banTypeOptions = [
    { value: "ip", label: "Adresse IP" },
    { value: "device", label: "Appareil" },
  ];

  const banDurationOptions = [
    { value: "1h", label: "1 heure" },
    { value: "24h", label: "24 heures" },
    { value: "7d", label: "7 jours" },
    { value: "30d", label: "30 jours" },
    { value: "permanent", label: "Permanent" },
  ];

  const columns = useRef([
    { data: "id", title: "ID" },
    { data: "type", title: "Type" },
    { data: "value", title: "Valeur" },
    { data: "reason", title: "Raison" },
    { data: "duration", title: "Durée" },
    { data: "bannedBy", title: "Banni par" },
    { data: "bannedAt", title: "Banni le" },
    {
      data: null,
      orderable: false,
      searchable: false,
      title: "Action",
      render: function (row) {
        return `
          <div class="d-flex align-items-center list-user-action justify-content-between">
            <div>  
              <a title="Débannir" id="action-row-${row.id}-method-unban" class="btn btn-sm btn-icon btn-success" data-bs-toggle="tooltip" data-bs-placement="top" href="#" aria-label="Unban" data-bs-original-title="Unban">
                <span class="btn-inner">
                  <svg xmlns="http://www.w3.org/2000/svg" class="icon-32" width="32" height="32" viewBox="0 0 24 24" fill="currentColor"><path d="M6.26489 3.80698L7.41191 5.44558C5.34875 6.89247 4 9.28873 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 9.28873 18.6512 6.89247 16.5881 5.44558L17.7351 3.80698C20.3141 5.61559 22 8.61091 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 8.61091 3.68594 5.61559 6.26489 3.80698ZM11 12V2H13V12H11Z"></path></svg>
                </span>
              </a>
            </div> 
          </div>`;
      },
    },
  ]);

  const listtableRef = useRef(null);

  const handleBanSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }

    handleCloseBanModal();
    setIsLoading(true);
    setLoadingText("Bannissement en cours...");

    // Simuler une requête API
    setTimeout(() => {
      const newBan = {
        id: tableData.length + 1,
        type: banType,
        value: banValue,
        reason: banReason,
        duration: banDuration,
        bannedBy: "Admin",
        bannedAt: new Date().toLocaleString(),
      };

      setTableData([...tableData, newBan]);
      setIsLoading(false);
      Sweetalert("success", "Succès ...", "Bannissement réussi!", "");

      setBanValue("");
    }, 2000);

    setValidated(false);
  };

  const handleUnban = (row) => {
    setIsLoading(true);
    setLoadingText("Débannissement en cours...");

    setTimeout(() => {
      const updatedTableData = tableData.filter((item) => item.id !== row.id);
      setTableData(updatedTableData);
      setIsLoading(false);
      Sweetalert("success", "Succès ...", "Débannissement réussi!", "");
    }, 2000);
  };

  useEffect(() => {
    setTableData([
      {
        id: 1,
        type: "ip",
        value: "192.168.1.1",
        reason: "Tentative de connexion suspecte",
        duration: "24h",
        bannedBy: "Admin",
        bannedAt: "2023-10-01 12:00:00",
      },
      {
        id: 2,
        type: "device",
        value: "Device123",
        reason: "Activité malveillante",
        duration: "7d",
        bannedBy: "Admin",
        bannedAt: "2023-10-02 14:30:00",
      },
    ]);
  }, []);

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: tableData,
    actionCallback: ({ id, method }) => {
      const row = tableData.find((item) => item.id.toString() === id);
      if (!row) return;

      switch (method) {
        case "unban":
          handleUnban(row);
          break;
        default:
          console.warn("Méthode non reconnue:", method);
      }
    },
  });

  return (
    <Fragment>
      <Row>
        {isLoading && (
          <ActivityIndicator
            title={"Liste des bannissements"}
            text={loadingText}
          />
        )}
        <Col style={{ display: isLoading ? "none" : "block" }} sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Liste des bannissements</h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <Row className="">
                <Col className="text-end mx-4">
                  <Button
                    variant="btn btn-danger"
                    type="button"
                    className=""
                    onClick={handleShowBanModal}
                  >
                    <svg
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="icon-24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                      ></path>
                    </svg>{" "}
                    <span>Bannir</span>
                  </Button>
                </Col>
              </Row>

              <div className="table-responsive">
                <table
                  id="datatable-ban"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showBanModal} onHide={handleCloseBanModal}>
        <Modal.Header closeButton>
          <Modal.Title>Bannir une adresse IP ou un appareil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleBanSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Type de bannissement</Form.Label>
              <Form.Group className="d-flex justify-content-between">
                <Form.Check className="d-block">
                  <Form.Check.Input
                    className="me-2"
                    type="radio"
                    name="banType"
                    id="banType1"
                    value={"ip"}
                    checked={banType === "ip"}
                    onChange={handleBanTypeChange}
                  />
                  <Form.Check.Label className="me-3" htmlFor="banType1">
                    Adresse IP
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="d-block">
                  <Form.Check.Input
                    className="me-2"
                    type="radio"
                    name="banType"
                    id="banType2"
                    value={"device"}
                    checked={banType === "device"}
                    onChange={handleBanTypeChange}
                  />
                  <Form.Check.Label htmlFor="banType2">
                    Appareil
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                {banType === "ip" ? "Adresse IP" : "Identifiant de l'appareil"}
              </Form.Label>
              <Form.Control
                required
                type="text"
                value={banValue}
                onChange={handleBanValueChange}
                placeholder={
                  banType === "ip"
                    ? "Entrez l'adresse IP"
                    : "Entrez l'identifiant de l'appareil"
                }
              />
              <Form.Control.Feedback type="invalid">
                {banType === "ip"
                  ? "Veuillez entrer l'adresse IP"
                  : "Veuillez entrez l'identifiant de l'appareil"}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Raison du bannissement</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={banReason}
                onChange={handleBanReasonChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Veuillez enter la raison
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Durée du bannissement</Form.Label>
              <Select
                data-trigger
                name="choices-single-default"
                id="banDuration"
                options={banDurationOptions}
                value={banDurationOptions.find(
                  (opt) => opt.value === banDuration
                )}
                onChange={handleBanDurationChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Bannir
            </Button>{" "}
            <Button variant="danger" onClick={handleCloseBanModal}>
              Annuler
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
});

DeviceList.displayName = "DeviceList";
export default DeviceList;
