import React, { memo, Fragment, useState } from "react";

//react-bootstrap
import { Row, Col, Image, Form, Button } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//router
import { Link, useNavigate } from "react-router-dom";

// img
import avatars1 from "../../../assets/images/avatars/01.png";
import avatars2 from "../../../assets/images/avatars/avtar_1.png";
import avatars3 from "../../../assets/images/avatars/avtar_2.png";
import avatars4 from "../../../assets/images/avatars/avtar_3.png";
import avatars5 from "../../../assets/images/avatars/avtar_4.png";
import avatars6 from "../../../assets/images/avatars/avtar_5.png";

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Select from "react-select";
import ActivityIndicator from "../../../components/partials/dashboard/mainstyle/activity-indicator";
import { Sweetalert } from "../../../components/partials/dashboard/mainstyle/sweet-alert";

const TicketAdd = memo(() => {
  const { t } = useTranslation();
  let history = useNavigate();
  const [validated, setValidated] = useState(false);
  const [isLooading, setIsLooading] = useState(false);

  const userOptions = [
    { value: "1", label: "Élodie Martin" },
    { value: "2", label: "Théo Lambert" },
    { value: "3", label: "Camille Dubois" },
    { value: "4", label: "Lucas Moreau" },
    { value: "5", label: "Jules Fontaine" },
    { value: "6", label: "Léa Girard" },
  ];
  const [typeCanalOptions, setTypeCanalOptions] = useState([
    { value: "sms", label: "SMS" },
    { value: "email", label: "Email" },
    { value: "whatsapp", label: "Whatsapp" },
  ]);

  const handleSubmitAddTicket = (event) => {
    setIsLooading(true);

    setTimeout(() => {
      // Code à exécuter après le délai
      setIsLooading(false);
      setValidated(false);
      Sweetalert("success", "Succès ...", "Ticket enregistré!", "");
    }, 5000);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const handleGoBack = () => {
    history("/dashboard/messaging/ticket-list");
  };

  return (
    <Fragment>
      <Row>
        {isLooading ? (
          <ActivityIndicator
            title={"Add New Ticket"}
            text={"Ticket en cours de création ..."}
          />
        ) : (
          <Col>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmitAddTicket}
              className="row g-3 needs-validation"
            >
              <Col xl="3" lg="4" className="">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Add New Ticket</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div>
                      <Form.Group className="form-group">
                        <div className="profile-img-edit position-relative">
                          <Image
                            className="theme-color-default-img  profile-pic rounded avatar-100"
                            src={avatars1}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-purple-img profile-pic rounded avatar-100"
                            src={avatars2}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-blue-img profile-pic rounded avatar-100"
                            src={avatars3}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-green-img profile-pic rounded avatar-100"
                            src={avatars5}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-yellow-img profile-pic rounded avatar-100"
                            src={avatars6}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-pink-img profile-pic rounded avatar-100"
                            src={avatars4}
                            alt="profile-pic"
                          />
                          <div className="upload-icone bg-primary">
                            <svg
                              className="upload-button icon-14"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#ffffff"
                                d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                              />
                            </svg>
                            <Form.Control
                              className="file-upload"
                              type="file"
                              accept="image/*"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="img-extension mt-3">
                          <div className="d-inline-block align-items-center">
                            <span>Only</span> <Link to="#">.jpg</Link>{" "}
                            <Link to="#">.png</Link> <Link to="#">.jpeg</Link>{" "}
                            <span>allowed</span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>User Role:</Form.Label>
                        <select
                          name="type"
                          className="selectpicker form-control"
                          data-style="py-0"
                          required
                          disabled
                        >
                          <option value="">Select</option>
                          <option>Administrateur</option>
                          <option>Support client</option>
                          <option>Conducteur</option>
                          <option>Passager</option>
                          <option>Expéditeur </option>
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Please choose a role
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl="9" lg="8">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">New Ticket Information</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="new-user-info">
                      <div>
                        <div className="row g-3 needs-validation">
                          <Row className="mt-3">
                            <Col className="mb-3">
                              <Form.Label htmlFor="user">
                                Utilisateur
                              </Form.Label>
                              <Select
                                data-trigger
                                name="choices-single-default"
                                id="user"
                                options={userOptions}
                              />
                            </Col>

                            <Col className="mb-3">
                              <Form.Label htmlFor="typeOfCanal">
                                Type de canal
                              </Form.Label>
                              <Select
                                data-trigger
                                name="choices-single-default"
                                id="typeOfCanal"
                                options={typeCanalOptions}
                              />
                            </Col>
                          </Row>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="message">
                              Write the message
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              id="message"
                              placeholder="Enter The message"
                              rows={"5"}
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the message
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <hr />

                        <Button
                          type="submit"
                          variant="btn btn-danger me-5"
                          onClick={handleGoBack}
                        >
                          Back
                        </Button>

                        <Button type="submit" variant="btn btn-primary">
                          Save
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Form>
          </Col>
        )}
      </Row>
    </Fragment>
  );
});

TicketAdd.displayName = "TicketAdd";
export default TicketAdd;
