const API_VERSION = "v1"; // Version des API

export const API_ENDPOINTS = {
  // api from admin-controller
  LOGIN: `/api/${API_VERSION}/admin/login`, // integrated
  ADD_USER: `/api/${API_VERSION}/admin/register`, // integrated
  UPDATE_USER: `/api/${API_VERSION}/admin/users/update`, // integrated
  GET_USERS: `/api/${API_VERSION}/admin/users/all`, // integrated
  SEARCH_USERS: `/api/${API_VERSION}/admin/users/filter`,
  DELETE_USER: `/api/${API_VERSION}/admin/users/delete`, // integrated
  DELETE_ADMIN: `/api/${API_VERSION}/admin/delete`, // integrated
  UPDATE_ADMIN_LOGGED: `/api/${API_VERSION}/admin/update`,
  UPDATE_ADMIN: `/api/${API_VERSION}/admin/update/admin`, //integrated
  GET_NEW_TOKEN: `/api/${API_VERSION}/admin/token/refresh`,
  GET_ADMIN: `/api/${API_VERSION}/admin/id`,
  GET_ADMINS: `/api/${API_VERSION}/admin/all`, // integrated
  ACTIVATE_DEACTIVATE_USER: `/api/${API_VERSION}/admin/users/activate`, // integrated
  ACTIVATE_DEACTIVATE_ADMIN: `/api/${API_VERSION}/admin/activate`, // integrated
  REJECT_USER_AS_CONFIRMED_DRIVER: `/api/${API_VERSION}/admin/users/certify/reject`,
  CERTIFY_USER_AS_CONFIRMED_DRIVER: `/api/${API_VERSION}/admin/users/certify/accept`,

  // api from logout-controllerADD_ROLE
  LOG_OUT: `/api/${API_VERSION}/auth/logout`,

  // api from verification-request-controller
  ALL_VERIFICATION_REQUEST: `/api/${API_VERSION}/verification/all`,
  GET_USER_VERIFICATION_REQUEST: `/api/${API_VERSION}/verification/id`,

  // api from role-controlleur
  ADD_ROLE: `/api/${API_VERSION}/role/save`,
  GET_ROLES: `/api/${API_VERSION}/role/all`, 
  GET_PERMISSIONS: `/api/${API_VERSION}/role/all/permissions`,
  UPDATE_ROLE: `/api/${API_VERSION}/role/update/permissions/{roleName}`,
  DELETE_ROLE: `/api/${API_VERSION}/role/delete/{roleName}`
  // Ajoutez d'autres endpoints ici
};
