import React, { useEffect } from "react";
//scss
import "./assets/modules/landing-pages/scss/landing-pages.scss";
import "shepherd.js/dist/css/shepherd.css";
import "flatpickr/dist/flatpickr.css";
import "choices.js/public/assets/styles/choices.min.css";
import "./assets/scss/hope-ui.scss";
import "./assets/scss/pro.scss";
import "./assets/scss/custom.scss";
import "./assets/scss/rtl.scss";
import "./assets/scss/customizer.scss";
import "./assets/custom/scss/custom.scss";

// Redux Selector / Action
import { useDispatch, useSelector } from "react-redux";

// import state selectors
import { setSetting } from "./store/setting/actions";
import "./i18n"; // Importez i18n pour l'initialiser
import { setUser } from "./services/http/actions/userActions";

function App({ children }) {
  const dispatch = useDispatch();

  dispatch(setSetting());
  dispatch(setUser());

  return <div className="App">{children}</div>;
}

export default App;
