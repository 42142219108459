import React, { memo, Fragment, useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activateUser,
  deleteUser,
  getAllUsers,
  addRole,
  searchUsers,
} from "../../../services/http/reducers/userReducers";
import { accessToken } from "../../../store/setting/selectors";
//react-bootstrap
import { Row, Col, Dropdown, Modal } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../components/hooks/useDatatable";

import $ from "jquery";
// img
import shap1 from "../../../assets/images/shapes/01.png";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import ActivityIndicator from "../../../components/partials/dashboard/mainstyle/activity-indicator";

import { Sweetalert } from "../../../components/partials/dashboard/mainstyle/sweet-alert";

import { format } from "date-fns";
import { fr } from "date-fns/locale";
import Swal from "sweetalert2";
import { setUserVerificationData } from "../../../services/http/actions/userActions";

const UserListMobile = memo(() => {
  const dispatch = useDispatch();
  const token = useSelector(accessToken);
  // const { data: users, operation: { loading, error } } = useSelector((state) => state.user.user);
  const {
    manage: { userListMobile },
    operation: { loading, error },
  } = useSelector((state) => state.user.user);
  const [tableData, setTableData] = useState([]);

  const { t } = useTranslation();
  let history = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [show, setShow] = useState(false);

  const userNameRef = useRef(null);
  const emailRef = useRef(null);
  const dateOfBirthRef = useRef(null);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const [isDriver, setIsDriver] = useState(null);
  const [isPassenger, setIsPassenger] = useState(null);
  const [isExpeditor, setIsExpeditor] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //footer: '<a href="">Voir les détails de l\'utilisateur?</a>',

  const typeUserOptions = [
    { value: "conducteur", label: "Conducteur" },
    { value: "passager", label: "Passager" },
    { value: "expéditeur", label: "Expéditeur" },
  ];

  const typeExportFormatOptions = [
    { value: "pdf", label: "PDF" },
    { value: "csv", label: "CSV" },
    { value: "xls", label: "XLS" },
  ];

  const columns = useRef([
    {
      data: null,
      title: "Profile",
      render: function (row) {
        return `
          <img class="bg-primary-subtle rounded img-fluid avatar-40 me-3" src="${row.image}" alt="profile" loading="lazy">`;
      },
    },
    {
      data: null,
      title: t("typeOfUser"),
      render: function (row) {
        return `
					<span>${row.role}</span>
					${
            row.isCertifiedDriver && row.isDriver
              ? `
						<svg
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							width="24" height="24"
							viewBox="0 0 24 24"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M20.3992 9.14611L21.1194 9.86611C21.6897 10.4261 21.9998 11.1861 21.9998 11.9861C22.0098 12.7861 21.6997 13.5471 21.1395 14.1161C21.1328 14.1234 21.1261 14.1299 21.1194 14.1363C21.1161 14.1396 21.1128 14.1428 21.1094 14.1461L20.3992 14.8561C20.1191 15.1361 19.959 15.5161 19.959 15.9171V16.9461C19.959 18.6061 18.6085 19.9571 16.9479 19.9571H15.9176C15.5174 19.9571 15.1373 20.1161 14.8572 20.3961L14.1369 21.1161C13.5467 21.7071 12.7764 21.9961 12.0061 21.9961C11.2359 21.9961 10.4656 21.7071 9.87537 21.1271L9.14511 20.3961C8.865 20.1161 8.48487 19.9571 8.08472 19.9571H7.05435C5.39375 19.9571 4.04326 18.6061 4.04326 16.9461V15.9171C4.04326 15.5161 3.8832 15.1361 3.6031 14.8461L2.88284 14.1361C1.71241 12.9671 1.70241 11.0561 2.87283 9.87711L3.6031 9.14611C3.8832 8.86611 4.04326 8.48611 4.04326 8.07611V7.05611C4.04326 5.39611 5.39375 4.04711 7.05435 4.04711H8.08472C8.48487 4.04711 8.865 3.88611 9.14511 3.60611L9.86537 2.88611C11.0358 1.70711 12.9465 1.70711 14.1269 2.87711L14.8572 3.60611C15.1373 3.88611 15.5174 4.04711 15.9176 4.04711H16.9479C18.6085 4.04711 19.959 5.39611 19.959 7.05611V8.08711C19.959 8.48611 20.1191 8.86611 20.3992 9.14611ZM9.42521 15.4461C9.66529 15.4461 9.88537 15.3561 10.0454 15.1861L15.1873 10.0471C15.5274 9.70711 15.5274 9.14611 15.1873 8.80611C14.8472 8.46711 14.297 8.46711 13.9569 8.80611L8.81499 13.9461C8.47486 14.2861 8.47486 14.8461 8.81499 15.1861C8.97504 15.3561 9.19512 15.4461 9.42521 15.4461ZM13.6968 14.5661C13.6968 15.0561 14.0869 15.4461 14.5771 15.4461C15.0572 15.4461 15.4474 15.0561 15.4474 14.5661C15.4474 14.0871 15.0572 13.6961 14.5771 13.6961C14.0869 13.6961 13.6968 14.0871 13.6968 14.5661ZM9.43521 8.55611C9.91539 8.55611 10.3055 8.94611 10.3055 9.42611C10.3055 9.91711 9.91539 10.3061 9.43521 10.3061C8.95504 10.3061 8.55489 9.91711 8.55489 9.42611C8.55489 8.94611 8.95504 8.55611 9.43521 8.55611Z"
								fill="green"
							></path>
						</svg>
					`
              : ""
          }
				`;
      },
    },

    { data: "name", title: t("username") },
    { data: "contact", title: "Contact" },
    { data: "email", title: t("email") },
    {
      data: null,
      title: "Status",
      render: function (row) {
        return `<span class="badge bg-${row.color}">${row.status}</span>`;
      },
    },

    {
      data: null,
      title: "Join Date",
      render: function (row) {
        try {
          const date = new Date(row.date);
          return format(date, "dd/MM/yyyy HH:mm:ss", { locale: fr }); // Format français
        } catch (e) {
          return "N/A"; // Format français
        }
      },
    },
    {
      data: null,
      orderable: false,
      searchable: false,
      title: "Action",
      render: function (row) {
        let toggleActivationButton = "";
        if (row.status === t("disabled")) {
          toggleActivationButton = `
            <a title="Activer" id="action-row-${row.id}-method-disable" class="btn btn-sm btn-icon btn-success" data-bs-toggle="tooltip" data-bs-placement="top" href="#" aria-label="Activate" data-bs-original-title="Activate">
              <span class="btn-inner">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" width="24" height="24" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M7.67 2H16.34C19.73 2 22 4.38 22 7.92V16.091C22 19.62 19.73 22 16.34 22H7.67C4.28 22 2 19.62 2 16.091V7.92C2 4.38 4.28 2 7.67 2ZM11.43 14.99L16.18 10.24C16.52 9.9 16.52 9.35 16.18 9C15.84 8.66 15.28 8.66 14.94 9L10.81 13.13L9.06 11.38C8.72 11.04 8.16 11.04 7.82 11.38C7.48 11.72 7.48 12.27 7.82 12.62L10.2 14.99C10.37 15.16 10.59 15.24 10.81 15.24C11.04 15.24 11.26 15.16 11.43 14.99Z" fill="currentColor"></path></svg>
              </span>
            </a>
          `;
        } else if (row.status === t("enabled")) {
          toggleActivationButton = `
            <a title="Désactiver" id="action-row-${row.id}-method-disable" class="btn btn-sm btn-icon btn-danger" data-bs-toggle="tooltip" data-bs-placement="top" href="#" aria-label="Delete" data-bs-original-title="Delete">
              <span class="btn-inner">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" width="24" height="24" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M7.67 1.99927H16.34C19.73 1.99927 22 4.37927 22 7.91927V16.0903C22 19.6203 19.73 21.9993 16.34 21.9993H7.67C4.28 21.9993 2 19.6203 2 16.0903V7.91927C2 4.37927 4.28 1.99927 7.67 1.99927ZM15.01 14.9993C15.35 14.6603 15.35 14.1103 15.01 13.7703L13.23 11.9903L15.01 10.2093C15.35 9.87027 15.35 9.31027 15.01 8.97027C14.67 8.62927 14.12 8.62927 13.77 8.97027L12 10.7493L10.22 8.97027C9.87 8.62927 9.32 8.62927 8.98 8.97027C8.64 9.31027 8.64 9.87027 8.98 10.2093L10.76 11.9903L8.98 13.7603C8.64 14.1103 8.64 14.6603 8.98 14.9993C9.15 15.1693 9.38 15.2603 9.6 15.2603C9.83 15.2603 10.05 15.1693 10.22 14.9993L12 13.2303L13.78 14.9993C13.95 15.1803 14.17 15.2603 14.39 15.2603C14.62 15.2603 14.84 15.1693 15.01 14.9993Z" fill="currentColor"></path></svg>
              </span>
            </a>
          `;
        }
        return `
          <div class="d-flex align-items-center list-user-action justify-content-between">
            <div>  
              <a title="Détails" id="action-row-${row.id}-method-details" class="btn btn-sm btn-icon btn-info" data-bs-toggle="tooltip" data-bs-placement="top" href="#" aria-label="Add" data-bs-original-title="Add">
                <span class="btn-inner">
                  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>                                         
                </span>
              </a>
            </div> 
            <div>
              ${toggleActivationButton} 
            </div> 
            
            <div>
              <button
                title="Plus d'actions"
                class="btn btn-sm btn-icon btn-warning popover-trigger"
                type="button"
                data-bs-toggle="popover"
                data-bs-html="true"
                data-id="${row.id}"
                data-bs-content='
                  <a id="action-row-${row.id}-method-modify" class="dropdown-item h6">Modifier</a>
                  <a id="action-row-${row.id}-method-verify" class="dropdown-item h6">Vérifier</a>
                  <a id="action-row-${row.id}-method-delete" class="dropdown-item h6">Supprimer</a>
                '
              >
                <span class="btn-inner">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
              </button>
            </div>
          </div>`;
      },
    },
  ]);

  useEffect(() => {
    const checkRole = (user) => {
      if (user.isPassenger === true) {
        return t("passenger");
      }
      if (user.isDriver === true) {
        return t("driver");
      }
      if (user.isExpeditor === true) {
        return t("sender");
      }
    };

    const defineColor = (user) => {
      if (user.accountActif) {
        return "success";
      } else {
        return "danger";
      }
    };

    if (Array.isArray(userListMobile)) {
      const formattedUsers = userListMobile.map((user) => ({
        id: user.id,
        image: user.picture, // Replace with actual image if available
        name: user.username,
        contact: user.phoneNumber || "N/A", // Replace with actual contact if available
        email: user.email,
        status: user.accountActif ? t("enabled") : t("disabled"),
        date: user.createdAt || "N/A", // Replace with actual date if available
        color: defineColor(user), // Replace with actual color if available
        role: checkRole(user),
        isCertifiedDriver: user.isCertifiedDriver,
        dateOfBirth: user.dateOfBirth,
        accountActif: user.accountActif,
        isDriver: user.isDriver,
        isPassenger: user.isPassenger,
        isExpeditor: user.isExpeditor,
      }));
      setTableData(formattedUsers);
    }
  }, [userListMobile, t]);

  const listtableRef = useRef(null);

  const handleSearchClick = (e) => {
    e.preventDefault();

    //Construction des paramètres
    const userName = userNameRef.current.value;
    const email = emailRef.current.value;
    const dateOfBirth = dateOfBirthRef.current.value;
    const startDate = startDateRef.current.value;
    const endDate = endDateRef.current.value;

    let parameters = {};

    if (userName) {
      parameters.username = userName;
    }

    if (email) {
      parameters.email = email;
    }

    if (dateOfBirth) {
      parameters.dateOfBirth = dateOfBirth;
    }

    if (startDate) {
      parameters.startDate = startDate;
    }

    if (endDate) {
      parameters.endDate = endDate;
    }

    if (isDriver) {
      parameters.isDriver = isDriver;
    }

    if (isPassenger) {
      parameters.isPassenger = isPassenger;
    }

    if (isExpeditor) {
      parameters.isExpeditor = isExpeditor;
    }

    console.log("------------- url parameter");
    console.log(parameters);

    if (token) {
      setLoadingText("Recherche des utilisateurs en cours ...");

      if (Object.keys(parameters).length !== 0) {
        setLoadingText("Recherche des utilisateurs en cours ...");
        dispatch(searchUsers({ token: token, parameters: parameters }));
      } else {
        setLoadingText("Chargement des utilisateurs en cours ...");
        dispatch(getAllUsers(token));
      }
    }
  };

  const handleExportUser = (event) => {
    event.preventDefault();
    handleClose();
    setIsLoading(true);
    setLoadingText("Exportation des utilisateurs en cours...");

    setTimeout(() => {
      // Code à exécuter après le délai
      setIsLoading(false);
      Sweetalert("success", "Succès ...", "Utilisateurs exportés!", "");
    }, 5000);
  };

  // Fonction pour afficher les détails d'un utilisateur
  const handleDetails = (row) => {
    console.log("Détails de l'utilisateur:", row);
    // Ajoutez ici la logique pour afficher les détails (par exemple, ouvrir un modal)

    history("/dashboard/app/user-profile", { state: row });
  };

  // Function for disable and enable a user
  const handleDisable = (row) => {
    const status = row.accountActif;
    // Créer un objet FormData
    const formData = new FormData();
    formData.append("status", !status);

    console.log("status avant : " + status);
    console.log("status à envoyer : " + !status);

    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: status
        ? `Désactivation de l'utiisateur ${row.name}!`
        : `Activation de l'utiisateur ${row.name}!`,
      icon: "warning",
      showCancelButton: true,
      backdrop: `rgba(60,60,60,0.8)`,
      confirmButtonText: status ? "Oui, désactiver le!" : "Oui, activer le!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoadingText(
          status
            ? "Désactivation de l'utilisateur en cours ..."
            : "Activation de l'utilisateur en cours ..."
        );
        dispatch(
          activateUser({
            status: !status,
            userId: row.id,
            token: token,
            formData: formData,
          })
        );
      }
    });
  };

  // Function for modify a user
  const handleModify = (row) => {
    history("/dashboard/app/user-edit", { state: row });
  };

  // Function for user verification
  const handleVerify = (row) => {
    dispatch(setUserVerificationData(row));

    history("/file-manager/user-file", { state: row });
  };

  // Function for deleting a user
  const handleDelete = (row) => {
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: `Suppression de l'utiisateur ${row.name}!`,
      icon: "warning",
      showCancelButton: true,
      backdrop: `rgba(60,60,60,0.8)`,
      confirmButtonText: "Oui, supprimer le!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          deleteUser({
            userId: row.id,
            token: token,
          })
        );
      }
    });
  };

  // Function for resetting password of a user
  const handleResetPassWord = (row) => {
    setIsLoading(true);
    setLoadingText(
      "Réinitialisation du mot de passe de l'utilisateur " +
        row.name +
        " en cours ..."
    );

    //Call API here
    setTimeout(() => {
      setIsLoading(false);
      setLoadingText("");

      Sweetalert("success", "Succès ...", "Mot de passe réinitialisé!", "");
    }, 5000);
  };

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: tableData,
    actionCallback: ({ id, method }) => {
      const row = tableData.find((item) => item.id.toString() === id); // Trouver la ligne correspondante
      if (!row) return;

      // Appeler la fonction correspondante en fonction de la méthode
      switch (method) {
        case "details":
          handleDetails(row);
          break;
        case "disable":
          handleDisable(row);
          break;
        case "modify":
          handleModify(row);
          break;
        case "verify":
          handleVerify(row);
          break;
        case "delete":
          handleDelete(row);
          break;
        case "reset":
          handleResetPassWord(row);
          break;
        default:
          console.warn("Méthode non reconnue:", method);
      }
    },
  });

  return (
    <Fragment>
      <Row>
        {loading && (
          <ActivityIndicator title={t("userlistmobile")} text={loadingText} />
        )}
        <Col style={{ display: loading ? "none" : "block" }} sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">{t("userlistmobile")}</h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <Form className="p-4" onSubmit={handleSearchClick}>
                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="startDate">
                      {t("startDate")}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      id="startDate"
                      ref={startDateRef}
                    />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="endDate">{t("endDate")}</Form.Label>
                    <Form.Control type="date" id="endDate" ref={endDateRef} />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="typeOfUser">
                      {t("typeOfUser")}
                    </Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="typeOfUser"
                      options={typeUserOptions}
                      onChange={(option) => {
                        console.log("------------ voici l'option");
                        console.log(option);
                        switch (option.value) {
                          case "passager":
                            setIsPassenger(true);
                            setIsDriver(null);
                            setIsExpeditor(null);
                            break;
                          case "conducteur":
                            setIsPassenger(null);
                            setIsDriver(true);
                            setIsExpeditor(null);
                            break;
                          case "expéditeur":
                            setIsPassenger(null);
                            setIsDriver(null);
                            setIsExpeditor(true);
                            break;
                          default:
                            setIsPassenger(null);
                            setIsDriver(null);
                            setIsExpeditor(null);
                            console.log("Vous devez faire un choix");
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="userName">
                      {" "}
                      {t("userName")}{" "}
                    </Form.Label>
                    <Form.Control type="text" id="userName" ref={userNameRef} />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="email"> {t("email")} </Form.Label>
                    <Form.Control type="email" id="email" ref={emailRef} />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="birthdate">
                      {t("birthdate")}
                    </Form.Label>
                    <Form.Control
                      type="date"
                      id="birthdate"
                      ref={dateOfBirthRef}
                    />
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className="text-center">
                    <Button
                      variant="btn btn-success"
                      type="submit"
                      className="mt-5"
                    >
                      <span>{t("search")} </span>
                      <svg
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon-24"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          cx="11.7669"
                          cy="11.7666"
                          r="8.98856"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                        <path
                          d="M18.0186 18.4851L21.5426 22"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </Button>

                    {error && (
                      <p className="text-danger mt-3">{error.message}</p>
                    )}
                  </Col>
                </Row>
              </Form>
              <div className="d-flex align-items-center gap-3 my-5 justify-content-end">
                <Button
                  variant="text-center btn btn-primary d-flex gap-2"
                  onClick={handleShow}
                >
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span>Exporter</span>
                </Button>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Exporter des utilisateurs</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label htmlFor="typeOfUser">
                        Format de fichier
                      </Form.Label>
                      <Select
                        data-trigger
                        name="choices-single-default"
                        id="typeOfExportFormat"
                        options={typeExportFormatOptions}
                      />
                    </Form.Group>
                    <Button variant="primary" onClick={handleExportUser}>
                      Exporter
                    </Button>{" "}
                    <Button variant="danger" onClick={handleClose}>
                      Cancel
                    </Button>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

UserListMobile.displayName = "UserListMobile";
export default UserListMobile;
