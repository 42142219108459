// axiosConfig.js
import axios from "axios";
import { useSelector } from "react-redux";
import { accessToken } from "../../store/setting/selectors";

// Créez une instance d'Axios avec une configuration de base
const isLocal = window.location.hostname === "localhost";
const baseURL = isLocal
  ? "http://213.199.51.156:8085"
  : "https://driv-serveur.cgweb.ca/";

console.log("Base URL:", baseURL);

const axiosInstance = axios.create({
  baseURL: baseURL, // Remplacez par l'URL de votre API
  // Timeout de 10 secondes
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercepteurs pour les requêtes
axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercepteurs pour les réponses
axiosInstance.interceptors.response.use(
  (response) => {
    // Vous pouvez traiter la réponse ici avant qu'elle ne soit renvoyée
    return response;
  },
  (error) => {
    // Vous pouvez gérer les erreurs globales ici
    if (error.response && error.response.status === 401) {
      // Rediriger vers la page de login si l'utilisateur n'est pas authentifié
      //window.location.href = "/auth/sign-in";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
