import React, { useState, memo, Fragment } from "react";

// React-bootstrap
import {
	Row,
	Col,
	Image,
	Nav,
	Tab,
	Form,
	Button,
	Table,
	FormCheck,
} from "react-bootstrap";

import VehicleCard from "../components/vehicle-card";
// import { Form, Nav, Tab, FormCheck, Button } from "react-bootstrap";

// Router
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Components
import Card from "../../../components/bootstrap/card";
import Counter from "../components/counter";
import ProductCard from "../components/path-card";
import ShareOffcanvas from "../../../components/partials/components/shareoffcanvas";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Mousewheel } from "swiper";

// Sweetalert2
import Swal from "sweetalert2";

//Img
// import img1 from "../../../assets/modules/e-commerce/images/product-detail/10.png";
// import img2 from "../../../assets/modules/e-commerce/images/product-detail/11.png";
// import img3 from "../../../assets/modules/e-commerce/images/product-detail/12.png";
import img1 from "../../../assets/images/vehicles/toyota.png";
import img2 from "../../../assets/images/vehicles/toyota_1.jpeg";
import img3 from "../../../assets/images/vehicles/toyota_2.jpg";

import img4 from "../../../assets/modules/e-commerce/images/product-detail/13.png";
import img5 from "../../../assets/modules/e-commerce/images/product-detail/14.png";
import img6 from "../../../assets/modules/e-commerce/images/user/04.png";
import img7 from "../../../assets/modules/e-commerce/images/user/01.png";
import img8 from "../../../assets/modules/e-commerce/images/01.png";
import img9 from "../../../assets/modules/e-commerce/images/02.png";
import img10 from "../../../assets/modules/e-commerce/images/03.png";
import img11 from "../../../assets/modules/e-commerce/images/04.png";
import img12 from "../../../assets/modules/e-commerce/images/05.png";
import img13 from "../../../assets/modules/e-commerce/images/06.png";

import Circularprogressbar from "../../../components/circularprogressbar";
import avatars11 from "../../../assets/images/avatars/01.png";
import avatars22 from "../../../assets/images/avatars/avtar_1.png";
import avatars33 from "../../../assets/images/avatars/avtar_2.png";
import avatars44 from "../../../assets/images/avatars/avtar_3.png";
import avatars55 from "../../../assets/images/avatars/avtar_4.png";
import avatars66 from "../../../assets/images/avatars/avtar_5.png";

import shap1 from "../../../assets/images/shapes/01.png";
import shap2 from "../../../assets/images/shapes/02.png";
import shap3 from "../../../assets/images/shapes/03.png";
import shap4 from "../../../assets/images/shapes/04.png";
import shap5 from "../../../assets/images/shapes/06.png";
import shap6 from "../../../assets/images/shapes/01.png";

const AddPath = memo(() => {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [stopPoints, setStopPoints] = useState([]); // Initialize with one stop point

	const [username, setUsername] = useState('');

	const handleInputChange = (event) => {
		setUsername(event.target.value);
	};

	function Sweetalert() {
		Swal.fire({
			icon: "success",
			title: "Added!",
			text: "Your item has been Added to the cart.",
		});
	}

	const addStopPoint = (index) => {
		const newStopPoint = { id: stopPoints.length }; // Create a new stop point with a unique ID
		const updatedStopPoints = [...stopPoints]; // Copy existing stop points
		updatedStopPoints.splice(index + 1, 0, newStopPoint); // Insert the new stop point after the current index
		setStopPoints(updatedStopPoints); // Update state
	};


	const removeStopPoint = (index) => {
		const updatedStopPoints = stopPoints.filter((_, i) => i !== index); // Remove the stop point at the specified index
		setStopPoints(updatedStopPoints); // Update state
	};

	const { t } = useTranslation();
	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<Row className="align-items-center">
								<Col lg="12">
									<div className="card-body">
										{/* <p>Creating basic google map</p> */}
										<iframe className="w-100" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3902543.2003194243!2d-118.04220880485131!3d36.56083290513502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80be29b9f4abb783%3A0x4757dc6be1305318!2sInyo%20National%20Forest!5e0!3m2!1sen!2sin!4v1576668158879!5m2!1sen!2sin" height="500" allowFullScreen="" loading="lazy"></iframe>
									</div>
								</Col>
							</Row>
						</Card.Body>
					</Card>
					<Card>
						<Card.Body>
							<Form>
								<Form.Group className="form-group">
									<Form.Label htmlFor="username"> {t("username").charAt(0).toUpperCase() + t("username").slice(1)} </Form.Label>
									<Form.Control
										type="text"
										id="username"
										// defaultValue="Mark Jhon"
										placeholder={t("enterUsername").charAt(0).toUpperCase() + t("enterUsername").slice(1)}
										value={username}
										onChange={handleInputChange}
									/>
								</Form.Group>
								<Row>
									<Col>
										<Form.Group className="form-group">
											<Form.Label htmlFor="exampleInputEmail3">
												{t("start").charAt(0).toUpperCase() + t("start").slice(1)}
											</Form.Label>
											<Form.Control
												type="text"
												id="start"
												// defaultValue="markjhon@gmail.com"
												placeholder={t("selectStartingPoint").charAt(0).toUpperCase() + t("selectStartingPoint").slice(1)}
											/>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group className="form-group">
											<Form.Label htmlFor="arrival">
												{t("arrival").charAt(0).toUpperCase() + t("arrival").slice(1)}
											</Form.Label>
											<Form.Control
												type="url"
												id="arrival"
												// defaultValue="https://getbootstrap.com"
												placeholder={t("selectArrivalPoint").charAt(0).toUpperCase() + t("selectArrivalPoint").slice(1)}
											/>
										</Form.Group>
									</Col>
									{stopPoints.length === 0 && (
										<Col xs="auto">
											<Button
												variant="btn btn-success"
												type="button"  // Change to "button" to prevent form submission
												className="mt-5"
												onClick={() => addStopPoint(0)} // Add a stop point when clicked
											>
												<svg
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
													className="icon-20"
													width="20"
													height="20"
													viewBox="0 0 24 24"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M7.33 2H16.66C20.06 2 22 3.92 22 7.33V16.67C22 20.06 20.07 22 16.67 22H7.33C3.92 22 2 20.06 2 16.67V7.33C2 3.92 3.92 2 7.33 2ZM12.82 12.83H15.66C16.12 12.82 16.49 12.45 16.49 11.99C16.49 11.53 16.12 11.16 15.66 11.16H12.82V8.34C12.82 7.88 12.45 7.51 11.99 7.51C11.53 7.51 11.16 7.88 11.16 8.34V11.16H8.33C8.11 11.16 7.9 11.25 7.74 11.4C7.59 11.56 7.5 11.769 7.5 11.99C7.5 12.45 7.87 12.82 8.33 12.83H11.16V15.66C11.16 16.12 11.53 16.49 11.99 16.49C12.45 16.49 12.82 16.12 12.82 15.66V12.83Z"
														fill="currentColor"
													></path>
												</svg>
											</Button>
										</Col>
									)}
								</Row>
								{stopPoints.map((stopPoint, index) => (
									<Row key={stopPoint.id}>
										<Col>
											<Form.Group className="form-group">
												<Form.Label htmlFor={`stopPoint-${index}`}>
													{t("stopPoint").charAt(0).toUpperCase() + t("stopPoint").slice(1)}
												</Form.Label>
												<Form.Control
													type="text"
													id={`stopPoint-${index}`}
													placeholder={t("selectStopPoint").charAt(0).toUpperCase() + t("selectStopPoint").slice(1)}
												/>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group className="form-group">
												<Form.Label htmlFor={`price-${index}`}>
													{t("price").charAt(0).toUpperCase() + t("price").slice(1)}
												</Form.Label>
												<Form.Control
													type="number"
													id={`price-${index}`}
													defaultValue="0"
												/>
											</Form.Group>
										</Col>
										<Col xs="auto">
											<Button
												variant="btn btn-danger"
												type="button" // Change to "button" to prevent form submission
												className="mt-5"
												onClick={() => removeStopPoint(index)} // Add a new stop point after the current one
											>
												<svg
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
													class="icon-20"
													width="20"
													height="20"
													viewBox="0 0 24 24"
												>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M7.67 1.99927H16.34C19.73 1.99927 22 4.37927 22 7.91927V16.0903C22 19.6203 19.73 21.9993 16.34 21.9993H7.67C4.28 21.9993 2 19.6203 2 16.0903V7.91927C2 4.37927 4.28 1.99927 7.67 1.99927ZM15.01 14.9993C15.35 14.6603 15.35 14.1103 15.01 13.7703L13.23 11.9903L15.01 10.2093C15.35 9.87027 15.35 9.31027 15.01 8.97027C14.67 8.62927 14.12 8.62927 13.77 8.97027L12 10.7493L10.22 8.97027C9.87 8.62927 9.32 8.62927 8.98 8.97027C8.64 9.31027 8.64 9.87027 8.98 10.2093L10.76 11.9903L8.98 13.7603C8.64 14.1103 8.64 14.6603 8.98 14.9993C9.15 15.1693 9.38 15.2603 9.6 15.2603C9.83 15.2603 10.05 15.1693 10.22 14.9993L12 13.2303L13.78 14.9993C13.95 15.1803 14.17 15.2603 14.39 15.2603C14.62 15.2603 14.84 15.1693 15.01 14.9993Z"
														fill="currentColor"
													></path>
												</svg>
											</Button>
										</Col>

										{index === stopPoints.length - 1 && (
											<Col xs="auto">
												<Button
													variant="btn btn-success"
													type="button" // Change to "button" to prevent form submission
													className="mt-5"
													onClick={() => addStopPoint(index)} // Add a new stop point after the current one
												>
													<svg
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
														className="icon-20"
														width="20"
														height="20"
														viewBox="0 0 24 24"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M7.33 2H16.66C20.06 2 22 3.92 22 7.33V16.67C22 20.06 20.07 22 16.67 22H7.33C3.92 22 2 20.06 2 16.67V7.33C2 3.92 3.92 2 7.33 2ZM12.82 12.83H15.66C16.12 12.82 16.49 12.45 16.49 11.99C16.49 11.53 16.12 11.16 15.66 11.16H12.82V8.34C12.82 7.88 12.45 7.51 11.99 7.51C11.53 7.51 11.16 7.88 11.16 8.34V11.16H8.33C8.11 11.16 7.9 11.25 7.74 11.4C7.59 11.56 7.5 11.769 7.5 11.99C7.5 12.45 7.87 12.82 8.33 12.83H11.16V15.66C11.16 16.12 11.53 16.49 11.99 16.49C12.45 16.49 12.82 16.12 12.82 15.66V12.83Z"
															fill="currentColor"
														></path>
													</svg>
												</Button>
											</Col>
										)}
									</Row>
								))}

								<Form.Group className="form-group">
									<Form.Label htmlFor="departureDate">
										{t("departureDate").charAt(0).toUpperCase() + t("departureDate").slice(1)}
									</Form.Label>
									<Form.Control
										type="datetime-local"
										id="departureDate"
									// defaultValue="2019-12-19T13:45:00"
									/>
								</Form.Group>

								<Form.Group className="form-group">
									<Form.Label htmlFor="price">
										{t("price").charAt(0).toUpperCase() + t("price").slice(1)}
									</Form.Label>
									<Form.Control
										type="number"
										id="price"
										defaultValue="0"
									/>
								</Form.Group>
								<Row>
									<Col>
										<Form.Check className="form-switch">
											<Form.Check.Input
												type="checkbox"
												className="me-2"
												id="acceptsBaggage"
											/>
											<Form.Check.Label htmlFor="acceptsBaggage">
												{t("acceptsBaggage").charAt(0).toUpperCase() + t("acceptsBaggage").slice(1)}
											</Form.Check.Label>
										</Form.Check>
									</Col>
									<Col>
										<Form.Check className="form-switch">
											<Form.Check.Input
												type="checkbox"
												className="me-2"
												id="acceptsAnimals"
											/>
											<Form.Check.Label htmlFor="acceptsAnimals">
												{t("acceptsAnimals").charAt(0).toUpperCase() + t("acceptsAnimals").slice(1)}
											</Form.Check.Label>
										</Form.Check>
									</Col>
									<Col>
										<Form.Check className="form-switch">
											<Form.Check.Input
												type="checkbox"
												className="me-2"
												id="acceptsParcels"
											/>
											<Form.Check.Label htmlFor="acceptsParcels">
												{t("acceptsParcels").charAt(0).toUpperCase() + t("acceptsParcels").slice(1)}
											</Form.Check.Label>
										</Form.Check>
									</Col>
								</Row>
								<br />
								<br />
								<Button type="button" variant="btn btn-primary">
									{t("submit").charAt(0).toUpperCase() + t("submit").slice(1)}
								</Button>{" "}
								<Button type="button" variant="btn btn-danger">
									{t("cancel").charAt(0).toUpperCase() + t("cancel").slice(1)}
								</Button>
							</Form>
						</Card.Body>
					</Card>
				</Col>
				{username && (
					<Col lg="12" md="12">
						<Row className="row-cols-1">
							<div className="d-flex py-4">
								<h4>{t("userVehicles")}</h4>
							</div>
							<div
								className="overflow-hidden slider-circle-btn"
								id="ecommerce-slider"
							>
								<Swiper
									className="p-0 m-0 mb-4 swiper-wrapper list-inline"
									slidesPerView={3}
									spaceBetween={32}
									modules={[Navigation]}
									navigation={{
										nextEl: "#swiper-button-next1",
										prevEl: "#swiper-button-prev1",
									}}
									breakpoints={{
										320: { slidesPerView: 1 },
										550: { slidesPerView: 2 },
										991: { slidesPerView: 3 },
										1400: { slidesPerView: 3 },
										1500: { slidesPerView: 3 },
										1920: { slidesPerView: 4 },
										2040: { slidesPerView: 4 },
										2440: { slidesPerView: 4 },
									}}
								>
									<SwiperSlide>
										<VehicleCard
											productName="Casual Shoes"
											productImage={img1}
											productRating="3.5"
											productPrice="$56.00"
											statusDetails={t("complete")}
											statusColor="success"
										/>
									</SwiperSlide>
									<SwiperSlide>
										<VehicleCard
											productName="Biker's Jacket"
											productImage={img2}
											productRating="3.5"
											productPrice="$56.00"
											statusDetails={t("cancel")}
											statusColor="danger"
										/>
									</SwiperSlide>
									<SwiperSlide>
										<VehicleCard
											productName="Knitted Shrug"
											productImage={img3}
											productRating="3.5"
											productPrice="$56.00"
											statusDetails={t("pending")}
											statusColor="warning"
										/>
									</SwiperSlide>
									<SwiperSlide>
										<VehicleCard
											productName="Blue Handbag"
											productImage={img2}
											productRating="3.5"
											productPrice="$56.00"
											statusDetails={t("cancel")}
											statusColor="danger"
										/>
									</SwiperSlide>
									<SwiperSlide>
										<VehicleCard
											productName="Pink Sweater"
											productImage={img1}
											productRating="3.5"
											statusDetails={t("pending")}
											statusColor="warning"
											productPrice="$56.00"
										/>
									</SwiperSlide>
									<SwiperSlide>
										<VehicleCard
											productName="Pink Handbag"
											productImage={img3}
											productRating="3.5"
											productPrice="$56.00"
											statusDetails={t("complete")}
											statusColor="success"
										/>
									</SwiperSlide>
									<SwiperSlide>
										<VehicleCard
											productName="Casual Shoes"
											productImage={img1}
											productRating="3.5"
											productPrice="$56.00"
											statusDetails={t("cancel")}
											statusColor="danger"
										/>
									</SwiperSlide>
								</Swiper>
								<div
									id="swiper-button-next1"
									className="swiper-button swiper-button-next"
								></div>
								<div
									id="swiper-button-prev1"
									className="swiper-button swiper-button-prev"
								></div>
							</div>
						</Row>
					</Col>)}
			</Row>
		</Fragment >
	);
});
AddPath.displayName = "PathDetails";
export default AddPath;
