import React, { memo, Fragment, useRef, useState } from "react";

// React-bootstrap
import { Row, Col, Form, Image, Button } from "react-bootstrap";

// Router
import { Link } from "react-router-dom";

//Img
import img2 from "../../../../assets/images/brands/gm.svg";
import img5 from "../../../../assets/images/brands/apple-fill.svg";

//Component
import Autheffect from "../components/auth-effect";
import Card from "../../../../components/bootstrap/card";

import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../../services/http/reducers/userReducers";
import { operation } from "../../../../store/setting/selectors";
import img1 from "../../../../assets/images/favicon.ico";
import * as SettingSelector from "../../../../store/setting/selectors";

const Signin = memo(() => {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => operation(state)); // Accédez à l'état user
  const appName = useSelector(SettingSelector.app_name);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.stopPropagation();
      return;
    }

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    dispatch(loginUser({ identifier: email, password: password }));
  };

  return (
    <Fragment>
      <div className="iq-auth-page">
        <Autheffect />
        <Row className="d-flex align-items-center iq-auth-container w-100">
          <Col xl="4" className="col-10 offset-xl-7 offset-1">
            <Card>
              <Card.Body>
                <h3 className="text-center">Sign In</h3>
                <div className="my-3">
                  <Link
                    to="/auth/sign-in"
                    className="iq-link d-flex align-items-center justify-content-center"
                  >
                    <Image src={img1} alt="logo" loading="lazy" />
                    {/* <h4 data-setting="app_name" className="mb-0">
                      {appName}
                    </h4> */}
                  </Link>
                </div>
                <p className="text-center">Sign in to stay connected</p>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  className="needs-validation"
                >
                  <div className="form-group">
                    <Form.Label htmlFor="email">Username</Form.Label>

                    <Form.Control
                      type="text"
                      className="mb-0"
                      id="email"
                      placeholder="Enter email"
                      ref={emailRef}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter username
                    </Form.Control.Feedback>
                  </div>
                  <div className="form-group">
                    <Form.Label htmlFor="password">Password</Form.Label>

                    <Form.Control
                      type="password"
                      className="mb-0"
                      id="password"
                      placeholder="Enter password"
                      ref={passwordRef}
                      required
                    />

                    <Form.Control.Feedback type="invalid">
                      Please enter password
                    </Form.Control.Feedback>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <Form.Check className="form-check d-inline-block pt-1 mb-0">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheck11"
                      />
                      <Form.Check.Label htmlFor="customCheck11">
                        Remember Me
                      </Form.Check.Label>
                    </Form.Check>
                    <Link to="/auth/reset-password">Forget password</Link>
                  </div>
                  <div className="text-center pb-3">
                    <Button type="submit" disabled={loading}>
                      {loading ? "Connection..." : "Sign in"}
                    </Button>
                    {error && (
                      <p className="text-danger mt-3">{error.message}</p>
                    )}
                  </div>
                </Form>
                <p className="text-center">Or sign in with other accounts?</p>
                <div className="d-flex justify-content-center">
                  <ul className="list-group list-group-horizontal list-group-flush">
                    <li className="list-group-item border-0 pb-0">
                      <Link to="#">
                        <Image src={img2} alt="gm" loading="lazy" />
                      </Link>
                    </li>
                    <li className="list-group-item border-0 pb-0">
                      <Link to="#">
                        <Image src={img5} alt="apple" loading="lazy" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
});

Signin.displayName = "Signin";
export default Signin;
