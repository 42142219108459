import React, { useState, memo, Fragment } from "react";

// React-bootstrap
import { Row, Col, Button, Image, Collapse, Form } from "react-bootstrap";

// Router
import { Link } from "react-router-dom";

//Components
import Card from "../../../components/bootstrap/card";
import PathCard from "../components/path-card";
import PathCardListView from "../components/path-card-list-view";
import FilterRating from "../components/filter-rating";
import FilterOptions from "../components/filter-options";
import ProductSlider from "../components/product-slider";
import OfferCard from "../components/offer-card";
import RangeSlider from "../components/range-slider";

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";
import { useTranslation } from "react-i18next";

//Img
import img1 from "../../../assets/images/path/image_1.jpg";
// import img2 from '../../../../assets/modules/e-commerce/images/02.png';
import img2 from "../../../assets/images/path/image_2.jpg";
// import img3 from '../../../../assets/modules/e-commerce/images/03.png';
import img3 from "../../../assets/images/path/image_3.jpg";
// import img4 from '../../../../assets/modules/e-commerce/images/04.png';
import img4 from "../../../assets/modules/e-commerce/images/product/06.png";
// import img5 from '../../../../assets/modules/e-commerce/images/05.png';
import img5 from "../../../assets/modules/e-commerce/images/product/07.png";
// import img6 from '../../../../assets/modules/e-commerce/images/06.png';
import img6 from "../../../assets/modules/e-commerce/images/product/10.png";
import img7 from "../../../assets/modules/e-commerce/images/offers/06.png";
import img8 from "../../../assets/modules/e-commerce/images/offers/07.png";
import img9 from "../../../assets/modules/e-commerce/images/offers/09.png";
import img10 from "../../../assets/modules/e-commerce/images/offers/10.png";
// import img11 from '../../../../assets/modules/e-commerce/images/2.png';
import img11 from "../../../assets/modules/e-commerce/images/offers/04.png";
import img12 from "../../../assets/modules/e-commerce/images/app/01.png";

SwiperCore.use([Autoplay]);
const translationPercentage = 10;
const scaleValues = { x: 0.9, y: 1 };

const PathList = memo(() => {
    const [openBase, setOpenBase] = useState(true);
    const [openDriver, setOpenDriver] = useState(true);
    const [openVehicle, setOpenVehicle] = useState(true);
    const { t } = useTranslation();
    return (
        <Fragment>
            {/* <div className="mt-4 mb-4"> */}
            <Row>
                <Col lg="9">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h4 className="mb-0">{t("tripList")}</h4>
                        <ul
                            className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center"
                            id="pills-tab"
                            role="tablist"
                        >
                            <li className="nav-item dropdown d-none d-xl-block">
                                <div className="form-group input-group mb-0 search-input w-100 ">
                                    {/* <input
                                        type="search"
                                        className="form-control"
                                        placeholder="Search..."
                                    /> */}
                                    <Link to="/path/path-add">
                                        <Button type="submit" variant="btn btn-primary">
                                            {t("newTrip")}
                                        </Button>
                                    </Link>
                                    {/* <span className="input-group-text">
                                        <svg
                                            width="18"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="11.7669"
                                                cy="11.7666"
                                                r="8.98856"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            ></circle>
                                            <path
                                                d="M18.0186 18.4851L21.5426 22"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            ></path>
                                        </svg>
                                    </span> */}
                                </div>
                            </li>
                            <li className="nav-item ms-2" role="presentation">
                                <Button
                                    bsPrefix="nav-link btn btn-sm btn-icon rounded-pill active"
                                    size="sm"
                                    id="grid-view-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-grid-view"
                                    to="#"
                                    role="tab"
                                    aria-controls="pills-grid-view"
                                    aria-selected="true"
                                >
                                    <svg
                                        width="16"
                                        className="icon-16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            opacity="0.4"
                                            d="M16.0756 2H19.4616C20.8639 2 22.0001 3.14585 22.0001 4.55996V7.97452C22.0001 9.38864 20.8639 10.5345 19.4616 10.5345H16.0756C14.6734 10.5345 13.5371 9.38864 13.5371 7.97452V4.55996C13.5371 3.14585 14.6734 2 16.0756 2Z"
                                            fill="currentColor"
                                        ></path>
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M4.53852 2H7.92449C9.32676 2 10.463 3.14585 10.463 4.55996V7.97452C10.463 9.38864 9.32676 10.5345 7.92449 10.5345H4.53852C3.13626 10.5345 2 9.38864 2 7.97452V4.55996C2 3.14585 3.13626 2 4.53852 2ZM4.53852 13.4655H7.92449C9.32676 13.4655 10.463 14.6114 10.463 16.0255V19.44C10.463 20.8532 9.32676 22 7.92449 22H4.53852C3.13626 22 2 20.8532 2 19.44V16.0255C2 14.6114 3.13626 13.4655 4.53852 13.4655ZM19.4615 13.4655H16.0755C14.6732 13.4655 13.537 14.6114 13.537 16.0255V19.44C13.537 20.8532 14.6732 22 16.0755 22H19.4615C20.8637 22 22 20.8532 22 19.44V16.0255C22 14.6114 20.8637 13.4655 19.4615 13.4655Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                </Button>
                            </li>
                            <li className="nav-item ms-2" role="presentation">
                                <Button
                                    bsPrefix="nav-link btn btn-sm btn-icon rounded-pill"
                                    id="list-view-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-list-view"
                                    to="#"
                                    role="tab"
                                    aria-controls="pills-list-view"
                                    aria-selected="false"
                                >
                                    <svg
                                        width="16"
                                        className="icon-16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            opacity="0.4"
                                            d="M10.0833 15.958H3.50777C2.67555 15.958 2 16.6217 2 17.4393C2 18.2559 2.67555 18.9207 3.50777 18.9207H10.0833C10.9155 18.9207 11.5911 18.2559 11.5911 17.4393C11.5911 16.6217 10.9155 15.958 10.0833 15.958Z"
                                            fill="currentColor"
                                        ></path>
                                        <path
                                            opacity="0.4"
                                            d="M22.0001 6.37867C22.0001 5.56214 21.3246 4.89844 20.4934 4.89844H13.9179C13.0857 4.89844 12.4102 5.56214 12.4102 6.37867C12.4102 7.1963 13.0857 7.86 13.9179 7.86H20.4934C21.3246 7.86 22.0001 7.1963 22.0001 6.37867Z"
                                            fill="currentColor"
                                        ></path>
                                        <path
                                            d="M8.87774 6.37856C8.87774 8.24523 7.33886 9.75821 5.43887 9.75821C3.53999 9.75821 2 8.24523 2 6.37856C2 4.51298 3.53999 3 5.43887 3C7.33886 3 8.87774 4.51298 8.87774 6.37856Z"
                                            fill="currentColor"
                                        ></path>
                                        <path
                                            d="M21.9998 17.3992C21.9998 19.2648 20.4609 20.7777 18.5609 20.7777C16.6621 20.7777 15.1221 19.2648 15.1221 17.3992C15.1221 15.5325 16.6621 14.0195 18.5609 14.0195C20.4609 14.0195 21.9998 15.5325 21.9998 17.3992Z"
                                            fill="currentColor"
                                        ></path>
                                    </svg>
                                </Button>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="pills-tabContent">
                        <div
                            className="tab-pane fade show active"
                            id="pills-grid-view"
                            role="tabpanel"
                            aria-labelledby="grid-view-tab"
                        >
                            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3">
                                <Col>
                                    <PathCard
                                        productName="Casual Shoes"
                                        productImage={img1}
                                        productRating="3.5"
                                        statusDetails={t("complete")}
                                        productPrice="$56.00"
                                        statusColor="success"
                                    />
                                </Col>
                                <Col>
                                    <PathCard
                                        productName="Biker's Jacket"
                                        productImage={img2}
                                        productRating="3.5"
                                        productPrice="$56.00"
                                        statusDetails={t("pending")}
                                        statusColor="warning"
                                    />
                                </Col>
                                <Col>
                                    <PathCard
                                        productName="Knitted Shrug"
                                        productImage={img3}
                                        productRating="3.5"
                                        productPrice="$56.00"
                                        statusDetails={t("cancel")}
                                        statusColor="danger"
                                    />
                                </Col>
                                <Col>
                                    <PathCard
                                        productName="Casual Shoes"
                                        productImage={img1}
                                        productRating="3.5"
                                        statusDetails={t("complete")}
                                        productPrice="$56.00"
                                        statusColor="success"
                                    />
                                </Col>
                                <Col>
                                    <PathCard
                                        productName="Biker's Jacket"
                                        productImage={img2}
                                        productRating="3.5"
                                        productPrice="$56.00"
                                        statusDetails={t("pending")}
                                        statusColor="warning"
                                    />
                                </Col>
                                <Col>
                                    <PathCard
                                        productName="Knitted Shrug"
                                        productImage={img3}
                                        productRating="3.5"
                                        productPrice="$56.00"
                                        statusDetails={t("cancel")}
                                        statusColor="danger"
                                    />
                                </Col>
                                <Col>
                                    <PathCard
                                        productName="Casual Shoes"
                                        productImage={img1}
                                        productRating="3.5"
                                        statusDetails={t("complete")}
                                        productPrice="$56.00"
                                        statusColor="success"
                                    />
                                </Col>
                                <Col>
                                    <PathCard
                                        productName="Biker's Jacket"
                                        productImage={img2}
                                        productRating="3.5"
                                        productPrice="$56.00"
                                        statusDetails={t("pending")}
                                        statusColor="warning"
                                    />
                                </Col>
                                <Col>
                                    <PathCard
                                        productName="Knitted Shrug"
                                        productImage={img3}
                                        productRating="3.5"
                                        productPrice="$56.00"
                                        statusDetails={t("cancel")}
                                        statusColor="danger"
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="pills-list-view"
                            role="tabpanel"
                            aria-labelledby="list-view-tab"
                        >
                            <Row className="row-cols-1">
                                <Col>
                                    <PathCardListView
                                        productName="Casual Shoes"
                                        productImage={img1}
                                        productRating="3.9"
                                        productPrice="$56.00"
                                        statusColor="success"
                                        statusIdentity={t("complete")}
                                        deliveryDate="Lindi 30 Janvier 2025 à 12h00"
                                    />
                                    <PathCardListView
                                        productName="Biker's Jacket"
                                        productImage={img2}
                                        productRating="3.5"
                                        productPrice="$65.00"
                                        statusColor="warning"
                                        statusIdentity={t("pending")}
                                        deliveryDate="Lindi 30 Janvier 2025 à 12h00"
                                    />
                                    <PathCardListView
                                        productName="Knitted Shrug"
                                        productImage={img3}
                                        productRating="3.5"
                                        productPrice="$65.00"
                                        statusColor="danger"
                                        statusIdentity={t("cancel")}
                                        deliveryDate="Lindi 30 Janvier 2025 à 12h00"
                                    />
                                    <PathCardListView
                                        productName="Casual Shoes"
                                        productImage={img1}
                                        productRating="3.9"
                                        productPrice="$56.00"
                                        statusColor="success"
                                        statusIdentity={t("complete")}
                                        deliveryDate="Lindi 30 Janvier 2025 à 12h00"
                                    />
                                    <PathCardListView
                                        productName="Biker's Jacket"
                                        productImage={img2}
                                        productRating="3.5"
                                        productPrice="$65.00"
                                        statusColor="warning"
                                        statusIdentity={t("pending")}
                                        deliveryDate="Lindi 30 Janvier 2025 à 12h00"
                                    />
                                    <PathCardListView
                                        productName="Knitted Shrug"
                                        productImage={img3}
                                        productRating="3.5"
                                        productPrice="$65.00"
                                        statusColor="danger"
                                        statusIdentity={t("cancel")}
                                        deliveryDate="Lindi 30 Janvier 2025 à 12h00"
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className="page-item">
                                    <Link className="page-link" to="#" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </Link>
                                </li>
                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        1
                                    </Link>
                                </li>
                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        2
                                    </Link>
                                </li>
                                <li className="page-item">
                                    <Link className="page-link" to="#">
                                        3
                                    </Link>
                                </li>
                                <li className="page-item">
                                    <Link className="page-link" to="#" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </Col>
                <Col lg="3">
                    <Card className="iq-filter-card">
                        <Card.Header className="border-bottom px-0 py-4 mx-4">
                            <h4 className="list-main mb-0">{t("filters").charAt(0).toUpperCase() + t("filters").slice(1)}</h4>
                        </Card.Header>
                        <Card.Body>
                            <Button
                                href="#"
                                onClick={() => setOpenBase(!openBase)}
                                aria-controls="example-collapse-text"
                                aria-expanded={openBase}
                                bsPrefix="bg-transparent iq-custom-collapse w-100 d-flex justify-content-between pb-3"
                            >
                                <h5 className="mb-0">{t("bases").charAt(0).toUpperCase() + t("bases").slice(1)}</h5>
                                <div className="noUi-connect bg-primary">
                                    <div
                                        className="noUi-connect"
                                        style={{
                                            transform: `translate(${translationPercentage}%, 0px) scale(${scaleValues.x}, ${scaleValues.y})`,
                                        }}
                                    ></div>
                                </div>
                                <i className="right-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M9 5l7 7-7 7"
                                        ></path>
                                    </svg>
                                </i>
                            </Button>
                            <Collapse in={openBase}>
                                <div id="iq-product-filter-01">
                                    <div className="mt-2">
                                        <Form className="form-horizontal" action="/action_page.php">
                                            <Form.Group as={Row} className="form-group">
                                                <Form.Label
                                                    column
                                                    sm="3"
                                                    className="control-label align-self-center mb-0"
                                                    htmlFor="start"
                                                >
                                                    <i className="icon">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="icon-32"
                                                            fill="none"
                                                            width="20"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="8"
                                                                fill="currentColor"
                                                            ></circle>
                                                        </svg>
                                                    </i>
                                                    {/* {t("start").charAt(0).toUpperCase() + t("start").slice(1)}: */}
                                                </Form.Label>
                                                <Col sm="9">
                                                    <Form.Control type="text" placeholder={t("start").charAt(0).toUpperCase() + t("start").slice(1)} />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="form-group">
                                                <Form.Label
                                                    column
                                                    sm="3"
                                                    className="control-label  align-self-center mb-0"
                                                    htmlFor="arrival"
                                                >
                                                    <i className="icon">
                                                        <svg
                                                            class="icon-32"
                                                            width="20"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M8.53162 2.93677C10.7165 1.66727 13.402 1.68946 15.5664 2.99489C17.7095 4.32691 19.012 6.70418 18.9998 9.26144C18.95 11.8019 17.5533 14.19 15.8075 16.0361C14.7998 17.1064 13.6726 18.0528 12.4488 18.856C12.3228 18.9289 12.1848 18.9777 12.0415 19C11.9036 18.9941 11.7693 18.9534 11.6508 18.8814C9.78243 17.6746 8.14334 16.134 6.81233 14.334C5.69859 12.8314 5.06584 11.016 5 9.13442C4.99856 6.57225 6.34677 4.20627 8.53162 2.93677ZM9.79416 10.1948C10.1617 11.1008 11.0292 11.6918 11.9916 11.6918C12.6221 11.6964 13.2282 11.4438 13.6748 10.9905C14.1214 10.5371 14.3715 9.92064 14.3692 9.27838C14.3726 8.29804 13.7955 7.41231 12.9073 7.03477C12.0191 6.65723 10.995 6.86235 10.3133 7.55435C9.63159 8.24635 9.42664 9.28872 9.79416 10.1948Z"
                                                                fill="currentColor"
                                                            ></path>
                                                            <ellipse
                                                                opacity="0.4"
                                                                cx="12" cy="21"
                                                                rx="5" ry="1"
                                                                fill="currentColor"
                                                            >
                                                            </ellipse>
                                                        </svg>
                                                    </i>
                                                    {/* {t("arrival").charAt(0).toUpperCase() + t("arrival").slice(1)}: */}
                                                </Form.Label>
                                                <Col sm="9">
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={t("arrival").charAt(0).toUpperCase() + t("arrival").slice(1)}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="departureDate">
                                                    {t("departureDate")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="datetime-local"
                                                    id="departureDate"
                                                    defaultValue="2019-12-19T13:45:00"
                                                />
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="numberOfSeats">
                                                    {t("numberOfSeats")}
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    id="numberOfSeats"
                                                    defaultValue="3"
                                                />
                                            </Form.Group>
                                        </Form>
                                        <span>{t("price").charAt(0).toUpperCase() + t("price").slice(1)}</span>
                                        <RangeSlider />
                                    </div>
                                </div>
                            </Collapse>
                            <br />
                            <Button
                                href="#"
                                onClick={() => setOpenDriver(!openDriver)}
                                aria-controls="example-collapse-text"
                                aria-expanded={openDriver}
                                bsPrefix="bg-transparent iq-custom-collapse w-100 d-flex justify-content-between pb-3"
                            >
                                <h5 className="mb-0">{t("driver")}</h5>
                                <i className="right-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M9 5l7 7-7 7"
                                        ></path>
                                    </svg>
                                </i>
                            </Button>
                            <Collapse in={openDriver}>
                                <div id="iq-product-filter-02">
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="driverName">{t("driverName")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="driverName"
                                            defaultValue="Mark Jhon"
                                            placeholder={t("driverName")}
                                        />
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="driverLicenceNumber">{t("driverLicenceNumber")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="driverLicenceNumber"
                                            defaultValue="1234 5678 9012 3456"
                                            placeholder={t("driverLicenceNumber")}
                                        />
                                    </Form.Group>
                                    <div className="product-ratings mt-2">
                                        <span>
                                            {t("averageDriverRating")}
                                        </span>
                                        <FilterRating
                                            id="01"
                                            count={5}
                                            count1={0}
                                            productChecked="Checked"
                                        />
                                        <FilterRating id="02" count={4} count1={1} />
                                        <FilterRating id="03" count={3} count1={2} />
                                        <FilterRating id="04" count={2} count1={3} />
                                        <FilterRating id="05" count={1} count1={4} />
                                    </div>
                                </div>
                            </Collapse>
                            <br />
                            <Button
                                href="#"
                                onClick={() => setOpenVehicle(!openVehicle)}
                                aria-controls="example-collapse-text"
                                aria-expanded={openVehicle}
                                bsPrefix="bg-transparent iq-custom-collapse w-100 d-flex justify-content-between pb-3"
                            >
                                <h5 className="mb-0">{t("vehicle")}</h5>
                                <i className="right-icon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M9 5l7 7-7 7"
                                        ></path>
                                    </svg>
                                </i>
                            </Button>
                            {/* </Link> */}
                            <Collapse in={openVehicle}>
                                <div id="iq-product-filter-03">
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="vehicleModel">{t("vehicleModel")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="vehicleModel"
                                            defaultValue="Hyundai Tucson, Mazda CX-5"
                                            placeholder={t("vehicleModel")}
                                        />
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label htmlFor="licencePlateNumber">{t("licencePlateNumber")}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            id="licencePlateNumber"
                                            defaultValue="1234 5678 9012 3456"
                                            placeholder={t("licencePlateNumber")}
                                        />
                                    </Form.Group>
                                </div>
                            </Collapse>
                        </Card.Body>
                    </Card>
                    <div className="overflow-hidden collection-slider">
                        <Swiper
                            className="p-0 m-0 mb-4 swiper-wrapper list-inline"
                            slidesPerView={1}
                            loop={true}
                            centeredSlides={true}
                            autoplay={true}
                            spaceBetween={8}
                            breakpoints={{
                                320: { slidesPerView: 1 },
                                550: { slidesPerView: 1 },
                                991: { slidesPerView: 1 },
                                1400: { slidesPerView: 1 },
                                1500: { slidesPerView: 1 },
                                1920: { slidesPerView: 1 },
                                2040: { slidesPerView: 1 },
                                2440: { slidesPerView: 1 },
                            }}
                        >
                            <SwiperSlide className="card-slide">
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex justify-content-center">
                                            <Image
                                                src={img2}
                                                className="img-fluid object-contain avatar-100"
                                                alt="product"
                                            />
                                        </div>
                                        <div className="text-center">
                                            <h6 className="text-primary">{t("newTrips")}</h6>
                                            <div className="d-flex align-items-center mb-1">
                                                <i className="icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="icon-20"
                                                        fill="none"
                                                        width="20"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="8"
                                                            fill="currentColor"
                                                        ></circle>
                                                    </svg>
                                                </i>
                                                <span>Wood Buffalo, Alberta, Canada</span>
                                            </div>
                                            <div className="d-flex  align-items-center mb-1">
                                                <i className="icon">
                                                    <svg
                                                        class="icon-20"
                                                        width="20"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M8.53162 2.93677C10.7165 1.66727 13.402 1.68946 15.5664 2.99489C17.7095 4.32691 19.012 6.70418 18.9998 9.26144C18.95 11.8019 17.5533 14.19 15.8075 16.0361C14.7998 17.1064 13.6726 18.0528 12.4488 18.856C12.3228 18.9289 12.1848 18.9777 12.0415 19C11.9036 18.9941 11.7693 18.9534 11.6508 18.8814C9.78243 17.6746 8.14334 16.134 6.81233 14.334C5.69859 12.8314 5.06584 11.016 5 9.13442C4.99856 6.57225 6.34677 4.20627 8.53162 2.93677ZM9.79416 10.1948C10.1617 11.1008 11.0292 11.6918 11.9916 11.6918C12.6221 11.6964 13.2282 11.4438 13.6748 10.9905C14.1214 10.5371 14.3715 9.92064 14.3692 9.27838C14.3726 8.29804 13.7955 7.41231 12.9073 7.03477C12.0191 6.65723 10.995 6.86235 10.3133 7.55435C9.63159 8.24635 9.42664 9.28872 9.79416 10.1948Z"
                                                            fill="currentColor"
                                                        ></path>
                                                        <ellipse
                                                            opacity="0.4"
                                                            cx="12" cy="21"
                                                            rx="5" ry="1"
                                                            fill="currentColor"
                                                        >
                                                        </ellipse>
                                                    </svg>
                                                </i>
                                                <span>Opportunity No 17, Alberta, Canada</span>
                                            </div>
                                            <Button className="btn btn-primary" type="submit">
                                                {t("viewNow")}
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </SwiperSlide>
                            <SwiperSlide className="card-slide">
                                <Card>
                                    <Card.Body>
                                        <div className="d-flex justify-content-center">
                                            <Image
                                                src={img2}
                                                className="img-fluid object-contain avatar-100"
                                                alt="product"
                                            />
                                        </div>
                                        <div className="text-center">
                                            <h6 className="text-primary">{t("newTrips")}</h6>
                                            <div className="d-flex align-items-center mb-1">
                                                <i className="icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="icon-20"
                                                        fill="none"
                                                        width="20"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="8"
                                                            fill="currentColor"
                                                        ></circle>
                                                    </svg>
                                                </i>
                                                <span>Wood Buffalo, Alberta, Canada</span>
                                            </div>
                                            <div className="d-flex  align-items-center mb-1">
                                                <i className="icon">
                                                    <svg
                                                        class="icon-20"
                                                        width="20"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            fill-rule="evenodd"
                                                            clip-rule="evenodd"
                                                            d="M8.53162 2.93677C10.7165 1.66727 13.402 1.68946 15.5664 2.99489C17.7095 4.32691 19.012 6.70418 18.9998 9.26144C18.95 11.8019 17.5533 14.19 15.8075 16.0361C14.7998 17.1064 13.6726 18.0528 12.4488 18.856C12.3228 18.9289 12.1848 18.9777 12.0415 19C11.9036 18.9941 11.7693 18.9534 11.6508 18.8814C9.78243 17.6746 8.14334 16.134 6.81233 14.334C5.69859 12.8314 5.06584 11.016 5 9.13442C4.99856 6.57225 6.34677 4.20627 8.53162 2.93677ZM9.79416 10.1948C10.1617 11.1008 11.0292 11.6918 11.9916 11.6918C12.6221 11.6964 13.2282 11.4438 13.6748 10.9905C14.1214 10.5371 14.3715 9.92064 14.3692 9.27838C14.3726 8.29804 13.7955 7.41231 12.9073 7.03477C12.0191 6.65723 10.995 6.86235 10.3133 7.55435C9.63159 8.24635 9.42664 9.28872 9.79416 10.1948Z"
                                                            fill="currentColor"
                                                        ></path>
                                                        <ellipse
                                                            opacity="0.4"
                                                            cx="12" cy="21"
                                                            rx="5" ry="1"
                                                            fill="currentColor"
                                                        >
                                                        </ellipse>
                                                    </svg>
                                                </i>
                                                <span>Opportunity No 17, Alberta, Canada</span>
                                            </div>
                                            <Button className="btn btn-primary" type="submit">
                                                {t("viewNow")}
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </Col>
                {/* <ProductSlider /> */}
            </Row>
            {/* </div> */}
        </Fragment>
    );
});
PathList.displayName = "PathList";
export default PathList;
