import  React,{memo,Fragment} from 'react'

//Components
import RatingStar from "../../../components/rating-star"

import { useTranslation } from "react-i18next";

const FilterRating = memo((props) => {
    const { t } = useTranslation();

    return(
        <Fragment>
            <div className="form-check d-flex align-items-center">
                <input type="checkbox" className="form-check-input" id={props.id} defaultChecked={props.productChecked}/>
                <label className="ms-2 d-flex align-items-center w-100" htmlFor={`Check-${props.id}`}> 
                    <RatingStar count1={props.count1} count={props.count} />
                    <span className="ms-2">& {t("andMore")}</span>
                </label>
            </div>
        </Fragment>
    )
})

FilterRating.displayName="FilterRating"
export default FilterRating