import React, { memo, Fragment, useState, useRef, useEffect } from "react";

//react-bootstrap
import { Row, Col, Image, Form, Button } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//router
import { Link } from "react-router-dom";

// img
import avatars1 from "../../../assets/images/avatars/01.png";
import avatars2 from "../../../assets/images/avatars/avtar_1.png";
import avatars3 from "../../../assets/images/avatars/avtar_2.png";
import avatars4 from "../../../assets/images/avatars/avtar_3.png";
import avatars5 from "../../../assets/images/avatars/avtar_4.png";
import avatars6 from "../../../assets/images/avatars/avtar_5.png";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  getAllRoles,
} from "../../../services/http/reducers/userReducers";
import {
  operation,
  accessToken,
  rolesName,
} from "../../../store/setting/selectors";

const UserAdd = memo(() => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const [password, setPassword] = useState("");
  const [passWordIsConfirm, setPassWordIsConfirm] = useState("");
  const [loadingText, setLoadingText] = useState("");
  const [role, setRole] = useState("");

  const emailRef = useRef(null);
  const birthDateRef = useRef(null);
  const contactRef = useRef(null);
  const userNameRef = useRef(null);

  const pictureRef = useRef(null);

  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => operation(state)); // Accédez à l'état user
  const token = useSelector((state) => accessToken(state)); // Accédez à l'état user
  const roleNames = useSelector(rolesName); // Accédez à l'état user

  const handleImageChange = (event) => {
    const file = event.target.files[0]; // Récupérer le fichier sélectionné
    if (file) {
      const reader = new FileReader(); // Créer un objet FileReader

      // Définir une fonction de rappel lorsque la lecture est terminée
      reader.onloadend = () => {
        const previews = document.getElementsByClassName("preview"); // Récupérer tous les éléments avec la classe "preview"
        for (let i = 0; i < previews.length; i++) {
          previews[i].src = reader.result; // Mettre à jour l'attribut src de chaque image
        }
      };

      reader.readAsDataURL(file); // Lire le fichier en tant qu'URL de données
    }
  };

  const handleSubmitAddUser = (event) => {
    console.log("Add new user call");
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.stopPropagation();
      return;
    }

    // Récupérer le fichier sélectionné
    const picture = pictureRef.current.files[0];

    // Créer un objet FormData
    const formData = new FormData();
    formData.append("picture", picture);

    //Construction du paramètre adminRequest
    const email = emailRef.current.value;
    const birthDate = birthDateRef.current.value;
    const contact = contactRef.current.value;
    const userName = userNameRef.current.value;

    console.log("Add new user data test stringlyfy");

    formData.append(
      "adminRequest",
      JSON.stringify({
        username: userName,
        email: email,
        password: password,
        phoneNumber: contact,
        dateOfBirth: birthDate,
        role: role,
      })
    );

    setLoadingText(" Utilisateur en cours de création ...");

    dispatch(
      addUser({
        formData: formData,
        token: token,
      })
    );

    setValidated(false);
  };

  useEffect(() => {
    setLoadingText("Chargement des rôles ...");
    dispatch(getAllRoles(token));
  }, [dispatch, token]);

  return (
    <Fragment>
      <Row>
        {loading ? (
          <Col>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">Add User</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <div>
                  <h5 className="text-center pb-2 ">{loadingText}</h5>
                  <div className="iq-loader-box">
                    <div className="iq-loader-9"></div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ) : (
          <Col>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmitAddUser}
              className="row g-3 needs-validation"
            >
              <Col xl="3" lg="4" className="">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Add New User</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div>
                      <Form.Group className="form-group">
                        <div className="profile-img-edit position-relative">
                          <Image
                            className="theme-color-default-img  profile-pic rounded avatar-100 preview"
                            src={avatars1}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-purple-img profile-pic rounded avatar-100 preview"
                            src={avatars2}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-blue-img profile-pic rounded avatar-100 preview"
                            src={avatars3}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-green-img profile-pic rounded avatar-100 preview"
                            src={avatars5}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-yellow-img profile-pic rounded avatar-100 preview"
                            src={avatars6}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-pink-img profile-pic rounded avatar-100 preview"
                            src={avatars4}
                            alt="profile-pic"
                          />
                          <div className="upload-icone bg-primary">
                            <svg
                              className="upload-button icon-14"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#ffffff"
                                d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                              />
                            </svg>
                            <Form.Control
                              className="file-upload"
                              type="file"
                              accept="image/*"
                              required
                              ref={pictureRef}
                              onChange={handleImageChange}
                            />

                            <Form.Control.Feedback type="invalid" className="">
                              XXX
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div className="img-extension mt-4">
                          <div className="d-inline-block align-items-center">
                            <span>Only</span> <Link to="#">.jpg</Link>{" "}
                            <Link to="#">.png</Link> <Link to="#">.jpeg</Link>{" "}
                            <span>allowed</span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>User Role:</Form.Label>
                        <select
                          name="type"
                          className="selectpicker form-control"
                          data-style="py-0"
                          required
                          onChange={(e) => {
                            setRole(e.target.value);
                          }}
                        >
                          <option value="">Select</option>
                          {roleNames.map((role) => {
                            if (role !== "SUPER_ADMIN") {
                              return <option value={role}>{role}</option>;
                            }
                          })}
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Please choose a role
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* <Form.Group className="form-group">
                <Form.Label htmlFor="furl">Facebook Url:</Form.Label>
                <Form.Control
                  type="text"
                  id="furl"
                  placeholder="Facebook Url"
                />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="turl">Twitter Url:</Form.Label>
                    <Form.Control
                      type="text"
                      id="turl"
                      placeholder="Twitter Url"
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label htmlFor="instaurl">Instagram Url:</Form.Label>
                    <Form.Control
                      type="text"
                      id="instaurl"
                      placeholder="Instagram Url"
                    />
                  </Form.Group>
                  <Form.Group className="mb-0 form-group">
                    <Form.Label htmlFor="lurl">Linkedin Url:</Form.Label>
                    <Form.Control
                      type="text"
                      id="lurl"
                      placeholder="Linkedin Url"
                    />
                  </Form.Group> */}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl="9" lg="8">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">New User Information</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="new-user-info">
                      <div>
                        <div className="row g-3 needs-validation">
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="validationCustom01">
                              {t("email")}
                            </Form.Label>
                            <Form.Control
                              type="email"
                              id="validationCustom01"
                              placeholder="Enter the e-mail address"
                              required
                              ref={emailRef}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the e-mail address
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="birthdate">
                              {t("birthdate")}
                            </Form.Label>
                            <Form.Control
                              type="date"
                              id="birthdate"
                              placeholder="Enter the birthdate"
                              required
                              ref={birthDateRef}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the birthdate
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="phonenumber">
                              {t("phonenumber")}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="phonenumber"
                              placeholder="Enter the contact"
                              required
                              ref={contactRef}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the contact
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <hr />
                        <h5 className="mb-3">Security</h5>
                        <div className="row">
                          <Form.Group className="col-md-12 form-group">
                            <Form.Label htmlFor="username">
                              User Name:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="username"
                              placeholder="User Name"
                              required
                              ref={userNameRef}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the user name
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="pass">Password:</Form.Label>
                            <Form.Control
                              type="password"
                              id="pass"
                              placeholder="Password"
                              required
                              onChange={(event) => {
                                setPassword(event.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the password
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="rpass">
                              Repeat Password:
                            </Form.Label>
                            <Form.Control
                              type="password"
                              id="rpass"
                              placeholder="Repeat Password "
                              required
                              onChange={(event) => {
                                setPassword(event.target.value);
                                if (password !== event.target.value) {
                                  setPassWordIsConfirm(false);
                                } else {
                                  setPassWordIsConfirm(true);
                                }
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please confirm the password
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <div className="checkbox">
                          <label className="form-label">
                            <input
                              type="checkbox"
                              className="me-2 form-check-input"
                              value=""
                              id="flexCheckChecked"
                            />
                            Enable Two-Factor-Authentication
                          </label>
                        </div>
                        <Button type="submit" variant="btn btn-primary">
                          Add New User
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Form>
          </Col>
        )}
      </Row>
    </Fragment>
  );
});

UserAdd.displayName = "UserAdd";
export default UserAdd;
