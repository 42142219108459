import React from 'react'

// pages
import PathList from '../path-list'
import PathDetail from '../path-details'
import PathAdd from '../path-add'
import Default from "../../../../layouts/dashboard/default";

export const PathRouter = [{
  path: 'path',
  element: <Default />,
  children: [

    {
      path: 'path-add',
      element: <PathAdd />,
    },

    {
      path: 'path-list',
      element: <PathList />,
    },

    {
      path: 'path-details',
      element: <PathDetail />,
    },

  ],
}
]