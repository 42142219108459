import React, { useState, memo, Fragment, useRef, useEffect } from "react";

//react-bootstrap
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import { accessToken } from "../../../store/setting/selectors";
//router
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  getAllRoles,
  getAllPermissions,
  addRole,
  deleteRole,
  updateRole,
} from "../../../services/http/reducers/userReducers";
//components
import Card from "../../../components/bootstrap/card";
import useDataTable from "../../../components/hooks/useDatatable";
import { useTranslation } from "react-i18next";

import { Sweetalert } from "../../../components/partials/dashboard/mainstyle/sweet-alert";
import { useDispatch, useSelector } from "react-redux";
import { set } from "date-fns";

import Select from "react-select";
import Swal from "sweetalert2";
import ActivityIndicator from "../../../components/partials/dashboard/mainstyle/activity-indicator";

const Admin = memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const token = useSelector(accessToken);
  const location = useLocation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => {
    setCurrentRoleTitle("");
    setDefaultSelectedPermissions([]);
    setSelectedPermissions([]);
    setShow1(false);
    setEdit(false);
  };
  const handleShow1 = () => {
    setRoleOperationTitle(t("addNewRole"));
    setShow1(true);
  };
  const [tableData, setTableData] = useState([]);
  // const [columns, setColumns] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [defaultSelectedPermissions, setDefaultSelectedPermissions] = useState(
    []
  );
  const [loadData, setLoadData] = useState(true);
  const [edit, setEdit] = useState(false);
  const {
    manage: { roles, permissions },
    operation: { loading, error },
  } = useSelector((state) => state.user.user);

  useEffect(() => {
    if (error) {
      if (Object.keys(error).includes("deleteRole")) {
        Swal.fire({
          icon: "success",
          title: "Succès...",
          text: "Rôle Supprimé!",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      }
    }
  });

  useEffect(() => {
    // setLoadingText("Recherche des utilisateurs en cours ...");
    if (loadData) {
      setLoadingText("Chargement des permissions en cours ...");
      dispatch(getAllPermissions(token)).then(() => {
        setLoadingText("Chargement des rôles en cours ...");
        dispatch(getAllRoles(token));
      });
      setLoadData(false);
    }
    console.log(
      "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$"
    );
  }, [dispatch, location, token, loadData]);

  const tableRef = useRef(null);

  useEffect(() => {
    const tableDataTemp = permissions.map((permission) => {
      const row = { name: permission };
      roles.forEach((role) => {
        const rolePermission = role.permissions.find(
          (perm) => perm === permission
        );
        row[role.name] = rolePermission ? true : false;
      });
      return row;
    });
    setTableData(tableDataTemp);
    console.log(
      "**************************-------------------***********************---------------------------*********************"
    );
    console.log(tableDataTemp);
    console.log(
      "*********************************** Role ****************************************"
    );
    console.log(roles);
  }, [roles, permissions]);

  const columns = useRef([{ data: "", title: "" }]);
  useEffect(() => {
    columns.current = [
      {
        data: "name",
        title: "",
        render: function (data) {
          return `
            <div class="d-flex align-items-center justify-content-between">
              ${data}
            </div>`;
        },
      },
      ...roles.map((role) => ({
        data: role.name,
        title: `
          <div class="d-flex align-items-center justify-content-between">
            ${role.name.toUpperCase()}
            <div style="float: right;">
              <a id="action-row-${
                role.name
              }-method-editrole" class="btn btn-sm btn-icon text-primary flex-end" data-bs-toggle="tooltip" title="Edit User" href="#">
                <span class="btn-inner">
                  <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                </span>
              </a>
              <a id="action-row-${
                role.name
              }-method-deleterole" class="btn btn-sm btn-icon text-danger" data-bs-toggle="tooltip" title="Delete User" href="#">
                <span class="btn-inner">
                  <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                    <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>`,
        render: function (data) {
          return `<input type="checkbox" ${
            data ? "checked" : ""
          }  disabled="disabled"/>`;
        },
      })),
    ];
  }, [roles]);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const defaultRoleOperationTitle = "Add new role";
  const [roleOperationTitle, setRoleOperationTitle] = useState(
    defaultRoleOperationTitle
  );
  const [currentRoleTitle, setCurrentRoleTitle] = useState("");

  // let history =useHistory()
  let history = useNavigate();

  // const handleOnclickSave = (event) => {
  //   event.preventDefault();
  //   setLoadingText("Modification des permissions en cours...");
  //   setIsLoading(true);

  //   setTimeout(() => {
  //     // Code à exécuter après le délai
  //     setIsLoading(false);
  //     Sweetalert(
  //       "success",
  //       "Succès ...",
  //       "Mise à jour des permissions Ok!",
  //       ""
  //     );
  //   }, 5000);
  // };

  // const handleAddPermission = (event) => {
  //   event.preventDefault();
  //   handleClose();
  //   setIsLoading(true);
  //   setLoadingText("Entregistrement de la permission en cours...");

  //   setTimeout(() => {
  //     // Code à exécuter après le délai
  //     setIsLoading(false);

  //     Sweetalert("success", "Succès ...", "Permission enregistré!", "");
  //   }, 5000);
  // };

  const handleConfirm = (event) => {
    event.preventDefault();
    setIsLoading(loading);
    if (!edit) {
      dispatch(
        addRole({
          data: {
            name: currentRoleTitle,
            permissions: selectedPermissions.map((permission) => {
              return permission.value;
            }),
          },
          token: token,
        })
      ).then(() => {
        setIsLoading(loading);
        setLoadData(true);
        handleClose1();
      });
    } else {
      dispatch(
        updateRole({
          data: {
            roleName: currentRoleTitle,
            permissions: selectedPermissions.map((permission) => {
              return permission.value;
            }),
          },
          token: token,
        })
      ).then(() => {
        setIsLoading(loading);
        setLoadData(true);
        handleClose1();
      });
    }
  };

  // // Function for deleting a permission
  // const handleDeletePermission = (row) => {
  //   setIsLoading(true);
  //   setLoadingText(
  //     "Suppression de la permission " + row.name + " en cours ..."
  //   );

  //   //Call API here
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     setLoadingText("");
  //     Sweetalert("success", "Succès ...", "Permission supprimée!", "");
  //   }, 5000);
  // };

  // Function for deleting a role
  const handleDeleteRole = (role) => {
    Swal.fire({
      title: "Êtes-vous sûr ?",
      text: `Suppression du rôle ${role}!`,
      icon: "warning",
      showCancelButton: true,
      backdrop: `rgba(60,60,60,0.8)`,
      confirmButtonText: "Oui, supprimer!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(loading);
        setLoadingText("Suppréssion du rôle en cours");
        dispatch(
          deleteRole({
            roleName: role,
            token: token,
          })
        ).then(() => {
          setIsLoading(loading);
          setLoadData(true);

          // Update columns and tableData after deletion
          const updatedRoles = roles.filter((r) => r.name !== role);
          const updatedTableData = permissions.map((permission) => {
            const row = { name: permission };
            updatedRoles.forEach((role) => {
              const rolePermission = role.permissions.find(
                (perm) => perm === permission
              );
              row[role.name] = rolePermission ? true : false;
            });
            return row;
          });

          setRolesList(updatedRoles); // Update roles list
          setTableData(updatedTableData); // Update table data
        });
        // dispatch(getAllRoles(token));
        // dispatch(getAllPermissions(token));
      }
    });
    // setIsLoading(true);
    // setLoadingText("Suppression du rôle " + role + " en cours ...");

    // //Call API here
    // setTimeout(() => {
    //   setIsLoading(false);
    //   setLoadingText("");

    //   Sweetalert("success", "Succès ...", "Rôle supprimé!", "");
    // }, 5000);
  };

  const handleUpdateRole = (role) => {
    setRoleOperationTitle(t("modifyRole"));
    setEdit(true);
    setCurrentRoleTitle(role);
    const selectedRole = roles.find((r) => r.name === role);
    if (selectedRole && selectedRole.permissions) {
      setDefaultSelectedPermissions(selectedRole.permissions);
    }
    setShow1(true);
  };
  const handleInputAddRoleChange = (event) => {
    setCurrentRoleTitle(event.target.value); // Update state with user input
  };

  useDataTable({
    isLoading,
    tableRef,
    columns: columns.current,
    data: tableData,
    actionCallback: ({ id, method }) => {
      let row = tableData.find((item) => item.name === id); // Trouver la ligne correspondante
      // if (!row) return;

      // Appeler la fonction correspondante en fonction de la méthode
      switch (method) {
        case "editrole":
          handleUpdateRole(id);
          break;
        case "deleterole":
          handleDeleteRole(id);
          break;

        default:
          console.warn("Méthode non reconnue:", method);
      }
    },
  });

  return (
    <Fragment>
      <Row>
        {loading && (
          <ActivityIndicator
            title={t("rolesandpermissions")}
            text={loadingText}
          />
        )}

        <Col sm="12" style={{ display: loading ? "none" : "block" }}>
          <Card>
            <Card.Header className="flex-wrap align-items-center">
              <div className="header-title">
                <h4 className="card-title mb-0">
                  {t("roles")} & {t("permissions")}
                </h4>
              </div>
              <div className="d-flex align-items-center gap-3">
                {/* <Button
                  variant="text-center btn btn-primary d-flex gap-2"
                  onClick={handleShow}
                >
                  <svg
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    ></path>
                  </svg>
                  <span>New Permission</span>
                </Button>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Add new permission</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>permission title</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Permission Title"
                      />
                    </Form.Group>
                    <Button variant="primary" onClick={handleAddPermission}>
                      Save
                    </Button>{" "}
                    <Button variant="danger" onClick={handleClose}>
                      Cancel
                    </Button>
                  </Modal.Body>
                </Modal> */}
                <Button
                  className="text-center btn btn-primary d-flex gap-2"
                  onClick={handleShow1}
                >
                  <svg
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    ></path>
                  </svg>
                  <span>{t("newRole")}</span>
                </Button>
                <Modal show={show1} onHide={handleClose1}>
                  <Modal.Header closeButton>
                    <Modal.Title>{roleOperationTitle}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>role title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Role Title"
                        defaultValue={currentRoleTitle}
                        disabled={edit}
                        onChange={handleInputAddRoleChange} // Capture input changes
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Permissions</Form.Label>
                      <Select
                        isMulti
                        options={permissions.map((permission) => {
                          const parts = permission.split("_");
                          const role = parts[0];
                          const action = parts[1] ? parts[1] : ""; // Ensure action is defined

                          return {
                            value: permission,
                            label: `${role} ${
                              action.charAt(0).toUpperCase() +
                              action.slice(1).toLowerCase()
                            }`.trim(), // Trim in case action is empty
                          };
                        })}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={defaultSelectedPermissions.map(
                          (permission) => {
                            const parts = permission.split("_");
                            const role = parts[0];
                            const action = parts[1] ? parts[1] : "";
                            return {
                              value: permission,
                              label: `${role} ${
                                action.charAt(0).toUpperCase() +
                                action.slice(1).toLowerCase()
                              }`.trim(),
                            };
                          }
                        )}
                        onChange={setSelectedPermissions}
                      />
                    </Form.Group>
                    {/* <Form.Group controlId="formBasicPassword">
                      <Form.Label>status</Form.Label>
                    </Form.Group> */}
                    {/* <Form.Check>
                      <Form.Check.Input
                        type="radio"
                        name="customRadio0"
                        id="automatically"
                        defaultChecked
                      />{" "}
                      <Form.Check.Label
                        htmlFor="automatically"
                        className="pl-2"
                      >
                        yes
                      </Form.Check.Label>
                    </Form.Check>
                    <Form.Check className="mb-3">
                      <Form.Check.Input
                        type="radio"
                        name="customRadio0"
                        id="automatically"
                        defaultChecked
                      />{" "}
                      <Form.Check.Label
                        htmlFor="automatically"
                        className="pl-2"
                      >
                        no
                      </Form.Check.Label>
                    </Form.Check> */}
                    <Button variant="primary" onClick={handleConfirm}>
                      Save
                    </Button>{" "}
                    <Button variant="danger" onClick={handleClose1}>
                      Cancel
                    </Button>
                  </Modal.Body>
                </Modal>
              </div>
            </Card.Header>
            <Card.Body>
              <div className="table-responsive">
                <table ref={tableRef} className="table table-bordered">
                  <thead>
                    <tr>
                      {columns.current.map((column, index) => (
                        <th key={index}>{column.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Les lignes du tableau seront générées dynamiquement par useDataTable */}
                  </tbody>
                </table>
                {/* <div className="d-flex justify-content-center align-items-center">
                  <div>
                    <Button
                      onClick={handleOnclickSave}
                      type="button"
                      variant="primary"
                    >
                      Save
                    </Button>
                  </div>
                </div> */}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

Admin.displayName = "Admin";
export default Admin;
