import React, { memo } from "react";
import { Col } from "react-bootstrap";
import Card from "../../../bootstrap/card";

const ActivityIndicator = memo((props) => {
  return (
    <Col>
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div className="header-title">
            <h4 className="card-title">{props.title}</h4>
          </div>
        </Card.Header>
        <Card.Body>
          <div>
            <h5 className="text-center pb-2 ">{props.text}</h5>
            <div className="iq-loader-box">
              <div className="iq-loader-9"></div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
});

ActivityIndicator.displayName = "ActivityIndicator";
export default ActivityIndicator;
