import React, { memo, Fragment, useState } from "react";

// React-bootstrap
import { Row, Col, Badge, Button } from "react-bootstrap";

// Components
import Document from "../../../modules/file-manager/components/document.js";
import Images from "../../../modules/file-manager/components/images.js";
import Video from "../../../modules/file-manager/components/video.js";

// Images
import img1 from "../../../../assets/modules/file-manager/images/5.png";
import img2 from "../../../../assets/modules/file-manager/images/6.png";
import img3 from "../../../../assets/modules/file-manager/images/7.png";
import img4 from "../../../../assets/modules/file-manager/images/8.png";
import pdf from "../../../../assets/modules/file-manager/images/pdf.svg";
import excel from "../../../../assets/modules/file-manager/images/excel.svg";
import word from "../../../../assets/modules/file-manager/images/word.svg";
import pptx from "../../../../assets/modules/file-manager/images/ppt.svg";

import ActivityIndicator from "../../../../components/partials/dashboard/mainstyle/activity-indicator.js";
import { useTranslation } from "react-i18next";

const VerificationProofDelivery = memo(() => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  // Structure de données avec les natures spécifiques
  const initialDocuments = [
    {
      id: 1,
      type: "image",
      nature: "Vérification des preuves de livraison",
      status: "approuvé",
      imageDate: "Dec 13, 2020",
      media: "pdf",
      imgName: "Permis-Conduire-123.pdf",
      imgLink: "just now",
      imgChange: img1,
      imgMb: "5mb",
      docType: "PDF",
      docName: "permis-conduire.pdf",
    },
    {
      id: 2,
      type: "image",
      nature: "Vérification des preuves de livraison",
      status: "désapprouvé",
      imageDate: "Dec 13, 2020",
      media: "pdf",
      imgName: "Permis-Conduire-456.pdf",
      imgLink: "2 days ago",
      imgChange: img2,
      imgMb: "4mb",
      docType: "PDF",
      docName: "permis-conduire-2.pdf",
    },
    {
      id: 3,
      type: "image",
      nature: "Vérification des preuves de livraison",
      status: "approuvé",
      imageDate: "Dec 13, 2020",
      media: "pdf",
      imgName: "svsevz svCIN-789.pdf",
      imgLink: "a month ago",
      imgChange: img3,
      imgMb: "3mb",
      docType: "PDF",
      docName: "cin.pdf",
    },
    {
      id: 4,
      type: "image",
      nature: "Vérification des preuves de livraison",
      status: "approuvé",
      imageDate: "Dec 13, 2020",
      media: "pdf",
      imgName: "Maintenance-Vehicule-123.pdf",
      imgLink: "just now",
      imgChange: img4,
      imgMb: "7mb",
      docType: "PDF",
      docName: "maintenance-vehicule.pdf",
    },
  ];

  // State pour gérer les documents
  const [documents, setDocuments] = useState(initialDocuments);

  // Fonction pour changer le statut d'un document
  const toggleStatus = (index) => {
    const updatedDocuments = [...documents];
    setIsLoading(true);

    if (updatedDocuments[index].status === "approuvé") {
      setLoadingText(
        updatedDocuments[index].nature + " désapprobation en cours ..."
      );
    } else {
      setLoadingText(
        updatedDocuments[index].nature + " approbation en cours ..."
      );
    }

    setTimeout(() => {
      updatedDocuments[index].status =
        updatedDocuments[index].status === "approuvé"
          ? "désapprouvé"
          : "approuvé";
      setDocuments(updatedDocuments);
      setIsLoading(false);
    }, 5000);
  };

  // Fonction pour afficher le badge de statut
  const renderStatusBadge = (status) => {
    if (status === "approuvé") {
      return <Badge bg="success">Approuvé</Badge>;
    } else if (status === "désapprouvé") {
      return <Badge bg="danger">Désapprouvé</Badge>;
    }
    return null;
  };

  // Fonction pour déterminer l'état de validation d'une section
  const getSectionValidationStatus = (section) => {
    const sectionDocs = documents.filter((doc) => doc.nature === section);
    const approvedCount = sectionDocs.filter(
      (doc) => doc.status === "approuvé"
    ).length;
    const disapprovedCount = sectionDocs.filter(
      (doc) => doc.status === "désapprouvé"
    ).length;

    if (approvedCount === sectionDocs.length) {
      return "Tous approuvés";
    } else if (disapprovedCount === sectionDocs.length) {
      return "Tous désapprouvés";
    } else {
      return "Mixte";
    }
  };

  // Fonction pour afficher le badge de validation de la section
  const renderSectionValidationBadge = (section) => {
    const status = getSectionValidationStatus(section);
    if (status === "Tous approuvés") {
      return <Badge bg="success">{status}</Badge>;
    } else if (status === "Tous désapprouvés") {
      return <Badge bg="danger">{status}</Badge>;
    } else {
      return <Badge bg="warning">{status}</Badge>;
    }
  };

  // Définir les sections d'affichage
  const sections = ["Vérification des preuves de livraison"];

  return (
    <Fragment>
      <div className="border-bottom pb-3 d-flex align-items-center justify-content-between">
        <h5>Toutes les preuves de livraison</h5>
      </div>

      {/* Afficher les sections spécifiques */}

      {isLoading ? (
        <div>
          <ActivityIndicator
            title={"Vérification des documents"}
            text={loadingText}
          />
        </div>
      ) : (
        sections.map((section) => (
          <div key={section} className="mb-5">
            <h4 className="py-3 mb-0">
              {section.charAt(0).toUpperCase() + section.slice(1)}{" "}
              {renderSectionValidationBadge(section)}
            </h4>
            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
              {documents
                .filter((doc) => doc.nature === section)
                .map((doc, index) => (
                  <Col key={index}>
                    <div className="mt-2">{renderStatusBadge(doc.status)}</div>
                    <Images {...doc} />
                    <div className="d-grid gap-2">
                      <Button
                        variant={
                          doc.status === "approuvé"
                            ? "outline-danger"
                            : "outline-success"
                        }
                        size="sm"
                        className="mt-2"
                        onClick={() => toggleStatus(documents.indexOf(doc))}
                      >
                        {doc.status === "approuvé"
                          ? "Désapprouver"
                          : "Approuver"}
                      </Button>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        ))
      )}
    </Fragment>
  );
});

VerificationProofDelivery.displayName = "VerificationProofDelivery";
export default VerificationProofDelivery;
