import React, { memo, Fragment, useRef, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Card from "../../../components/bootstrap/card";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import ActivityIndicator from "../../../components/partials/dashboard/mainstyle/activity-indicator";
import { Sweetalert } from "../../../components/partials/dashboard/mainstyle/sweet-alert";
import useDataTable from "../../../components/hooks/useDatatable";
import Swal from "sweetalert2";

const SecurityReportList = memo(() => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [showExportModal, setShowExportModal] = useState(false);

  const handleCloseExportModal = () => setShowExportModal(false);
  const handleShowExportModal = () => setShowExportModal(true);

  const reportTypeOptions = [
    { value: "all", label: "Tous" },
    { value: "suspicious_login", label: "Connexion suspecte" },
    { value: "hacking_attempt", label: "Tentative de piratage" },
    { value: "brute_force_attack", label: "Attaque par force brute" },
    { value: "phishing_attempt", label: "Tentative de phishing" },
  ];

  const typeExportFormatOptions = [
    { value: "pdf", label: "PDF" },
    { value: "csv", label: "CSV" },
    { value: "xls", label: "XLS" },
  ];

  const severityOptions = [
    { value: "all", label: "Tous" },
    { value: "low", label: "Faible" },
    { value: "medium", label: "Moyen" },
    { value: "high", label: "Élevé" },
  ];

  const originOptions = [
    { value: "all", label: "Tous" },
    { value: "mobile", label: "Appareil mobile" },
    { value: "desktop", label: "Ordinateur" },
  ];

  const columns = useRef([
    { data: "date", title: "Date" },
    { data: "type", title: "Type de rapport" },
    { data: "severity", title: "Sévérité" },
    { data: "description", title: "Description" },
    { data: "ip_address", title: "Adresse IP" },
    { data: "device_id", title: "Identifiant de l'appareil" },
    { data: "user_affected", title: "Utilisateur affecté" },
    { data: "origin", title: "Origine" },
    {
      data: null,
      title: "Actions",
      orderable: false,
      searchable: false,
      render: function (row) {
        return `
          <div class="d-flex gap-2">
            <a title="Bannir" id="action-row-${row.id}-method-banish" class="btn btn-sm btn-danger btn-icon" >
               <svg
                      
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      class="icon-32" width="32" height="32"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
                      ></path>
                    </svg>
            </a>
          </div>
        `;
      },
    },
  ]);

  const listtableRef = useRef(null);

  const handleBanClick = (row) => {
    Swal.fire({
      icon: "warning",
      title: "Banissement",
      text: "Voulez-vous vraiment bannir cette adresse IP ou cet appareil ?",
      showDenyButton: true,
      showCancelButton: true,
      denyButtonText: "Bannir l'appareil",
      backdrop: `
                rgba(60,60,60,0.8)`,
      confirmButtonText: "Bannir l'IP",
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        setLoadingText(`Adresse IP en cours de bannissement ...`);

        setTimeout(() => {
          setIsLoading(false);
          Swal.fire("Succès ...", "Adresse IP banni avec succès !", "success");
        }, 5000);
      } else if (result.isDenied) {
        setIsLoading(true);
        setLoadingText(`Appareil en cours de bannissement ...`);

        setTimeout(() => {
          setIsLoading(false);
          Swal.fire("Succès ...", "Appareil banni avec succès !", "success");
        }, 5000);
      }
    });
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLoadingText("Recherche des rapports de sécurité en cours...");

    // Simuler un appel API
    setTimeout(() => {
      setLoadingText("");
      setTableData([
        {
          id: 1,
          date: "2023/10/01 14:30:45",
          type: "Connexion suspecte",
          severity: "Moyen",
          description: "Connexion depuis une IP inhabituelle",
          ip_address: "192.168.1.100",
          device_id: "ABC123XYZ",
          user_affected: "user1@example.com",
          origin: "Appareil mobile",
        },
        {
          id: 2,
          date: "2023/10/02 09:15:22",
          type: "Tentative de piratage",
          severity: "Élevé",
          description: "Tentative de force brute sur le compte admin",
          ip_address: "10.0.0.1",
          device_id: "DEF456UVW",
          user_affected: "admin@example.com",
          origin: "Appareil mobile",
        },
      ]);
      setIsLoading(false);
    }, 3000);
  };

  const handleExportReports = (event) => {
    event.preventDefault();
    handleCloseExportModal();
    setIsLoading(true);
    setLoadingText("Exportation des rapports en cours...");

    setTimeout(() => {
      setIsLoading(false);
      Sweetalert("success", "Succès ...", "Rapports exportés!", "");
    }, 3000);
  };

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: tableData,
    actionCallback: ({ id, method }) => {
      const row = tableData.find((item) => item.id.toString() === id); // Trouver la ligne correspondante
      if (!row) return;

      // Appeler la fonction correspondante en fonction de la méthode
      switch (method) {
        case "banish":
          handleBanClick(row);
          break;

        default:
          console.warn("Méthode non reconnue:", method);
      }
    },
  });

  return (
    <Fragment>
      <Row>
        {isLoading && (
          <ActivityIndicator
            title={"Rapports de sécurité"}
            text={loadingText}
          />
        )}
        <Col style={{ display: isLoading ? "none" : "block" }} sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Rapports de sécurité</h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <Form className="p-4" onSubmit={handleSearchClick}>
                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="startDate">Date de début</Form.Label>
                    <Form.Control type="date" id="startDate" />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="endDate">Date de fin</Form.Label>
                    <Form.Control type="date" id="endDate" />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="reportType">
                      Type de rapport
                    </Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="reportType"
                      options={reportTypeOptions}
                    />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="severity">Sévérité</Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="severity"
                      options={severityOptions}
                    />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="origin">Origine</Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="origin"
                      options={originOptions}
                    />
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col className="text-center">
                    <Button
                      variant="btn btn-success"
                      type="submit"
                      className="mt-5"
                    >
                      <svg
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon-24"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          cx="11.7669"
                          cy="11.7666"
                          r="8.98856"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                        <path
                          d="M18.0186 18.4851L21.5426 22"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>{" "}
                      <span>{t("search")} </span>
                    </Button>
                  </Col>
                </Row>
              </Form>
              <div className="d-flex align-items-center gap-3 my-5 justify-content-end">
                <Button
                  variant="text-center btn btn-primary d-flex gap-2"
                  onClick={handleShowExportModal}
                >
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span>Exporter</span>
                </Button>
                <Modal show={showExportModal} onHide={handleCloseExportModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Exporter des utilisateurs</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label htmlFor="typeOfUser">
                        Format de fichier
                      </Form.Label>
                      <Select
                        data-trigger
                        name="choices-single-default"
                        id="typeOfExportFormat"
                        options={typeExportFormatOptions}
                      />
                    </Form.Group>
                    <Button variant="primary" onClick={handleExportReports}>
                      Exporter
                    </Button>{" "}
                    <Button variant="danger" onClick={handleCloseExportModal}>
                      Cancel
                    </Button>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="table-responsive">
                <table
                  id="datatable-security-reports"
                  ref={listtableRef}
                  className="data-tables table custom-table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

SecurityReportList.displayName = "SecurityReportList";
export default SecurityReportList;
