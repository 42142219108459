import React, { memo, Fragment, useRef, useState, useEffect } from "react";
import { Row, Col, Dropdown, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ActivityIndicator from "../../../components/partials/dashboard/mainstyle/activity-indicator";

import { Sweetalert } from "../../../components/partials/dashboard/mainstyle/sweet-alert";

import Card from "../../../components/bootstrap/card";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";

//Hooks
import useDataTable from "../../../components/hooks/useDatatable";

// img
import shap1 from "../../../assets/images/shapes/01.png";
import shap2 from "../../../assets/images/shapes/02.png";
import shap3 from "../../../assets/images/shapes/03.png";
import shap4 from "../../../assets/images/shapes/04.png";
import shap5 from "../../../assets/images/shapes/05.png";
import shap6 from "../../../assets/images/shapes/06.png";

const TicketList = memo(() => {
  const { t } = useTranslation();
  let history = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [show, setShow] = useState(false);
  const [assignTo, setAssignTo] = useState("utilisateur");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAssignToChange = (event) => {
    setAssignTo(event.target.value);
  };

  const userOptions = [
    { value: "1", label: "Élodie Martin" },
    { value: "2", label: "Théo Lambert" },
    { value: "3", label: "Camille Dubois" },
    { value: "4", label: "Lucas Moreau" },
    { value: "5", label: "Jules Fontaine" },
    { value: "6", label: "Léa Girard" },
  ];

  const groupOptions = [
    { value: "1", label: "Admin groupe" },
    { value: "2", label: "Support Niveau 1" },
    { value: "3", label: "Support Technique" },
    { value: "4", label: "Support Premium" },
  ];

  const typeCanalOptions = [
    { value: "tout", label: "Tout" },
    { value: "sms", label: "SMS" },
    { value: "email", label: "Email" },
    { value: "whatsapp", label: "Whatsapp" },
  ];

  const stateOfMessageOptions = [
    { value: "tout", label: "Tout" },
    { value: "archivés", label: "Archivés" },
    { value: "non archivés", label: "Non Archivés" },
  ];

  const typeExportFormatOptions = [
    { value: "pdf", label: "PDF" },
    { value: "csv", label: "CSV" },
    { value: "xls", label: "XLS" },
  ];

  const columns = useRef([
    { data: "date", title: "Date" },
    { data: "client", title: "Client" },
    { data: "type", title: "Type" },
    { data: "content", title: "Contenu" },
    { data: "unreadReply", title: "Réponses non lu" },
    {
      data: null,
      title: "Responsable",
      render: function (row) {
        return `
          <div> 
            <span>Utilisateur: ${row.userManager}</span>
          </div>
          <div> 
            <span>Groupe: ${row.groupManager}</span>
          </div>
          `;
      },
    },
    { data: "state", title: "Statut" },
    { data: "transfertState", title: "Transféré" },

    {
      data: null,
      orderable: false,
      searchable: false,
      title: "Action",
      render: function (row) {
        let toggleCloseButton = "";
        if (row.state === "Non archivé") {
          toggleCloseButton = `
            <a title="Fermer" id="action-row-${row.id}-method-close" class="btn btn-sm btn-icon btn-danger" data-bs-toggle="tooltip" data-bs-placement="top" href="#" aria-label="Close" data-bs-original-title="Close">
               <span class="btn-inner">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" width="24" height="24" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M7.67 1.99927H16.34C19.73 1.99927 22 4.37927 22 7.91927V16.0903C22 19.6203 19.73 21.9993 16.34 21.9993H7.67C4.28 21.9993 2 19.6203 2 16.0903V7.91927C2 4.37927 4.28 1.99927 7.67 1.99927ZM15.01 14.9993C15.35 14.6603 15.35 14.1103 15.01 13.7703L13.23 11.9903L15.01 10.2093C15.35 9.87027 15.35 9.31027 15.01 8.97027C14.67 8.62927 14.12 8.62927 13.77 8.97027L12 10.7493L10.22 8.97027C9.87 8.62927 9.32 8.62927 8.98 8.97027C8.64 9.31027 8.64 9.87027 8.98 10.2093L10.76 11.9903L8.98 13.7603C8.64 14.1103 8.64 14.6603 8.98 14.9993C9.15 15.1693 9.38 15.2603 9.6 15.2603C9.83 15.2603 10.05 15.1693 10.22 14.9993L12 13.2303L13.78 14.9993C13.95 15.1803 14.17 15.2603 14.39 15.2603C14.62 15.2603 14.84 15.1693 15.01 14.9993Z" fill="currentColor"></path></svg>
              </span>
            </a>
          `;
        } else if (row.state === "Archivé") {
          toggleCloseButton = `
            <a title="Ouvrir" id="action-row-${row.id}-method-close" class="btn btn-sm btn-icon btn-success" data-bs-toggle="tooltip" data-bs-placement="top" href="#" aria-label="Open" data-bs-original-title="Open">
              <span class="btn-inner">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" width="24" height="24" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M7.67 2H16.34C19.73 2 22 4.38 22 7.92V16.091C22 19.62 19.73 22 16.34 22H7.67C4.28 22 2 19.62 2 16.091V7.92C2 4.38 4.28 2 7.67 2ZM11.43 14.99L16.18 10.24C16.52 9.9 16.52 9.35 16.18 9C15.84 8.66 15.28 8.66 14.94 9L10.81 13.13L9.06 11.38C8.72 11.04 8.16 11.04 7.82 11.38C7.48 11.72 7.48 12.27 7.82 12.62L10.2 14.99C10.37 15.16 10.59 15.24 10.81 15.24C11.04 15.24 11.26 15.16 11.43 14.99Z" fill="currentColor"></path></svg>
              </span>
            </a>
          `;
        }
        return `
            <div class="d-flex align-items-center list-user-action justify-content-between">
              <div>  
                <a title="Repondre" id="action-row-${row.id}-method-reply" class="btn btn-sm btn-icon btn-info" data-bs-toggle="tooltip" data-bs-placement="top" href="#" aria-label="Reply" data-bs-original-title="Reply">
                  <span class="btn-inner">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon-32"  width="32" height="32" viewBox="0 0 24 24" fill="currentColor"><path d="M11 20L1 12L11 4V9C16.5228 9 21 13.4772 21 19C21 19.2729 20.9891 19.5433 20.9676 19.8107C19.4605 16.9502 16.458 15 13 15H11V20Z"></path></svg>                                      
                  </span>
                </a>
              </div> 

              <div class="mx-1">  
                <a title="Transférer" id="action-row-${row.id}-method-transfer" class="btn btn-sm btn-icon btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" href="#" aria-label="Transfer" data-bs-original-title="Transfer">
                  <span class="btn-inner">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon-32" width="32" height="32" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6 17 18H15L15 6 17 6ZM13 12 7 18V6L13 12Z"></path></svg>                                       
                  </span>
                </a>
              </div> 

              <div>  
                  ${toggleCloseButton} 
              </div> 
             
            </div>`;
      },
    },
  ]);

  const listtableRef = useRef(null);

  const handleSearchClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLoadingText("Recherche des tickets en cours ...");

    //Call API here
    setTimeout(() => {
      // Code à exécuter après le délai

      setLoadingText("");
      setTableData([
        {
          id: 1,
          date: "2019/12/01 20:25:68",
          client: "(760) 756 7568 (Tamafo Césor)",
          contact: "(760) 756  ",
          name: "Tamafo Césor",
          type: "SMS",
          content: "Mon message",
          unreadReply: 0,
          userManager: "Gomzo eoro",
          groupManager: "Support Niveau 1",
          state: "Non archivé",
          transfertState: "Oui",
        },

        {
          id: 2,
          date: "2019/12/01 12:56:32",
          client: "(760) 756 7567 (Mafo Rémy)",
          contact: "(760) 756 7567 ",
          name: "Mafo Rémy",
          type: "Whatsapp",
          content: "Mon message",
          unreadReply: 0,
          userManager: "Gomzo eoro",
          groupManager: "Support Niveau 1",
          state: "Non archivé",
          transfertState: "Oui",
        },
        {
          id: 3,
          date: "2019/11/01 12:56:32",
          client: "(760) 756 7567 (Mafo Rémy)",
          contact: "(760) 756 7567 ",
          name: "Mafo Rémy",
          type: "Whatsapp",
          content: "Mon message",
          unreadReply: 0,
          userManager: "Gomzo eoro",
          groupManager: "Support Niveau 1",
          state: "Non archivé",
          transfertState: "Oui",
        },
      ]);
      setIsLoading(false);
    }, 5000);
  };

  const handleExportTicket = (event) => {
    event.preventDefault();
    handleClose();
    setIsLoading(true);
    setLoadingText("Exportation des tickets en cours...");

    setTimeout(() => {
      // Code à exécuter après le délai
      setIsLoading(false);
      Sweetalert("success", "Succès ...", "Tickets exportés!", "");
    }, 5000);
  };

  // Function for add ticket
  const handleAddTicket = (event) => {
    history("/dashboard/messaging/ticket-add");
  };

  // Function for add ticket
  const handleReplyTicket = (row) => {
    history("/dashboard/messaging/ticket-reply", { state: row });
  };

  // Function to close a ticket
  const handleCloseTicket = (row) => {
    setIsLoading(true);

    if (row.state === "Non archivé") {
      setLoadingText("Fermeture du ticket " + row.id + " en cours ...");
    } else {
      setLoadingText("Ouverture du ticket " + row.id + " en cours ...");
    }

    setTimeout(() => {
      // Find the ticket in the tableData array
      const updatedTableData = tableData.map((ticket) => {
        if (ticket.id === row.id) {
          // Toggle the state between "archivé" and "non archivé"
          return {
            ...ticket,
            state: ticket.state === "Archivé" ? "Non archivé" : "Archivé",
          };
        }
        return ticket;
      });

      // Update the tableData state with the modified ticket
      setTableData(updatedTableData);
      setIsLoading(false);

      if (row.state === "Archivé") {
        Sweetalert("success", "Succès ...", "Ticket ouvert!", "");
      } else {
        Sweetalert("success", "Succès ...", "Ticket fermé!", "");
      }
    }, 5000);
  };

  // Function for add ticket
  const handleTransferTicket = (row) => {
    history("/dashboard/messaging/ticket-transfer", { state: row });
  };

  useEffect(() => {
    setTableData([
      {
        id: 1,
        date: "2019/12/01 20:25:68",
        client: "(760) 756 7568 (Tamafo Césor)",
        contact: "(760) 756 7568 ",
        name: "Tamafo Césor",
        type: "SMS",
        content: "Mon message",
        unreadReply: 0,
        userManager: "Gomzo eoro",
        groupManager: "Support Niveau 1",
        state: "Non archivé",
        transfertState: "Oui",
      },

      {
        id: 2,
        date: "2019/12/01 12:56:32",
        client: "(760) 756 7568 (Mafo Rémy)",
        contact: "(760) 756 7568 ",
        name: "Tamafo Césor",
        type: "Whatsapp",
        content: "Mon message",
        unreadReply: 0,
        userManager: "Gomzo eoro",
        groupManager: "Support Niveau 1",
        state: "Non archivé",
        transfertState: "Oui",
      },
    ]);
  }, []);

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: tableData,
    actionCallback: ({ id, method }) => {
      const row = tableData.find((item) => item.id.toString() === id); // Trouver la ligne correspondante
      if (!row) return;

      // Appeler la fonction correspondante en fonction de la méthode
      switch (method) {
        case "reply":
          handleReplyTicket(row);
          break;
        case "transfer":
          handleTransferTicket(row);
          break;
        case "close":
          handleCloseTicket(row);
          break;

        default:
          console.warn("Méthode non reconnue:", method);
      }
    },
  });

  return (
    <Fragment>
      <Row>
        {isLoading && (
          <ActivityIndicator title={"Liste des tickets"} text={loadingText} />
        )}
        <Col style={{ display: isLoading ? "none" : "block" }} sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Ticket List</h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <Row className="">
                <Col className="text-end mx-4">
                  <Button
                    variant="btn btn-success"
                    type="button"
                    className=""
                    onClick={handleAddTicket}
                  >
                    <svg
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="icon-24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 4v16m8-8H4"
                      ></path>
                    </svg>{" "}
                    <span>Nouveau Ticket </span>
                  </Button>
                </Col>
              </Row>

              <Form className="p-4" onSubmit={handleSearchClick}>
                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="startDate">
                      {t("startDate")}
                    </Form.Label>
                    <Form.Control type="date" id="startDate" />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="endDate">{t("endDate")}</Form.Label>
                    <Form.Control type="date" id="endDate" />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="assignTo">Affecté à</Form.Label>
                    <Form.Group className="d-flex  justify-content-between">
                      <Form.Check className="d-block">
                        <Form.Check.Input
                          className="me-2"
                          type="radio"
                          name="assignTo"
                          id="assignTo1"
                          value={"utilisateur"}
                          checked={assignTo === "utilisateur"}
                          onChange={handleAssignToChange}
                        />
                        <Form.Check.Label className="me-3" htmlFor="assignTo1">
                          Utilisateur
                        </Form.Check.Label>
                      </Form.Check>
                      <Form.Check className="d-block">
                        <Form.Check.Input
                          className="me-2"
                          type="radio"
                          name="assignTo"
                          id="assignTo2"
                          value={"groupe"}
                          checked={assignTo === "groupe"}
                          onChange={handleAssignToChange}
                        />
                        <Form.Check.Label htmlFor="assignTo2">
                          Groupe
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </Col>

                  {assignTo === "utilisateur" && (
                    <Col className="mb-3">
                      <Form.Label htmlFor="user">Utilisateur</Form.Label>
                      <Select
                        data-trigger
                        name="choices-single-default"
                        id="user"
                        options={userOptions}
                      />
                    </Col>
                  )}

                  {assignTo === "groupe" && (
                    <Col className="mb-3">
                      <Form.Label htmlFor="group">Groupe</Form.Label>
                      <Select
                        data-trigger
                        name="choices-single-default"
                        id="group"
                        options={groupOptions}
                      />
                    </Col>
                  )}
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="typeOfCanal">Type de canal</Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="typeOfCanal"
                      options={typeCanalOptions}
                    />
                  </Col>

                  <Col className="mb-3">
                    <Form.Label htmlFor="stateOfMessage">
                      Status du message
                    </Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="stateOfMessage"
                      options={stateOfMessageOptions}
                    />
                  </Col>
                </Row>

                <Row className="my-3">
                  <Col className="text-center">
                    <Button
                      variant="btn btn-success"
                      type="submit"
                      className="mt-5"
                    >
                      <svg
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon-24"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          cx="11.7669"
                          cy="11.7666"
                          r="8.98856"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                        <path
                          d="M18.0186 18.4851L21.5426 22"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>{" "}
                      <span>{t("search")} </span>
                    </Button>
                  </Col>
                </Row>
              </Form>
              <div className="d-flex align-items-center gap-3 my-5 justify-content-end">
                <Button
                  variant="text-center btn btn-primary d-flex gap-2"
                  onClick={handleShow}
                >
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span>Exporter</span>
                </Button>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Exporter des tickets</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label htmlFor="typeOfUser">
                        Format de fichier
                      </Form.Label>
                      <Select
                        data-trigger
                        name="choices-single-default"
                        id="typeOfExportFormat"
                        options={typeExportFormatOptions}
                      />
                    </Form.Group>
                    <Button variant="primary" onClick={handleExportTicket}>
                      Exporter
                    </Button>{" "}
                    <Button variant="danger" onClick={handleClose}>
                      Cancel
                    </Button>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

TicketList.displayName = "TicketList";
export default TicketList;
