import React, { memo, Fragment, useState, useEffect } from "react";

// React-bootstrap
import { Row, Col, Badge, Button } from "react-bootstrap";

// Components
import Document from "../../modules/file-manager/components/document.js";
import Images from "../../modules/file-manager/components/images.js";
import Video from "../../modules/file-manager/components/video.js";

// Images
import img1 from "../../../assets/modules/file-manager/images/5.png";
import img2 from "../../../assets/modules/file-manager/images/6.png";
import img3 from "../../../assets/modules/file-manager/images/7.png";
import img4 from "../../../assets/modules/file-manager/images/8.png";
import pdf from "../../../assets/modules/file-manager/images/pdf.svg";
import excel from "../../../assets/modules/file-manager/images/excel.svg";
import word from "../../../assets/modules/file-manager/images/word.svg";
import pptx from "../../../assets/modules/file-manager/images/ppt.svg";

import ActivityIndicator from "../../../components/partials/dashboard/mainstyle/activity-indicator.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  accessToken,
  operation,
  userVerificationData,
  userVerificationRequest,
} from "../../../store/setting/selectors";
import {
  certifyUserAsConfirmedDriver,
  getUserVerificationRequest,
  rejectUserAsConfirmedDriver,
} from "../../../services/http/reducers/userReducers.js";
import { useLocation } from "react-router-dom";
import { setUserVerificationData } from "../../../services/http/actions/userActions.js";
import Swal from "sweetalert2";
import * as FormData from "form-data";

const UserFile = memo(() => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const { loading, error } = useSelector(operation); // Accédez à l'état user
  const token = useSelector(accessToken); // Accédez à l'état user
  const user = useSelector(userVerificationData); // Accédez à l'état user
  const requests = useSelector(userVerificationRequest); // Accédez à l'état requests

  // State pour gérer les documents
  const [documents, setDocuments] = useState([]);

  // Function for certifying a user
  const handleCertify = (request) => {
    const status = request.status;

    // Créer un objet FormData
    const formData = new FormData();
    formData.append("idUser", request.idUser);

    Swal.fire({
      title: "Êtes-vous sûr ?",
      text:
        status !== "ACCEPTED"
          ? `Certification de l'utiisateur ${user.name}!`
          : `Rejet de l'utiisateur ${user.name}!`,
      icon: "warning",
      showCancelButton: true,
      backdrop: `rgba(60,60,60,0.8)`,
      confirmButtonText:
        status !== "ACCEPTED" ? "Oui, certifier le!" : "Oui, rejeté le!",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoadingText(
          status !== "ACCETED"
            ? "Certification de l'utilisateur en cours ..."
            : "Rejet de l'utilisateur en cours ..."
        );

        if (status !== "ACCEPTED") {
          dispatch(
            certifyUserAsConfirmedDriver({
              idUser: request.idUser,
              token: token,
              formData: formData,
            })
          );
        } else {
          dispatch(
            rejectUserAsConfirmedDriver({
              idUser: request.idUser,
              token: token,
              formData: formData,
            })
          );
        }
      }
    });
  };

  // Fonction pour changer le statut d'un document
  const toggleStatus = (index) => {
    const updatedDocuments = [...documents];
    setIsLoading(true);

    if (updatedDocuments[index].status === "approuvé") {
      setLoadingText(
        updatedDocuments[index].nature + " désapprobation en cours ..."
      );
    } else {
      setLoadingText(
        updatedDocuments[index].nature + " approbation en cours ..."
      );
    }

    setTimeout(() => {
      updatedDocuments[index].status =
        updatedDocuments[index].status === "approuvé"
          ? "désapprouvé"
          : "approuvé";
      setDocuments(updatedDocuments);
      setIsLoading(false);
    }, 5000);
  };

  // Fonction pour afficher le badge de statut
  const renderStatusBadge = (status) => {
    if (status === "ACCEPTED") {
      return <Badge bg="success">{t("accepted")}</Badge>;
    } else if (status === "PENDING") {
      return <Badge bg="warning">{t("pending")}</Badge>;
    } else if (status === "REJECTED") {
      return <Badge bg="danger">{t("rejected")}</Badge>;
    }
    return null;
  };

  // Fonction pour déterminer l'état de validation d'une section
  const getSectionValidationStatus = (section) => {
    const sectionDocs = documents.filter((doc) => doc.nature === section);
    const approvedCount = sectionDocs.filter(
      (doc) => doc.status === "ACCEPTED"
    ).length;
    const disapprovedCount = sectionDocs.filter(
      (doc) => doc.status === "REJECTED"
    ).length;

    const pendingCount = sectionDocs.filter(
      (doc) => doc.status === "PENDING"
    ).length;

    if (approvedCount !== 0 && approvedCount === sectionDocs.length) {
      return "Tous acceptés";
    } else if (
      disapprovedCount !== 0 &&
      disapprovedCount === sectionDocs.length
    ) {
      return "Tous rejetés";
    } else if (pendingCount !== 0 && pendingCount === sectionDocs.length) {
      return "Tous en attente";
    } else {
      return "Non soumise";
    }
  };

  // Fonction pour afficher le badge de validation de la section
  const renderSectionValidationBadge = (section) => {
    const status = getSectionValidationStatus(section);
    if (status === "Tous acceptés") {
      return <Badge bg="success">{status}</Badge>;
    } else if (status === "Tous rejetés") {
      return <Badge bg="danger">{status}</Badge>;
    } else {
      return <Badge bg="warning">{status}</Badge>;
    }
  };

  // Définir les sections d'affichage
  const sections = ["pièce d'identité", "permis de conduire"];

  useEffect(() => {
    const parameters = {
      idUser: user.id,
    };

    console.log("parameter");
    console.log(parameters);
    setLoadingText("Chargement des documents en cours ...");
    dispatch(
      getUserVerificationRequest({ token: token, parameters: parameters })
    );
  }, [dispatch, token, user.id]);

  useEffect(() => {
    // set full name
    if (requests.length !== 0) {
      dispatch(
        setUserVerificationData({
          ...location.state,
          fullName: requests[0].fullName,
        })
      );
    }

    // Mapping
    const documentsTemp = [];

    for (let i = 0; i <= requests.length - 1; i++) {
      if (Object.keys(requests[i]).includes("idCard")) {
        const extension = requests[i].idCard.split(".").pop();

        if (extension.toLowerCase() === "pdf") {
          documentsTemp.push({
            type: "document",
            media: "pdf",
            nature: "pièce d'identité",
            status: requests[i].status,
            imageDate: "",
            imgName: "Pièce d'identité n° " + requests[i].cardNumber,
            imgLink: "",
            imgChange: pdf,
            extension: extension,
            file: requests[i].idCard,
          });
        } else {
          documentsTemp.push({
            type: "image",
            nature: "pièce d'identité",
            status: requests[i].status,
            imageDate: "",
            imgName: "Pièce d'identité n° " + requests[i].cardNumber,
            imgLink: "",
            imgChange: requests[i].idCard,
            extension: extension,
          });
        }
      }

      if (Object.keys(requests[i]).includes("driverLicense")) {
        const extension = requests[i].idCard.split(".").pop();

        if (extension.toLowerCase() === "pdf") {
          documentsTemp.push({
            type: "document",
            media: "pdf",
            nature: "permis de conduire",
            status: requests[i].status,
            imageDate: "",
            imgName: "Permis de conduire n° " + requests[i].licenseNumber,
            imgLink: "",
            imgChange: pdf,
            file: requests[i].driverLicense,
            extension: extension,
          });
        } else {
          documentsTemp.push({
            type: "image",
            nature: "permis de conduire",
            status: requests[i].status,
            imageDate: "",
            imgName: "Permis de conduire n° " + requests[i].licenseNumber,
            imgLink: "",
            imgChange: requests[i].driverLicense,
            extension: extension,
          });
        }
      }
    }

    setDocuments(documentsTemp);

    // mapping
  }, [dispatch, requests, location.state]);

  return (
    <Fragment>
      <div className="border-bottom  d-flex align-items-center justify-content-start mb-5">
        <h5 className="py-0 mb-0">Tous les documents à vérifier</h5>
        <div className="d-grid gap-2 ms-3">
          {requests.length !== 0 ? (
            <Button
              variant={
                requests[0].status !== "ACCEPTED"
                  ? "outline-success"
                  : "outline-danger"
              }
              size="sm"
              disabled={requests.length !== 0 ? false : true}
              onClick={(e) => {
                handleCertify(requests[0]);
              }}
            >
              {requests[0].status !== "ACCEPTED"
                ? "Certifier l'utilisateur"
                : "Rejeter l'utilisateur"}
            </Button>
          ) : null}
        </div>
      </div>

      {/* Afficher les sections spécifiques */}

      {loading ? (
        <div>
          <ActivityIndicator
            title={"Vérification des documents"}
            text={loadingText}
          />
        </div>
      ) : (
        sections.map((section) => (
          <div key={section} className="mb-5">
            <h4 className="py-3 mb-0">
              {section.charAt(0).toUpperCase() + section.slice(1)}{" "}
              {renderSectionValidationBadge(section)}
            </h4>
            <Row className="row-cols-1 row-cols-md-2 row-cols-lg-4">
              {documents

                .filter((doc) => doc.nature === section)
                .map((doc, index) => (
                  <Col key={index}>
                    <div className="mt-2">{renderStatusBadge(doc.status)}</div>
                    {doc.type === "image" ? (
                      <Images {...doc} />
                    ) : (
                      <Document {...doc} />
                    )}
                  </Col>
                ))}
            </Row>
          </div>
        ))
      )}
    </Fragment>
  );
});

UserFile.displayName = "UserFile";
export default UserFile;
