import React, { memo, Fragment } from "react";

// React-bootstrap
import { Row, Col, Form, Button, Image } from "react-bootstrap";

//Components
import Autheffect from "../components/auth-effect";
import Card from "../../../../components/bootstrap/card";
import { useNavigate, Link } from "react-router-dom";

import img1 from "../../../../assets/images/favicon.ico";

const Twofactor = memo(() => {
  let history = useNavigate();
  return (
    <Fragment>
      <div className="iq-auth-page">
        <Autheffect />
        <Row className="align-items-center iq-auth-container w-100">
          <Col lg="4" className="col-10 offset-lg-7 offset-1">
            <Card>
              <Card.Body>
                <div className="my-3">
                  <Link
                    to="/auth/sign-in"
                    className="iq-link d-flex align-items-center justify-content-center"
                  >
                    <Image src={img1} alt="logo" loading="lazy" />
                    {/* <h4 data-setting="app_name" className="mb-0">
                      {appName}
                    </h4> */}
                  </Link>
                </div>
                <h4 className="pb-2 text-center">Two Factor -Verification</h4>
                <p>
                  Enter your email address and we’ll send you an email with
                  instructions to reset your password
                </p>
                <div className="form-group me-3">
                  <Form.Label htmlFor="otp01">
                    Enter the OTP you recieved to veify your device
                  </Form.Label>
                  <Form.Control
                    type="email"
                    className="mb-0"
                    id="otp01"
                    placeholder="Enter OTP"
                  />
                </div>
                <Button onClick={() => history("/dashboard")}>Verify</Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
});

Twofactor.displayName = "Twofactor";
export default Twofactor;
