import React from "react";

// default layout
import Default from "../layouts/dashboard/default";

// Dashboard
import Index from "../views/dashboard/index";
import AlternateDashboard from "../views/dashboard/alternate-dashboard";

// icon pages
import Solid from "../views/dashboard/icons/solid";
import Outline from "../views/dashboard/icons/outline";
import DualTone from "../views/dashboard/icons/dual-tone";

// special pages
import Billing from "../views/dashboard/special-pages/billing";
import Calender from "../views/dashboard/special-pages/calender";
import Kanban from "../views/dashboard/special-pages/kanban";
import Pricing from "../views/dashboard/special-pages/pricing";
import Timeline from "../views/dashboard/special-pages/timeline";

// user pages
import Userprofile from "../views/dashboard/app/user-profile";
import UserAdd from "../views/dashboard/app/user-add";
import UserEdit from "../views/dashboard/app/user-edit";
import UserListMobile from "../views/dashboard/app/user-list-mobile";
import UserListEmployees from "../views/dashboard/app/user-list-employees";
import UserFile from "../views/dashboard/app/user-file";
import PathList from "../views/dashboard/path/path-list";
// extra
import Admin from "../views/dashboard/admin/admin";
import Uicolor from "../views/modules/plugins/pages/ui-color";

// widget
import Widgetbasic from "../views/dashboard/widget/widgetbasic";
import Widgetchart from "../views/dashboard/widget/widgetchart";
import Widgetcard from "../views/dashboard/widget/widgetcard";

// map
import Google from "../views/dashboard/maps/google";

// form
import FormElement from "../views/dashboard/from/form-element";
import FormWizard from "../views/dashboard/from/form-wizard";
import FormValidation from "../views/dashboard/from/form-validation";

// table
import BootstrapTable from "../views/dashboard/table/bootstrap-table";
import TableData from "../views/dashboard/table/table-data";
import Borderedtable from "../views/dashboard/table/border-table";
import Fancytable from "../views/dashboard/table/fancy-table";
import FixedTable from "../views/dashboard/table/fixed-table";

//extra
import PrivacyPolicy from "../views/dashboard/extra/privacy-policy";
import TermsofService from "../views/dashboard/extra/terms-of-service";
import BlankPage from "../views/dashboard/extra/blank-page";
import ShimmerComponent from "../views/dashboard/extra/ShimmerComponent";

import BlankPage1 from "../views/dashboard/extra/blank-page1";
import TicketList from "../views/dashboard/messaging/ticket-list";
import TicketAdd from "../views/dashboard/messaging/ticket-add";
import TicketTransfer from "../views/dashboard/messaging/ticket-transfer";
import DeviceList from "../views/dashboard/protection-and-security/device-list";
import SecurityReportList from "../views/dashboard/protection-and-security/security-report-list";
import RatingFollowUpList from "../views/dashboard/protection-and-security/rating-follow-up-list";
import TicketResponse from "../views/dashboard/messaging/ticket-response";
import CarpoolRequestLlist from "../views/dashboard/reservation/carpool/request-list";
import ParcelRequestLlist from "../views/dashboard/reservation/parcel/request-list";
import RequestDetails from "../views/dashboard/reservation/parcel/request-details";
import ParcelRequestDetails from "../views/dashboard/reservation/parcel/request-details";
import CarpoolRequestDetails from "../views/dashboard/reservation/carpool/request-details";
import ParcelRequestEdit from "../views/dashboard/reservation/parcel/request-edit";
import VerificationProofDelivery from "../views/dashboard/reservation/parcel/verification-proof-delivery";
import ReservationDashboard from "../views/dashboard/reservation/dashboard";
import TransactionList from "../views/dashboard/finance/transaction-list";
import FinanceDashboard from "../views/dashboard/finance/dashboard";

export const DefaultRouter = [
  {
    path: "",
    element: <Default />,
    children: [
      {
        path: "dashboard",
        element: <Index />,
        name: "home",
        active: "home",
      },
      {
        path: "dashboard/alternate-dashboard",
        element: <AlternateDashboard />,
        name: "alternate dashboard",
        active: "home",
      },
      // Special Pages
      {
        path: "dashboard/special-pages/billing",
        element: <Billing />,
        name: "Billing",
        active: "pages",
        subActive: "SpecialPages",
      },
      {
        path: "dashboard/special-pages/calender",
        element: <Calender />,
        name: "Calendar",
        active: "pages",
        subActive: "SpecialPages",
      },
      {
        path: "dashboard/special-pages/kanban",
        element: <Kanban />,
        name: "Kanban",
        active: "pages",
        subActive: "SpecialPages",
      },
      {
        path: "dashboard/special-pages/pricing",
        element: <Pricing />,
        name: "Pricing",
        active: "pages",
        subActive: "SpecialPages",
      },
      {
        path: "dashboard/special-pages/timeline",
        element: <Timeline />,
        name: "Timeline",
        active: "pages",
        subActive: "SpecialPages",
      },

      //User
      {
        path: "dashboard/app/user-profile",
        element: <Userprofile />,
        name: "User Profile",
        active: "pages",
        subActive: "User",
      },
      {
        path: "dashboard/app/user-add",
        element: <UserAdd />,
        name: "User Add",
        active: "pages",
        subActive: "User",
      },
      {
        path: "dashboard/app/user-edit",
        element: <UserEdit />,
        name: "User Edit",
        active: "pages",
        subActive: "User",
      },
      {
        path: "dashboard/app/user-file",
        element: <UserFile />,
        name: "User File",
        active: "pages",
        subActive: "User",
      },
      {
        path: "dashboard/app/user-list-employees",
        element: <UserListEmployees />,
        name: "User Employees",
        active: "pages",
        subActive: "User",
      },

      {
        path: "dashboard/app/user-list-mobile",
        element: <UserListMobile />,
        name: "User Mobile",
        active: "pages",
        subActive: "User",
      },

      //Messaging
      {
        path: "dashboard/messaging/ticket-list",
        element: <TicketList />,
        name: "Ticket List",
        active: "pages",
        subActive: "Ticket",
      },

      {
        path: "dashboard/messaging/ticket-add",
        element: <TicketAdd />,
        name: "Ticket Add",
        active: "pages",
        subActive: "Ticket",
      },

      {
        path: "dashboard/messaging/ticket-transfer",
        element: <TicketTransfer />,
        name: "Ticket Transfer",
        active: "pages",
        subActive: "Ticket",
      },

      {
        path: "dashboard/messaging/ticket-reply",
        element: <TicketResponse />,
        name: "Ticket Reply",
        active: "pages",
        subActive: "Ticket",
      },

      //Security and protection
      {
        path: "dashboard/protection-and-security/device-list",
        element: <DeviceList />,
        name: "Device List",
        active: "pages",
        subActive: "Device",
      },

      {
        path: "dashboard/protection-and-security/security-report-list",
        element: <SecurityReportList />,
        name: "Report List",
        active: "pages",
        subActive: "Report ",
      },
      {
        path: "dashboard/protection-and-security/rating-follow-up-list",
        element: <RatingFollowUpList />,
        name: "Rating List",
        active: "pages",
        subActive: "Rating",
      },

      //Reservation
      {
        path: "dashboard/reservation/carpool/request-list",
        element: <CarpoolRequestLlist />,
        name: "Carpool Request",
        active: "pages",
        subActive: "Carpool",
      },
      {
        path: "dashboard/reservation/parcel/request-list",
        element: <ParcelRequestLlist />,
        name: "Parcel Request",
        active: "pages",
        subActive: "Parcel",
      },

      {
        path: "dashboard/reservation/carpool/dashboard",
        element: <Userprofile />,
        name: "Carpool Dashboard",
        active: "pages",
        subActive: "Carpool",
      },

      {
        path: "dashboard/reservation/parcel/dashboard",
        element: <Userprofile />,
        name: "Parcel Dashboard",
        active: "pages",
        subActive: "Parcel",
      },

      {
        path: "dashboard/reservation/parcel/request-details",
        element: <ParcelRequestDetails />,
        name: "Parcel Details",
        active: "pages",
        subActive: "Parcel",
      },

      {
        path: "dashboard/reservation/carpool/request-details",
        element: <CarpoolRequestDetails />,
        name: "Carpool Details",
        active: "pages",
        subActive: "Carpool",
      },

      {
        path: "dashboard/reservation/parcel/request-edit",
        element: <ParcelRequestEdit />,
        name: "Parcel Edit",
        active: "pages",
        subActive: "Parcel",
      },

      {
        path: "dashboard/reservation/dashboard",
        element: <ReservationDashboard />,
        name: "Reservation Chart",
        active: "pages",
        subActive: "Reservation",
      },

      //Finance
      {
        path: "dashboard/finance/dashboard",
        element: <FinanceDashboard />,
        name: "Finance Chart",
        active: "pages",
        subActive: "Finance",
      },

      {
        path: "dashboard/finance/transaction-list",
        element: <TransactionList />,
        name: "Transaction List",
        active: "pages",
        subActive: "Transaction",
      },

      //Configuration

      //Marketing and loyalty

      // Admin
      {
        path: "dashboard/admin/admin",
        element: <Admin />,
        name: "Admin",
      },
      // Paths
      {
        path: "dashboard/app/path-list",
        element: <PathList />,
        name: "Path List",
      },
      // Ui-Color
      {
        path: "plugins/ui-color",
        element: <Uicolor />,
        name: "UI Color",
      },
      // Widgets
      {
        path: "dashboard/widget/widgetbasic",
        element: <Widgetbasic />,
        name: "Basic Widget",
        active: "elements",
        subActive: "Widgets",
      },
      {
        path: "dashboard/widget/widgetchart",
        element: <Widgetchart />,
        name: "Widget Chart",
        active: "elements",
        subActive: "Widgets",
      },
      {
        path: "dashboard/widget/widgetcard",
        element: <Widgetcard />,
        name: "Widget Card",
        active: "elements",
        subActive: "Widgets",
      },
      // Map
      {
        path: "dashboard/map/google",
        element: <Google />,
        name: "Google Map",
        active: "elements",
        subActive: "Map",
      },

      // Form
      {
        path: "dashboard/form/form-element",
        element: <FormElement />,
        name: "Form Elements",
        active: "elements",
        subActive: "Form",
      },
      {
        path: "dashboard/form/form-wizard",
        element: <FormWizard />,
        name: "Form Wizard",
        active: "elements",
        subActive: "Form",
      },
      {
        path: "dashboard/form/form-validation",
        element: <FormValidation />,
        name: "Form Validation",
        active: "elements",
        subActive: "Form",
      },
      // Table
      {
        path: "dashboard/table/bootstrap-table",
        element: <BootstrapTable />,
        name: "Bootstrap Table",
        active: "elements",
        subActive: "Table",
      },
      {
        path: "dashboard/table/table-data",
        element: <TableData />,
        name: "Data Table",
        active: "elements",
        subActive: "Table",
      },
      {
        path: "dashboard/table/border-table",
        element: <Borderedtable />,
        name: "Border Table",
        active: "elements",
        subActive: "Table",
      },
      {
        path: "dashboard/table/fancy-table",
        element: <Fancytable />,
        name: "Fancy Table",
        active: "elements",
        subActive: "Table",
      },
      {
        path: "dashboard/table/fixed-table",
        element: <FixedTable />,
        name: "Fixed Table",
        active: "elements",
        subActive: "Table",
      },

      // Icon
      {
        path: "dashboard/icon/solid",
        element: <Solid />,
        name: "Solid Icons",
        active: "elements",
        subActive: "Icon",
      },
      {
        path: "dashboard/icon/outline",
        element: <Outline />,
        name: "Outline Icons",
        active: "elements",
        subActive: "Icon",
      },
      {
        path: "dashboard/icon/dual-tone",
        element: <DualTone />,
        name: "Dual Tone Icons",
        active: "elements",
        subActive: "Icon",
      },
      // extra-pages
      {
        path: "dashboard/extra/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "dashboard/extra/terms-of-service",
        element: <TermsofService />,
      },
      {
        path: "dashboard/blank-page",
        element: <BlankPage1 />,
        name: "Blank-Page",
      },
      {
        path: "dashboard/extra/blank-page",
        element: <BlankPage />,
      },
      {
        path: "dashboard/extra/ShimmerComponent",
        element: <ShimmerComponent />,
      },
    ],
  },
];
