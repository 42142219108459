import React, { memo, Fragment, useState, useRef, useEffect } from "react";

//react-bootstrap
import { Row, Col, Image, Form, Button } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//router
import { Link, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import ActivityIndicator from "../../../components/partials/dashboard/mainstyle/activity-indicator";
import { useDispatch, useSelector } from "react-redux";
import {
  accessToken,
  operation,
  rolesName,
} from "../../../store/setting/selectors";
import {
  getAllRoles,
  updateAdmin,
  updateUser,
} from "../../../services/http/reducers/userReducers";

const UserEdit = memo((props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const user = location.state;

  const [validated, setValidated] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const emailRef = useRef(null);
  const birthDateRef = useRef(null);
  const contactRef = useRef(null);
  const userNameRef = useRef(null);

  const [email, setEmail] = useState(user ? user.email : "");
  const [birthDate, setBirthDate] = useState(user ? user.dateOfBirth : "");
  const [contact, setContact] = useState(user ? user.contact : "");
  const [userName, setUserName] = useState(user ? user.name : "");
  const [updateRole, setUpdateRole] = useState(user ? user.role : "");
  const [image, setImage] = useState(user ? user.image : "");
  const [role, setRole] = useState(user ? user.role : "");

  const typeUserOptions = ["USER"];

  const dispatch = useDispatch();
  const { loading, error } = useSelector(operation); // Accédez à l'état user
  const token = useSelector(accessToken); // Accédez à l'état user
  const roleNames = useSelector(rolesName); // Accédez à l'état user

  const handleSubmitModifyUser = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.stopPropagation();
      return;
    }

    console.log("Add new user data test stringlyfy");

    let bodyData = {};

    if (user.name !== userName) {
      bodyData.username = userName;
    }

    if (user.email !== email) {
      bodyData.email = email;
    }

    if (user.contact !== contact) {
      bodyData.phoneNumber = contact;
    }

    if (user.dateOfBirth !== birthDate) {
      bodyData.dateOfBirth = birthDate;
    }

    if (user.role !== updateRole) {
      bodyData.role = updateRole;
    }

    setLoadingText("Utilisateur en cours de modification...");

    if (user.role === "USER") {
      dispatch(
        updateUser({
          userId: user.id,
          bodyData: bodyData,
          token: token,
        })
      );
    } else {
      dispatch(
        updateAdmin({
          userId: user.id,
          bodyData: bodyData,
          token: token,
        })
      );
    }

    setValidated(false);
  };

  useEffect(() => {
    setLoadingText("Chargement des rôles ...");
    dispatch(getAllRoles(token));
  }, [dispatch, token]);

  return (
    <Fragment>
      <Row>
        {loading ? (
          <ActivityIndicator title={t("modifyuser")} text={loadingText} />
        ) : (
          <Col>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmitModifyUser}
              className="row g-3 needs-validation"
            >
              <Col xl="3" lg="4" className="">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Modify User</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div>
                      <Form.Group className="form-group">
                        <div className="profile-img-edit position-relative">
                          <Image
                            className="theme-color-default-img  profile-pic rounded avatar-100"
                            src={image}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-purple-img profile-pic rounded avatar-100"
                            src={image}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-blue-img profile-pic rounded avatar-100"
                            src={image}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-green-img profile-pic rounded avatar-100"
                            src={image}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-yellow-img profile-pic rounded avatar-100"
                            src={image}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-pink-img profile-pic rounded avatar-100"
                            src={image}
                            alt="profile-pic"
                          />
                          <div className="upload-icone bg-primary">
                            <svg
                              className="upload-button icon-14"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#ffffff"
                                d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                              />
                            </svg>
                            <Form.Control
                              className="file-upload"
                              type="file"
                              accept="image/*"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="img-extension mt-3">
                          <div className="d-inline-block align-items-center">
                            <span>Only</span> <Link to="#">.jpg</Link>{" "}
                            <Link to="#">.png</Link> <Link to="#">.jpeg</Link>{" "}
                            <span>allowed</span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>User Role:</Form.Label>
                        <select
                          name="type"
                          className="selectpicker form-control"
                          data-style="py-0"
                          required
                          onChange={(e) => {
                            setUpdateRole(e.target.value);
                          }}
                        >
                          {role !== "USER" ? (
                            roleNames.map((currentRole) => (
                              <option
                                key={currentRole}
                                value={currentRole}
                                selected={currentRole === updateRole}
                              >
                                {currentRole}
                              </option>
                            ))
                          ) : (
                            <option
                              key={"USER"}
                              value={"USER"}
                              selected={"USER" === role}
                            >
                              {"USER"}
                            </option>
                          )}
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Please choose a role
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl="9" lg="8">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Modify User Information</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="new-user-info">
                      <div>
                        <div className="row g-3 needs-validation">
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="validationCustom01">
                              {t("email")}
                            </Form.Label>
                            <Form.Control
                              type="email"
                              id="validationCustom01"
                              placeholder="Enter the e-mail address"
                              defaultValue={email}
                              required
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the e-mail address
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="birthdate">
                              {t("birthdate")}
                            </Form.Label>
                            <Form.Control
                              type="date"
                              id="birthdate"
                              placeholder="Enter the birthdate"
                              defaultValue={birthDate}
                              required
                              onChange={(e) => {
                                setBirthDate(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the birthdate
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="phonenumber">
                              {t("phonenumber")}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="phonenumber"
                              placeholder="Enter the contact"
                              defaultValue={contact}
                              required
                              onChange={(e) => {
                                setContact(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the contact
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="username">
                              User Name:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="username"
                              placeholder="User Name"
                              required
                              defaultValue={userName}
                              onChange={(e) => {
                                setUserName(e.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the user name
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <hr />

                        <Button type="submit" variant="btn btn-primary">
                          Modify User
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Form>
          </Col>
        )}
      </Row>
    </Fragment>
  );
});

UserEdit.displayName = "UserEdit";
export default UserEdit;
