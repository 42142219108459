import React, { memo, Fragment, useRef, useState, useEffect } from "react";

// React-Bootstrap
import { Row, Col, Dropdown, Modal } from "react-bootstrap";

// Components
import Card from "../../../components/bootstrap/card";

// Hooks
import useDataTable from "../../../components/hooks/useDatatable";

// Img
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import ActivityIndicator from "../../../components/partials/dashboard/mainstyle/activity-indicator";
import { Sweetalert } from "../../../components/partials/dashboard/mainstyle/sweet-alert";

import { format } from "date-fns";
import { fr } from "date-fns/locale";

const TransactionList = memo(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const debtorOptions = [];
  const beneficiaryOptions = [];

  const [tableData, setTableData] = useState([
    {
      transaction_id: "txn_123456",
      reservation_id: "RES123456",
      date: "2023-10-05T14:48:00Z",
      user: {
        user_id: "USR789",
        name: "Jean Dupont",
      },
      driver: {
        driver_id: "DRV321",
        name: "Marie Martin",
      },
      type: "Paiement",
      amount: 25.5,
      status: "Completé",
      payment_method: "Stripe",
    },
    {
      transaction_id: "txn_123457",
      reservation_id: "RES654321",
      date: "2023-10-06T15:30:00Z",
      user: {
        user_id: "USR456",
        name: "Paul Leroy",
      },
      driver: {
        driver_id: "DRV654",
        name: "Lucie Dubois",
      },
      type: "Remboursement",
      amount: 10.0,
      status: "En attente",
      payment_method: "Stripe",
    },
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const typeExportFormatOptions = [
    { value: "pdf", label: "PDF" },
    { value: "csv", label: "CSV" },
    { value: "xls", label: "XLS" },
  ];

  const columns = useRef([
    { data: "transaction_id", title: "ID Transaction" },
    {
      data: "reservation_id",
      title: "ID Réservation",
    },
    {
      data: null,
      title: "Date et heure",
      render: function (row) {
        const date = new Date(row.date);

        // Formatez la date et l'heure avec date-fns
        return format(date, "dd/MM/yyyy HH:mm:ss", { locale: fr }); // Format français
      },
    },
    {
      data: null,
      title: "Débiteur",
      render: function (row) {
        return `<span>${row.user.name}</span>`;
      },
    },
    {
      data: null,
      title: "Bénéficiaire",
      render: function (row) {
        return `<span>${row.driver.name}</span>`;
      },
    },
    { data: "type", title: "Type" },
    {
      data: null,
      title: "Montant",
      render: function (row) {
        return `${row.amount} $`;
      },
    },
    {
      data: null,
      title: "Statut",
      render: function (row) {
        let color = "";
        switch (row.status) {
          case "Completé":
            color = "success";
            break;
          case "En attente":
            color = "warning";
            break;
          case "Échoué":
            color = "danger";
            break;
          default:
            color = "secondary";
        }
        return `<span class="badge bg-${color}">${row.status}</span>`;
      },
    },

    // {
    //   data: null,
    //   orderable: false,
    //   searchable: false,
    //   title: "Action",
    //   render: function (row) {
    //     return `
    //       <div class="d-flex align-items-center list-user-action justify-content-between">
    //         <div>
    //           <a title="Détails" id="action-row-${row.transaction_id}-method-details" class="btn btn-sm btn-icon btn-info" data-bs-toggle="tooltip" data-bs-placement="top" href="#" aria-label="Détails" data-bs-original-title="Détails">
    //             <span class="btn-inner">
    //               <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                 <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    //                 <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
    //               </svg>
    //             </span>
    //           </a>
    //         </div>
    //       </div>`;
    //   },
    // },
  ]);

  const listtableRef = useRef(null);

  const handleSearchClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLoadingText("Recherche des transactions en cours ...");

    // Simuler une requête API
    setTimeout(() => {
      setIsLoading(false);
      setLoadingText("");
    }, 2000);
  };

  const handleExport = (event) => {
    event.preventDefault();
    handleClose();
    setIsLoading(true);
    setLoadingText("Exportation des transactions en cours...");

    setTimeout(() => {
      setIsLoading(false);
      Sweetalert("success", "Succès ...", "Transactions exportées!", "");
    }, 2000);
  };

  const handleDetails = (row) => {
    navigate("/dashboard/transaction/details", { state: row });
  };

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: tableData,
    actionCallback: ({ id, method }) => {
      const row = tableData.find((item) => item.transaction_id === id);
      if (!row) return;

      switch (method) {
        case "details":
          handleDetails(row);
          break;
        default:
          console.warn("Méthode non reconnue:", method);
      }
    },
  });

  return (
    <Fragment>
      <Row>
        {isLoading && (
          <ActivityIndicator
            title={"Liste des transactions financières"}
            text={loadingText}
          />
        )}
        <Col style={{ display: isLoading ? "none" : "block" }} sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">
                  Liste des transactions financières
                </h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <Form className="p-4" onSubmit={handleSearchClick}>
                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="startDate">Date de début</Form.Label>
                    <Form.Control type="date" id="startDate" />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="endDate">Date de fin</Form.Label>
                    <Form.Control type="date" id="endDate" />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="status">Statut</Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="status"
                      options={[
                        { value: "Completé", label: "Completé" },
                        { value: "En attente", label: "En attente" },
                        { value: "Échoué", label: "Échoué" },
                      ]}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="debtor">Débiteur</Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="debtor"
                      options={debtorOptions}
                    />
                  </Col>

                  <Col className="mb-3">
                    <Form.Label htmlFor="beneficiary">Bénéficiaire</Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="beneficiary"
                      options={beneficiaryOptions}
                    />
                  </Col>

                  <Col className="mb-3">
                    <Form.Label htmlFor="path_id">ID du trajet</Form.Label>
                    <Form.Control type="text" id="path_id" />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="transaction_id">
                      ID Transaction
                    </Form.Label>
                    <Form.Control type="text" id="transaction_id" />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="reservation_id">
                      ID Réservation
                    </Form.Label>
                    <Form.Control type="text" id="reservation_id" />
                  </Col>

                  <Col className="mb-3">
                    <Form.Label htmlFor="price">Prix</Form.Label>
                    <Form.Control type="text" id="price" />
                  </Col>
                </Row>

                <Row className="my-3">
                  <Col className="text-center">
                    <Button variant="btn btn-success" type="submit">
                      <span>Rechercher</span>
                    </Button>
                  </Col>
                </Row>
              </Form>
              <div className="d-flex align-items-center gap-3 my-5 justify-content-end">
                <Button variant="btn btn-primary" onClick={handleShow}>
                  Exporter
                </Button>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Exporter des transactions</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Format de fichier</Form.Label>
                      <Select
                        data-trigger
                        name="choices-single-default"
                        options={typeExportFormatOptions}
                      />
                    </Form.Group>
                    <Button variant="primary" onClick={handleExport}>
                      Exporter
                    </Button>{" "}
                    <Button variant="danger" onClick={handleClose}>
                      Annuler
                    </Button>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

TransactionList.displayName = "TransactionList";
export default TransactionList;
