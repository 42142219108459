import Swal from "sweetalert2";

export function Sweetalert(icon, title, text, footer) {
  Swal.fire({
    icon: icon,
    title: title,
    text: text,
    backdrop: `rgba(60,60,60,0.8)`,
    footer: footer,
    timer: undefined,
    allowOutsideClick: "true",
  });
}
