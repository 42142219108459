import React, { useState, memo, Fragment } from "react";

// React-bootstrap
import {
	Row,
	Col,
	Image,
	Nav,
	Tab,
	Form,
	Button,
	Table,
} from "react-bootstrap";

// Router
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Components
import Card from "../../../components/bootstrap/card";
import Counter from "../components/counter";
import ProductCard from "../components/path-card";
import ShareOffcanvas from "../../../components/partials/components/shareoffcanvas";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs, Mousewheel } from "swiper";

// Sweetalert2
import Swal from "sweetalert2";

//Img
// import img1 from "../../../assets/modules/e-commerce/images/product-detail/10.png";
// import img2 from "../../../assets/modules/e-commerce/images/product-detail/11.png";
// import img3 from "../../../assets/modules/e-commerce/images/product-detail/12.png";
import img1 from "../../../assets/images/path/image_1.jpg";
import img2 from "../../../assets/images/path/image_2.jpg";
import img3 from "../../../assets/images/path/image_3.jpg";

import img4 from "../../../assets/modules/e-commerce/images/product-detail/13.png";
import img5 from "../../../assets/modules/e-commerce/images/product-detail/14.png";
import img6 from "../../../assets/modules/e-commerce/images/user/04.png";
import img7 from "../../../assets/modules/e-commerce/images/user/01.png";
import img8 from "../../../assets/modules/e-commerce/images/01.png";
import img9 from "../../../assets/modules/e-commerce/images/02.png";
import img10 from "../../../assets/modules/e-commerce/images/03.png";
import img11 from "../../../assets/modules/e-commerce/images/04.png";
import img12 from "../../../assets/modules/e-commerce/images/05.png";
import img13 from "../../../assets/modules/e-commerce/images/06.png";

import Circularprogressbar from "../../../components/circularprogressbar";
import avatars11 from "../../../assets/images/avatars/01.png";
import avatars22 from "../../../assets/images/avatars/avtar_1.png";
import avatars33 from "../../../assets/images/avatars/avtar_2.png";
import avatars44 from "../../../assets/images/avatars/avtar_3.png";
import avatars55 from "../../../assets/images/avatars/avtar_4.png";
import avatars66 from "../../../assets/images/avatars/avtar_5.png";

import shap1 from "../../../assets/images/shapes/01.png";
import shap2 from "../../../assets/images/shapes/02.png";
import shap3 from "../../../assets/images/shapes/03.png";
import shap4 from "../../../assets/images/shapes/04.png";
import shap5 from "../../../assets/images/shapes/06.png";
import shap6 from "../../../assets/images/shapes/01.png";

const PathDetails = memo(() => {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);

	function Sweetalert() {
		Swal.fire({
			icon: "success",
			title: "Added!",
			text: "Your item has been Added to the cart.",
		});
	}

	const { t } = useTranslation();
	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<Row className="align-items-center">
								<Col lg="9">
									<div className="card-body">
										{/* <p>Creating basic google map</p> */}
										<iframe className="w-100" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3902543.2003194243!2d-118.04220880485131!3d36.56083290513502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80be29b9f4abb783%3A0x4757dc6be1305318!2sInyo%20National%20Forest!5e0!3m2!1sen!2sin!4v1576668158879!5m2!1sen!2sin" height="500" allowFullScreen="" loading="lazy"></iframe>
									</div>
								</Col>
								<Col lg="3" className="mt-4 mt-lg-0">
									<div className="d-flex align-items-center justify-content-center">
										<div className="d-flex flex-column text-center align-items-center justify-content-between ">
											<div className="fs-italic">
												<h5>
													Regina Miles
													<svg
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
														width="24" height="24"
														viewBox="0 0 24 24"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M20.3992 9.14611L21.1194 9.86611C21.6897 10.4261 21.9998 11.1861 21.9998 11.9861C22.0098 12.7861 21.6997 13.5471 21.1395 14.1161C21.1328 14.1234 21.1261 14.1299 21.1194 14.1363C21.1161 14.1396 21.1128 14.1428 21.1094 14.1461L20.3992 14.8561C20.1191 15.1361 19.959 15.5161 19.959 15.9171V16.9461C19.959 18.6061 18.6085 19.9571 16.9479 19.9571H15.9176C15.5174 19.9571 15.1373 20.1161 14.8572 20.3961L14.1369 21.1161C13.5467 21.7071 12.7764 21.9961 12.0061 21.9961C11.2359 21.9961 10.4656 21.7071 9.87537 21.1271L9.14511 20.3961C8.865 20.1161 8.48487 19.9571 8.08472 19.9571H7.05435C5.39375 19.9571 4.04326 18.6061 4.04326 16.9461V15.9171C4.04326 15.5161 3.8832 15.1361 3.6031 14.8461L2.88284 14.1361C1.71241 12.9671 1.70241 11.0561 2.87283 9.87711L3.6031 9.14611C3.8832 8.86611 4.04326 8.48611 4.04326 8.07611V7.05611C4.04326 5.39611 5.39375 4.04711 7.05435 4.04711H8.08472C8.48487 4.04711 8.865 3.88611 9.14511 3.60611L9.86537 2.88611C11.0358 1.70711 12.9465 1.70711 14.1269 2.87711L14.8572 3.60611C15.1373 3.88611 15.5174 4.04711 15.9176 4.04711H16.9479C18.6085 4.04711 19.959 5.39611 19.959 7.05611V8.08711C19.959 8.48611 20.1191 8.86611 20.3992 9.14611ZM9.42521 15.4461C9.66529 15.4461 9.88537 15.3561 10.0454 15.1861L15.1873 10.0471C15.5274 9.70711 15.5274 9.14611 15.1873 8.80611C14.8472 8.46711 14.297 8.46711 13.9569 8.80611L8.81499 13.9461C8.47486 14.2861 8.47486 14.8461 8.81499 15.1861C8.97504 15.3561 9.19512 15.4461 9.42521 15.4461ZM13.6968 14.5661C13.6968 15.0561 14.0869 15.4461 14.5771 15.4461C15.0572 15.4461 15.4474 15.0561 15.4474 14.5661C15.4474 14.0871 15.0572 13.6961 14.5771 13.6961C14.0869 13.6961 13.6968 14.0871 13.6968 14.5661ZM9.43521 8.55611C9.91539 8.55611 10.3055 8.94611 10.3055 9.42611C10.3055 9.91711 9.91539 10.3061 9.43521 10.3061C8.95504 10.3061 8.55489 9.91711 8.55489 9.42611C8.55489 8.94611 8.95504 8.55611 9.43521 8.55611Z"
															fill="green"
														></path>
													</svg>
												</h5>
												<div className="text-muted-50 mb-1">
													<small>{t("certifiedDriver")}</small>
												</div>
											</div>
											<div className="text-black-50 text-warning">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20px"
													height="20px"
													viewBox="0 0 20 20"
													fill="orange"
													className="ms-1"
												>
													<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
												</svg>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20px"
													height="20px"
													viewBox="0 0 20 20"
													fill="orange"
													className="ms-1"
												>
													<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
												</svg>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20px"
													height="20px"
													viewBox="0 0 20 20"
													fill="orange"
													className="ms-1"
												>
													<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
												</svg>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20px"
													height="20px"
													viewBox="0 0 20 20"
													fill="gary"
													className="ms-1"
												>
													<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
												</svg>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20px"
													height="20px"
													viewBox="0 0 20 20"
													fill="gary"
													className="ms-1"
												>
													<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
												</svg>
											</div>
											<div className="card-profile-progress">
												<div className="circle-progress  circle-progress-basic circle-progress-primary">
													<Circularprogressbar
														stroke="#3A57E8"
														Linecap="rounded"
														trailstroke="#ddd"
														strokewidth="4px"
														width="100"
														height="100"
														value={80}
													></Circularprogressbar>
												</div>
												<Image
													className="theme-color-default-img  img-fluid rounded-circle card-img"
													src={avatars11}
													alt="profile-pic"
												/>
												<Image
													className="theme-color-purple-img img-fluid rounded-circle card-img"
													src={avatars22}
													alt="profile-pic"
												/>
												<Image
													className="theme-color-blue-img img-fluid rounded-circle card-img"
													src={avatars33}
													alt="profile-pic"
												/>
												<Image
													className="theme-color-green-img img-fluid rounded-circle card-img"
													src={avatars55}
													alt="profile-pic"
												/>
												<Image
													className="theme-color-yellow-img img-fluid rounded-circle card-img"
													src={avatars66}
													alt="profile-pic"
												/>
												<Image
													className="theme-color-pink-img img-fluid rounded-circle card-img"
													src={avatars44}
													alt="profile-pic"
												/>
											</div>
											<div className="d-flex icon-pill">
												<Link
													to="#"
													className="btn btn-sm rounded-pill px-2 py-2 ms-2"
												>
													<svg
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
														// class="icon-32" 
														className="text-primary"
														width="20"
														height="20"
														viewBox="0 0 24 24"
													>
														<path
															fillRule="evenodd"
															clipRule="evenodd"
															d="M8.09756 12C8.09756 14.1333 9.8439 15.8691 12 15.8691C14.1463 15.8691 15.8927 14.1333 15.8927 12C15.8927 9.85697 14.1463 8.12121 12 8.12121C9.8439 8.12121 8.09756 9.85697 8.09756 12ZM17.7366 6.04606C19.4439 7.36485 20.8976 9.29455 21.9415 11.7091C22.0195 11.8933 22.0195 12.1067 21.9415 12.2812C19.8537 17.1103 16.1366 20 12 20H11.9902C7.86341 20 4.14634 17.1103 2.05854 12.2812C1.98049 12.1067 1.98049 11.8933 2.05854 11.7091C4.14634 6.88 7.86341 4 11.9902 4H12C14.0683 4 16.0293 4.71758 17.7366 6.04606ZM12.0012 14.4124C13.3378 14.4124 14.4304 13.3264 14.4304 11.9979C14.4304 10.6597 13.3378 9.57362 12.0012 9.57362C11.8841 9.57362 11.767 9.58332 11.6597 9.60272C11.6207 10.6694 10.7426 11.5227 9.65971 11.5227H9.61093C9.58166 11.6779 9.56215 11.833 9.56215 11.9979C9.56215 13.3264 10.6548 14.4124 12.0012 14.4124Z"
															fill="currentColor"
														></path>
													</svg>
												</Link>
												<Link
													to="#"
													className="btn btn-sm rounded-pill px-2 py-2  ms-2"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className="text-danger"
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="currentColor"
													>
														<path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
													</svg>
												</Link>
												<Link
													to="#"
													className="btn btn-sm rounded-pill px-2 py-2 ms-2"
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														className="text-success"
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="currentColor"
													>
														<path
															fillRule="evenodd"
															d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
															clipRule="evenodd"
														/>
													</svg>
												</Link>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</Card.Body>
					</Card>
					<Card>
						<Card.Body>
							<Tab.Container defaultActiveKey="first">
								<Nav className="tab-bottom-bordered">
									<div
										className="mb-0 nav nav-tabs"
										id="nav-tab1"
										role="tablist"
									>
										<Nav.Link
											eventKey="first"
											id="nav-description-tab"
											data-bs-toggle="tab"
											data-bs-target="#nav-description"
											type="button"
											role="tab"
											aria-controls="nav-description"
											aria-selected="true"
										>
											{t("reservations").charAt(0).toUpperCase() + t("reservations").slice(1)}(4)
										</Nav.Link>
										<Nav.Link
											id="nav-info-tab"
											eventKey="second"
											data-bs-toggle="tab"
											data-bs-target="#nav-info"
											type="button"
											role="tab"
											aria-controls="nav-info"
											aria-selected="false"
										>
											{t("informations").charAt(0).toUpperCase() + t("informations").slice(1)}
										</Nav.Link>
										<Nav.Link
											id="nav-review-tab"
											eventKey="thired"
											data-bs-toggle="tab"
											data-bs-target="#nav-review"
											type="button"
											role="tab"
											aria-controls="nav-review"
											aria-selected="false"
										>
											{t("reviews").charAt(0).toUpperCase() + t("reviews").slice(1)}(2)
										</Nav.Link>
									</div>
								</Nav>
								<Tab.Content
									className="mt-4 iq-tab-fade-up"
									id="nav-tabContent"
								>
									<Tab.Pane
										eventKey="first"
										id="nav-description"
										role="tabpanel"
										aria-labelledby="nav-description-tab"
									>
										<Col sm="12">
											<Card>
												{/* <Card.Header className="d-flex justify-content-between">
													<div className="header-title">
														<h4 className="card-title">Basic Table</h4>
													</div>
												</Card.Header> */}
												<Card.Body className="p-0">
													<div className="table-responsive mt-4">
														<table
															id="basic-table"
															className="table table-striped mb-0"
															role="grid"
														>
															<thead>
																<tr>
																	<th>{t("passenger")}</th>
																	<th>{t("averagePassengerRating")}</th>
																	<th>{t("numberOfSeats")}</th>
																	<th>{t("status")}</th>
																</tr>
															</thead>
															<tbody>
																<tr>
																	<td>
																		<div className="d-flex align-items-center">
																			<Image
																				className="rounded img-fluid avatar-40 me-3 bg-primary-subtle"
																				src={avatars22}
																				alt="profile"
																				loading="lazy"
																			/>
																			<h6>Martial Brice</h6>
																		</div>
																	</td>
																	<td>
																		<div className="text-black-50 text-warning">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="orange"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="orange"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="orange"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="gary"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="gary"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																		</div>
																	</td>
																	<td>
																		<div className="iq-media-group iq-media-group-1">
																			<div className="iq-media-1">
																				<div className="icon iq-icon-box-3 rounded-pill">
																					SP
																				</div>
																			</div>{" "}
																			<div className="iq-media-1">
																				<div className="icon iq-icon-box-3 rounded-pill">
																					SP
																				</div>
																			</div>{" "}
																			<div className="iq-media-1">
																				<div className="icon iq-icon-box-3 rounded-pill">
																					SP
																				</div>
																			</div>{" "}
																		</div>
																	</td>
																	<td>
																		<div className="text-info">Pending</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<div className="d-flex align-items-center">
																			<Image
																				className="rounded img-fluid avatar-40 me-3 bg-primary-subtle"
																				src={avatars22}
																				alt="profile"
																				loading="lazy"
																			/>
																			<h6>Dimitri</h6>
																		</div>
																	</td>
																	<td>
																		<div className="text-black-50 text-warning">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="orange"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="orange"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="orange"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="gary"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="gary"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																		</div>
																	</td>
																	<td>
																		<div className="iq-media-group iq-media-group-1">
																			<div className="iq-media-1">
																				<div className="icon iq-icon-box-3 rounded-pill">
																					SP
																				</div>
																			</div>{" "}
																			<div className="iq-media-1">
																				<div className="icon iq-icon-box-3 rounded-pill">
																					SP
																				</div>
																			</div>{" "}
																		</div>
																	</td>
																	<td>
																		<div className="text-info">Pending</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<div className="d-flex align-items-center">
																			<Image
																				className="rounded img-fluid avatar-40 me-3 bg-primary-subtle"
																				src={avatars22}
																				alt="profile"
																				loading="lazy"
																			/>
																			<h6>Alain</h6>
																		</div>
																	</td>
																	<td>
																		<div className="text-black-50 text-warning">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="orange"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="orange"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="gray"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="gary"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="gray"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																		</div>
																	</td>
																	<td>
																		<div className="iq-media-group iq-media-group-1">
																			<div className="iq-media-1">
																				<div className="icon iq-icon-box-3 rounded-pill">
																					SP
																				</div>
																			</div>{" "}
																			<div className="iq-media-1">
																				<div className="icon iq-icon-box-3 rounded-pill">
																					SP
																				</div>
																			</div>{" "}
																			<div className="iq-media-1">
																				<div className="icon iq-icon-box-3 rounded-pill">
																					SP
																				</div>
																			</div>{" "}
																		</div>
																	</td>
																	<td>
																		<div className="text-info">Pending</div>
																	</td>
																</tr>
																<tr>
																	<td>
																		<div className="d-flex align-items-center">
																			<Image
																				className="rounded img-fluid avatar-40 me-3 bg-primary-subtle"
																				src={avatars22}
																				alt="profile"
																				loading="lazy"
																			/>
																			<h6>Darlin</h6>
																		</div>
																	</td>
																	<td>
																		<div className="text-black-50 text-warning">
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="orange"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="orange"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="orange"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="gary"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																			<svg
																				xmlns="http://www.w3.org/2000/svg"
																				width="20px"
																				height="20px"
																				viewBox="0 0 20 20"
																				fill="gary"
																				className="ms-1"
																			>
																				<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																			</svg>
																		</div>
																	</td>
																	<td>
																		<div className="iq-media-group iq-media-group-1">
																			<div className="iq-media-1">
																				<div className="icon iq-icon-box-3 rounded-pill">
																					SP
																				</div>
																			</div>{" "}
																			<div className="iq-media-1">
																				<div className="icon iq-icon-box-3 rounded-pill">
																					SP
																				</div>
																			</div>{" "}
																			<div className="iq-media-1">
																				<div className="icon iq-icon-box-3 rounded-pill">
																					SP
																				</div>
																			</div>{" "}
																		</div>
																	</td>
																	<td>
																		<div className="text-info">Pending</div>
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</Card.Body>
											</Card>
										</Col>
									</Tab.Pane>
									<Tab.Pane
										eventKey="second"
										id="nav-info"
										role="tabpanel"
										aria-labelledby="nav-info-tab"
									>
										<Table bordered className="mb-0" responsive>
											<tbody>
												<tr>
													<th>{t("start")}</th>
													<td>Wood Buffalo, Alberta, Canada</td>
												</tr>
												<tr>
													<th>{t("arrival")}</th>
													<td>Opportunity No 17, Alberta, Canada</td>
												</tr>
												<tr>
													<th>{t("departureDate")}</th>
													<td>Lundi 30 Janvier 2025 à 12h00</td>
												</tr>
												<tr>
													<th>{t("price")}</th>
													<td>$5 CAD</td>
												</tr>
												<tr>
													<th>{t("numberOfSeats")}</th>
													<td>4</td>
												</tr>
												<tr>
													<th>{t("acceptsAnimals")}</th>
													<td>No</td>
												</tr>
												<tr>
													<th>{t("acceptsBaggage")}</th>
													<td>Yes</td>
												</tr>
												<tr>
													<th>{t("acceptsParcels")}</th>
													<td>Yes</td>
												</tr>
											</tbody>
										</Table>
									</Tab.Pane>
									<Tab.Pane
										eventKey="thired"
										id="nav-review"
										role="tabpanel"
										aria-labelledby="nav-review-tab"
									>
										<h4>{t("reviews").charAt(0).toUpperCase() + t("reviews").slice(1)}</h4>
										<div>
											<Card className="shadow-none bg-transparent border">
												<Card.Body>
													<div className="d-flex flex-sm-nowrap flex-wrap">
														<div>
															<Image
																className="img-fluid object-contain avatar-120 rounded"
																src={img6}
																alt="01"
																loading="lazy"
															/>
														</div>
														<div className="ms-0 ms-sm-3">
															<div className="d-flex justify-content-between align-items-center my-2 my-lg-0">
																<h6 className="mb-0">Emma Jones</h6>
															</div>
															<small className="text-primary">
																March 01st 2021
															</small>
															<p className="mt-2 mb-0">
																Lorem ipsum dolor sit amet, consectetur
																adipiscing elit. Aliquam ut eu morbi tincidunt
																erat egestas quisque ultrices ut. Vel elementum
																blandit et tellus sit tincidunt.
															</p>
															<div className="text-black-50 text-warning">
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20px"
																	height="20px"
																	viewBox="0 0 20 20"
																	fill="orange"
																	className="ms-1"
																>
																	<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																</svg>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20px"
																	height="20px"
																	viewBox="0 0 20 20"
																	fill="orange"
																	className="ms-1"
																>
																	<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																</svg>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20px"
																	height="20px"
																	viewBox="0 0 20 20"
																	fill="orange"
																	className="ms-1"
																>
																	<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																</svg>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20px"
																	height="20px"
																	viewBox="0 0 20 20"
																	fill="gary"
																	className="ms-1"
																>
																	<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																</svg>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20px"
																	height="20px"
																	viewBox="0 0 20 20"
																	fill="gary"
																	className="ms-1"
																>
																	<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																</svg>
															</div>
														</div>
													</div>
												</Card.Body>
											</Card>
											<Card className="shadow-none bg-transparent border">
												<Card.Body>
													<div className="d-flex flex-sm-nowrap flex-wrap">
														<div>
															<Image
																className="img-fluid object-contain avatar-120 rounded"
																src={img7}
																alt="01"
																loading="lazy"
															/>
														</div>
														<div className="ms-0 ms-sm-3">
															<div className="d-flex justify-content-between align-items-center my-2 my-lg-0">
																<h6 className="mb-0">Jackson Jones</h6>
															</div>
															<small className="text-primary">
																March 20th 2021
															</small>
															<p className="mt-3">
																Lorem ipsum dolor sit amet, consectetur
																adipiscing elit. Aliquam ut eu morbi tincidunt
																erat egestas quisque ultrices ut. Vel elementum
																blandit et tellus sit tincidunt.
															</p>
															<div className="text-black-50 text-warning">
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20px"
																	height="20px"
																	viewBox="0 0 20 20"
																	fill="orange"
																	className="ms-1"
																>
																	<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																</svg>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20px"
																	height="20px"
																	viewBox="0 0 20 20"
																	fill="orange"
																	className="ms-1"
																>
																	<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																</svg>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20px"
																	height="20px"
																	viewBox="0 0 20 20"
																	fill="orange"
																	className="ms-1"
																>
																	<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																</svg>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20px"
																	height="20px"
																	viewBox="0 0 20 20"
																	fill="orange"
																	className="ms-1"
																>
																	<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																</svg>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	width="20px"
																	height="20px"
																	viewBox="0 0 20 20"
																	fill="gary"
																	className="ms-1"
																>
																	<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
																</svg>
															</div>
														</div>
													</div>
												</Card.Body>
											</Card>
										</div>
										{/* <p className="my-4">- There are no reviews yet</p>
										<h4 className="mb-3">Be the first to Review</h4> */}
									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</Card.Body>
					</Card>
				</Col>
				<Col lg="12" md="12">
					<Row className="row-cols-1">
						<div className="d-flex py-4">
							<h4>{t("similarTrips")}</h4>
						</div>
						<div
							className="overflow-hidden slider-circle-btn"
							id="ecommerce-slider"
						>
							<Swiper
								className="p-0 m-0 mb-4 swiper-wrapper list-inline"
								slidesPerView={3}
								spaceBetween={32}
								modules={[Navigation]}
								navigation={{
									nextEl: "#swiper-button-next1",
									prevEl: "#swiper-button-prev1",
								}}
								breakpoints={{
									320: { slidesPerView: 1 },
									550: { slidesPerView: 2 },
									991: { slidesPerView: 3 },
									1400: { slidesPerView: 3 },
									1500: { slidesPerView: 3 },
									1920: { slidesPerView: 4 },
									2040: { slidesPerView: 4 },
									2440: { slidesPerView: 4 },
								}}
							>
								<SwiperSlide>
									<ProductCard
										productName="Casual Shoes"
										productImage={img1}
										productRating="3.5"
										productPrice="$56.00"
										statusDetails={t("complete")}
										statusColor="success"
									/>
								</SwiperSlide>
								<SwiperSlide>
									<ProductCard
										productName="Biker's Jacket"
										productImage={img2}
										productRating="3.5"
										productPrice="$56.00"
										statusDetails={t("cancel")}
										statusColor="danger"
									/>
								</SwiperSlide>
								<SwiperSlide>
									<ProductCard
										productName="Knitted Shrug"
										productImage={img3}
										productRating="3.5"
										productPrice="$56.00"
										statusDetails={t("pending")}
										statusColor="warning"
									/>
								</SwiperSlide>
								<SwiperSlide>
									<ProductCard
										productName="Blue Handbag"
										productImage={img2}
										productRating="3.5"
										productPrice="$56.00"
										statusDetails={t("cancel")}
										statusColor="danger"
									/>
								</SwiperSlide>
								<SwiperSlide>
									<ProductCard
										productName="Pink Sweater"
										productImage={img1}
										productRating="3.5"
										statusDetails={t("pending")}
										statusColor="warning"
										productPrice="$56.00"
									/>
								</SwiperSlide>
								<SwiperSlide>
									<ProductCard
										productName="Pink Handbag"
										productImage={img3}
										productRating="3.5"
										productPrice="$56.00"
										statusDetails={t("complete")}
										statusColor="success"
									/>
								</SwiperSlide>
								<SwiperSlide>
									<ProductCard
										productName="Casual Shoes"
										productImage={img1}
										productRating="3.5"
										productPrice="$56.00"
										statusDetails={t("cancel")}
										statusColor="danger"
									/>
								</SwiperSlide>
							</Swiper>
							<div
								id="swiper-button-next1"
								className="swiper-button swiper-button-next"
							></div>
							<div
								id="swiper-button-prev1"
								className="swiper-button swiper-button-prev"
							></div>
						</div>
					</Row>
				</Col>
			</Row>
		</Fragment>
	);
});
PathDetails.displayName = "PathDetails";
export default PathDetails;
