import React, { memo, Fragment, useRef, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Dropdown, Modal } from "react-bootstrap";

//components
import Card from "../../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../../components/hooks/useDatatable";

import $ from "jquery";
// img
import shap1 from "../../../../assets/images/shapes/01.png";
import shap2 from "../../../../assets/images/shapes/02.png";
import shap3 from "../../../../assets/images/shapes/03.png";
import shap4 from "../../../../assets/images/shapes/04.png";
import shap5 from "../../../../assets/images/shapes/05.png";
import shap6 from "../../../../assets/images/shapes/06.png";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import ActivityIndicator from "../../../../components/partials/dashboard/mainstyle/activity-indicator";

import { Sweetalert } from "../../../../components/partials/dashboard/mainstyle/sweet-alert";

const CarpoolRequestLlist = memo(() => {
  const { t } = useTranslation();
  let history = useNavigate();
  const [tableData, setTableData] = useState([
    {
      reservation_id: "RES123456",
      type: "Covoiturage",
      user: {
        user_id: "USR789",
        name: "Jean Dupont",
      },
      status: "En attente",
      pickup_location: "Paris, 75001",
      dropoff_location: "Lyon, 69001",
      pickup_time: "2023-10-25T08:00:00Z",
      dropoff_time: "2023-10-25T12:00:00Z",
      driver: {
        driver_id: "DRV321",
        name: "Marie Martin",
      },
      price: 25.5,
      payment_status: "Payé",
    },

    {
      reservation_id: "RES345678",
      type: "Covoiturage",
      user: {
        user_id: "USR456",
        name: "Paul Leroy",
      },
      status: "Terminée",
      pickup_location: "Lille, 59000",
      dropoff_location: "Bruxelles, 1000",
      pickup_time: "2023-10-24T07:00:00Z",
      dropoff_time: "2023-10-24T10:00:00Z",
      driver: {
        driver_id: "DRV654",
        name: "Lucie Dubois",
      },
      price: 30.0,
      payment_status: "Payé",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //footer: '<a href="">Voir les détails de l\'utilisateur?</a>',

  const typeExportFormatOptions = [
    { value: "pdf", label: "PDF" },
    { value: "csv", label: "CSV" },
    { value: "xls", label: "XLS" },
  ];

  const stateOfMessageOptions = [];
  const passengerOptions = [];
  const driverOptions = [];

  const columns = useRef([
    { data: "reservation_id", title: "ID Réservation" },
    { data: "type", title: "Type" },
    {
      data: null,
      title: "Expéditeur",
      render: function (row) {
        return `
          <span>${row.user.name}</span>`;
      },
    },
    {
      data: null,
      title: "Statut",
      render: function (row) {
        let color = "";
        switch (row.status) {
          case "Terminée":
            color = "success";
            break;
          default:
            color = "warning";
        }
        return `
          <span class="badge bg-${color}">${row.status}</span>`;
      },
    },
    {
      data: null,
      title: "Lieux",
      render: function (row) {
        return `
          <span>${row.pickup_location + " ->  " + row.dropoff_location}</span>`;
      },
    },

    {
      data: null,
      title: "Conducteur",
      render: function (row) {
        return `
          <span>${row.driver.name}</span>`;
      },
    },

    { data: "price", title: "Prix" },

    {
      data: null,
      orderable: false,
      searchable: false,
      title: "Action",
      render: function (row) {
        let toggleActivationButton = "";
        if (row.status === "Annulée") {
          toggleActivationButton = `
            <a title="Réactiver" id="action-row-${row.reservation_id}-method-disable" class="btn btn-sm btn-icon btn-success" data-bs-toggle="tooltip" data-bs-placement="top" href="#" aria-label="Réactiver" data-bs-original-title="Réactiver">
              <span class="btn-inner">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" width="24" height="24" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M7.67 2H16.34C19.73 2 22 4.38 22 7.92V16.091C22 19.62 19.73 22 16.34 22H7.67C4.28 22 2 19.62 2 16.091V7.92C2 4.38 4.28 2 7.67 2ZM11.43 14.99L16.18 10.24C16.52 9.9 16.52 9.35 16.18 9C15.84 8.66 15.28 8.66 14.94 9L10.81 13.13L9.06 11.38C8.72 11.04 8.16 11.04 7.82 11.38C7.48 11.72 7.48 12.27 7.82 12.62L10.2 14.99C10.37 15.16 10.59 15.24 10.81 15.24C11.04 15.24 11.26 15.16 11.43 14.99Z" fill="currentColor"></path></svg>
              </span>
            </a>
          `;
        } else {
          toggleActivationButton = `
            <a title="Annuler" id="action-row-${row.reservation_id}-method-disable" class="btn btn-sm btn-icon btn-danger" data-bs-toggle="tooltip" data-bs-placement="top" href="#" aria-label="Annuler" data-bs-original-title="Annuler">
              <span class="btn-inner">
                <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" width="24" height="24" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M7.67 1.99927H16.34C19.73 1.99927 22 4.37927 22 7.91927V16.0903C22 19.6203 19.73 21.9993 16.34 21.9993H7.67C4.28 21.9993 2 19.6203 2 16.0903V7.91927C2 4.37927 4.28 1.99927 7.67 1.99927ZM15.01 14.9993C15.35 14.6603 15.35 14.1103 15.01 13.7703L13.23 11.9903L15.01 10.2093C15.35 9.87027 15.35 9.31027 15.01 8.97027C14.67 8.62927 14.12 8.62927 13.77 8.97027L12 10.7493L10.22 8.97027C9.87 8.62927 9.32 8.62927 8.98 8.97027C8.64 9.31027 8.64 9.87027 8.98 10.2093L10.76 11.9903L8.98 13.7603C8.64 14.1103 8.64 14.6603 8.98 14.9993C9.15 15.1693 9.38 15.2603 9.6 15.2603C9.83 15.2603 10.05 15.1693 10.22 14.9993L12 13.2303L13.78 14.9993C13.95 15.1803 14.17 15.2603 14.39 15.2603C14.62 15.2603 14.84 15.1693 15.01 14.9993Z" fill="currentColor"></path></svg>
              </span>
            </a>
          `;
        }
        return `
          <div class="d-flex align-items-center list-user-action justify-content-between">
            <div>  
              <a title="Détails" id="action-row-${row.reservation_id}-method-details" class="btn btn-sm btn-icon btn-info" data-bs-toggle="tooltip" data-bs-placement="top" href="#" aria-label="Add" data-bs-original-title="Add">
                <span class="btn-inner">
                  <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>                                         
                </span>
              </a>
            </div> 
            <div>
              ${toggleActivationButton} 
            </div> 
          </div>`;
      },
    },
  ]);

  const listtableRef = useRef(null);

  const handleSearchClick = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLoadingText("Recherche des utilisateurs en cours ...");

    //Call API here
    setTimeout(() => {
      // Code à exécuter après le délai
      setIsLoading(false);
      setLoadingText("");
      setTableData([]);
    }, 5000);
  };

  const handleExportUser = (event) => {
    event.preventDefault();
    handleClose();
    setIsLoading(true);
    setLoadingText("Exportation des utilisateurs en cours...");

    setTimeout(() => {
      // Code à exécuter après le délai
      setIsLoading(false);
      Sweetalert("success", "Succès ...", "Utilisateurs exportés!", "");
    }, 5000);
  };

  // Fonction pour afficher les détails d'un utilisateur
  const handleDetails = (row) => {
    history("/dashboard/reservation/carpool/request-details", { state: row });
  };

  // Function for disable and enable a user
  const handleDisable = (row) => {
    setIsLoading(true);

    if (row.status === "Activé") {
      setLoadingText(
        "Désactivation de l'utilisateur " + row.name + " en cours ..."
      );
    } else {
      setLoadingText(
        "Activation de l'utilisateur " + row.name + " en cours ..."
      );
    }

    //Call API here
    setTimeout(() => {
      const newTableData = tableData.map((user) => {
        if (user.id === row.id) {
          if (user.status === "Activé") {
            user.status = "Désactivé";
            user.color = "danger";
          } else {
            user.status = "Activé";
            user.color = "success";
          }
        }
        return user;
      });

      setIsLoading(false);
      setLoadingText("");

      setTableData(newTableData);
      if (row.status === "Activé") {
        Sweetalert("success", "Succès ...", "Utilisateur activé!", "");
      } else {
        Sweetalert("success", "Succès ...", "Utilisateur désactivé!", "");
      }
    }, 5000);
  };

  // Function for modify a user
  const handleModify = (row) => {
    history("/dashboard/app/user-edit", { state: row });
  };

  // Function for user verification
  const handleVerify = (row) => {
    history("/file-manager/user-file", { state: row });
  };

  // Function for deleting a user
  const handleDelete = (row) => {
    setIsLoading(true);
    setLoadingText(
      "Suppression de l'utilisateur " + row.name + " en cours ..."
    );

    //Call API here
    setTimeout(() => {
      const filteredTableData = tableData.filter((user) => user.id !== row.id);

      setIsLoading(false);
      setLoadingText("");

      setTableData(filteredTableData);
      Sweetalert("success", "Succès ...", "Utilisateur supprimé!", "");
    }, 5000);
  };

  // Function for resetting password of a user
  const handleResetPassWord = (row) => {
    setIsLoading(true);
    setLoadingText(
      "Réinitialisation du mot de passe de l'utilisateur " +
        row.name +
        " en cours ..."
    );

    //Call API here
    setTimeout(() => {
      setIsLoading(false);
      setLoadingText("");

      Sweetalert("success", "Succès ...", "Mot de passe réinitialisé!", "");
    }, 5000);
  };

  useDataTable({
    tableRef: listtableRef,
    columns: columns.current,
    data: tableData,
    actionCallback: ({ id, method }) => {
      const row = tableData.find(
        (item) => item.reservation_id.toString() === id
      ); // Trouver la ligne correspondante
      if (!row) return;

      // Appeler la fonction correspondante en fonction de la méthode
      switch (method) {
        case "details":
          handleDetails(row);
          break;
        case "disable":
          handleDisable(row);
          break;
        case "modify":
          handleModify(row);
          break;
        case "verify":
          handleVerify(row);
          break;
        case "delete":
          handleDelete(row);
          break;
        case "reset":
          handleResetPassWord(row);
          break;
        default:
          console.warn("Méthode non reconnue:", method);
      }
    },
  });

  return (
    <Fragment>
      <Row>
        {isLoading && (
          <ActivityIndicator
            title={"Liste des demandes de réservation covoiturage"}
            text={loadingText}
          />
        )}
        <Col style={{ display: isLoading ? "none" : "block" }} sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">
                  Liste des demandes de réservation covoiturage
                </h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <Form className="p-4" onSubmit={handleSearchClick}>
                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="startDate">
                      {t("startDate")}
                    </Form.Label>
                    <Form.Control type="date" id="startDate" />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="endDate">{t("endDate")}</Form.Label>
                    <Form.Control type="date" id="endDate" />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="stateOfMessage">Status</Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="stateOfMessage"
                      options={stateOfMessageOptions}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="passenger">Passager</Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="passenger"
                      options={passengerOptions}
                    />
                  </Col>

                  <Col className="mb-3">
                    <Form.Label htmlFor="driver">Conducteur</Form.Label>
                    <Select
                      data-trigger
                      name="choices-single-default"
                      id="driver"
                      options={driverOptions}
                    />
                  </Col>

                  <Col className="mb-3">
                    <Form.Label htmlFor="path_id">ID du trajet</Form.Label>
                    <Form.Control type="text" id="path_id" />
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="departure_date">
                      Date de départ
                    </Form.Label>
                    <Form.Control type="date" id="departure_date" />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="departure_time">
                      Heure de départ
                    </Form.Label>
                    <Form.Control type="time" id="departure_time" />
                  </Col>

                  <Col className="mb-3">
                    <Form.Label htmlFor="price">Prix</Form.Label>
                    <Form.Control type="number" id="price" />
                  </Col>
                </Row>

                <Row>
                  <Col className="mb-3">
                    <Form.Label htmlFor="reservation_id">
                      ID Réservation
                    </Form.Label>
                    <Form.Control type="text" id="reservation_id" />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="departure_point">
                      Point de départ
                    </Form.Label>
                    <Form.Control type="text" id="departure_point" />
                  </Col>
                  <Col className="mb-3">
                    <Form.Label htmlFor="stopover_point">
                      Point d' escale
                    </Form.Label>
                    <Form.Control type="text" id="stopover_point" />
                  </Col>

                  <Col className="mb-3">
                    <Form.Label htmlFor="arrival_point">
                      Point d'arrivée
                    </Form.Label>
                    <Form.Control type="text" id="arrival_point" />
                  </Col>
                </Row>

                <Row className="my-3">
                  <Col className="text-center">
                    <Button
                      variant="btn btn-success"
                      type="submit"
                      className="mt-5"
                    >
                      <span>{t("search")} </span>
                      <svg
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon-24"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          cx="11.7669"
                          cy="11.7666"
                          r="8.98856"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></circle>
                        <path
                          d="M18.0186 18.4851L21.5426 22"
                          stroke="currentColor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </Button>
                  </Col>
                </Row>
              </Form>
              <div className="d-flex align-items-center gap-3 my-5 justify-content-end">
                <Button
                  variant="text-center btn btn-primary d-flex gap-2"
                  onClick={handleShow}
                >
                  <svg
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z"
                      fill="currentColor"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.07996 6.6499V6.6599C7.64896 6.6599 7.29996 7.0099 7.29996 7.4399C7.29996 7.8699 7.64896 8.2199 8.07996 8.2199H11.069C11.5 8.2199 11.85 7.8699 11.85 7.4289C11.85 6.9999 11.5 6.6499 11.069 6.6499H8.07996ZM15.92 12.7399H8.07996C7.64896 12.7399 7.29996 12.3899 7.29996 11.9599C7.29996 11.5299 7.64896 11.1789 8.07996 11.1789H15.92C16.35 11.1789 16.7 11.5299 16.7 11.9599C16.7 12.3899 16.35 12.7399 15.92 12.7399ZM15.92 17.3099H8.07996C7.77996 17.3499 7.48996 17.1999 7.32996 16.9499C7.16996 16.6899 7.16996 16.3599 7.32996 16.1099C7.48996 15.8499 7.77996 15.7099 8.07996 15.7399H15.92C16.319 15.7799 16.62 16.1199 16.62 16.5299C16.62 16.9289 16.319 17.2699 15.92 17.3099Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span>Exporter</span>
                </Button>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Exporter des utilisateurs</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label htmlFor="typeOfUser">
                        Format de fichier
                      </Form.Label>
                      <Select
                        data-trigger
                        name="choices-single-default"
                        id="typeOfExportFormat"
                        options={typeExportFormatOptions}
                      />
                    </Form.Group>
                    <Button variant="primary" onClick={handleExportUser}>
                      Exporter
                    </Button>{" "}
                    <Button variant="danger" onClick={handleClose}>
                      Cancel
                    </Button>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="table-responsive">
                <table
                  id="datatable-ecom"
                  ref={listtableRef}
                  className="data-tables table custom-table movie_table"
                ></table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

CarpoolRequestLlist.displayName = "CarpoolRequestLlist";
export default CarpoolRequestLlist;
