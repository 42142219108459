import React, { memo, Fragment, useState } from "react";

//react-bootstrap
import { Row, Col, Image, Form, Button } from "react-bootstrap";

//components
import Card from "../../../../components/bootstrap/card";

//router
import { Link, useLocation } from "react-router-dom";

// img
import avatars1 from "../../../../assets/images/avatars/01.png";
import avatars2 from "../../../../assets/images/avatars/avtar_1.png";
import avatars3 from "../../../../assets/images/avatars/avtar_2.png";
import avatars4 from "../../../../assets/images/avatars/avtar_3.png";
import avatars5 from "../../../../assets/images/avatars/avtar_4.png";
import avatars6 from "../../../../assets/images/avatars/avtar_5.png";

import { useTranslation } from "react-i18next";

import { Sweetalert } from "../../../../components/partials/dashboard/mainstyle/sweet-alert";
import ActivityIndicator from "../../../../components/partials/dashboard/mainstyle/activity-indicator";

const ParcelRequestEdit = memo((props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { email, date, contact, role } = location.state || {};

  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const typeUserOptions = [
    "administrateur",
    "support client",
    "conducteur",
    "passager",
    "expéditeur",
    "destinataire",
  ];

  const handleSubmitModifyUser = (event) => {
    setIsLoading(true);
    setLoadingText("Utilisateur en cours de modification...");

    setTimeout(() => {
      // Code à exécuter après le délai
      setIsLoading(false);
      setValidated(false);
      setLoadingText("");
      Sweetalert("success", "Succès ...", "Utilisateur modifié!");
    }, 5000);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    // Call API here
    setValidated(true);
  };
  return (
    <Fragment>
      <Row>
        {isLoading ? (
          <ActivityIndicator title={t("modifyuser")} text={loadingText} />
        ) : (
          <Col>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmitModifyUser}
              className="row g-3 needs-validation"
            >
              <Col xl="3" lg="4" className="">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Modify Parcel</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div>
                      <Form.Group className="form-group">
                        <div className="profile-img-edit position-relative">
                          <Image
                            className="theme-color-default-img  profile-pic rounded avatar-100"
                            src={avatars1}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-purple-img profile-pic rounded avatar-100"
                            src={avatars2}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-blue-img profile-pic rounded avatar-100"
                            src={avatars3}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-green-img profile-pic rounded avatar-100"
                            src={avatars5}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-yellow-img profile-pic rounded avatar-100"
                            src={avatars6}
                            alt="profile-pic"
                          />
                          <Image
                            className="theme-color-pink-img profile-pic rounded avatar-100"
                            src={avatars4}
                            alt="profile-pic"
                          />
                          <div className="upload-icone bg-primary">
                            <svg
                              className="upload-button icon-14"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#ffffff"
                                d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                              />
                            </svg>
                            <Form.Control
                              disabled
                              className="file-upload"
                              type="file"
                              accept="image/*"
                            />
                          </div>
                        </div>
                        <div className="img-extension mt-3">
                          <div className="d-inline-block align-items-center">
                            <span>Only</span> <Link to="#">.jpg</Link>{" "}
                            <Link to="#">.png</Link> <Link to="#">.jpeg</Link>{" "}
                            <span>allowed</span>
                          </div>
                        </div>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Form.Label>User Role:</Form.Label>
                        <select
                          name="type"
                          className="selectpicker form-control"
                          data-style="py-0"
                          required
                        >
                          {typeUserOptions.map((currentRole) => (
                            <option
                              key={currentRole}
                              value={currentRole}
                              selected={currentRole === role}
                            >
                              {currentRole}
                            </option>
                          ))}
                        </select>
                        <Form.Control.Feedback type="invalid">
                          Please choose a role
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col xl="9" lg="8">
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Modify Parcel Information</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="new-user-info">
                      <div>
                        <div className="row g-3 needs-validation">
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="recipient_name">
                              Recipient name
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="recipient_name"
                              placeholder="Enter the recipient name"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the recipient name
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="recipient_addres">
                              Recipient addres
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="recipient_addres"
                              placeholder="Enter the recipient name"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the recipient name
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="phonenumber">
                              {t("phonenumber")}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="phonenumber"
                              placeholder="Enter the contact"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the contact
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group className="col-md-6 form-group">
                            <Form.Label htmlFor="parcel_weight">
                              Parcel weight (Kg)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              id="parcel_weight"
                              placeholder="Enter the parcel weight"
                              required
                            />
                            <Form.Control.Feedback type="invalid">
                              Please provide the parcel weight
                            </Form.Control.Feedback>
                          </Form.Group>
                        </div>
                        <hr />

                        <Button type="submit" variant="btn btn-primary">
                          Modify Parcel
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Form>
          </Col>
        )}
      </Row>
    </Fragment>
  );
});

ParcelRequestEdit.displayName = "ParcelRequestEdit";
export default ParcelRequestEdit;
