// store/user/slice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axiosConfig";
import { API_ENDPOINTS } from "../../apiEndpoints";

import Swal from "sweetalert2";
import { initialUserState } from "../../../store/setting/state";

import _ from "lodash";
import { updateStorage, getStorage } from "../../../utilities/setting";

const createUserObj = (state) => {
  return {
    saveLocal: state.saveLocal,
    storeKey: state.storeKey,
    user: _.cloneDeep(state.user),
  };
};

// Définir l'action asynchrone loginUser
export const loginUser = createAsyncThunk(
  "user/loginUser", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        API_ENDPOINTS.LOGIN,
        credentials
      ); // Appel API
      return response.data; // Retourne les données en cas de succès
    } catch (error) {
      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone logoutUser
export const logoutUser = createAsyncThunk(
  "user/logoutUser", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (token, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(API_ENDPOINTS.LOG_OUT, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Appel API
      console.log("Response");
      console.log(response);
      return response.data; // Retourne les données en cas de succès
    } catch (error) {
      console.log("error");
      console.log(error);
      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone getAllUsers
export const getAllUsers = createAsyncThunk(
  "user/getAllUsers", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (token, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_USERS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Appel API
      console.log(
        "********************************  get All users ************************ "
      );
      console.log(response.data);
      return response.data; // Retourne les données en cas de succès
    } catch (error) {
      console.log(
        "********************************  get All users error************************ "
      );
      console.log(error.response.data);
      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone searchUsers
export const searchUsers = createAsyncThunk(
  "user/searchUsers", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    console.log("----------- Call endpoint before try");
    console.log(API_ENDPOINTS.SEARCH_USERS);
    try {
      console.log("----------- Call endpoint");
      console.log(API_ENDPOINTS.SEARCH_USERS);
      const response = await axiosInstance.get(API_ENDPOINTS.SEARCH_USERS, {
        params: data.parameters,
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }); // Appel API

      console.log(
        "********************************  search users ************************ "
      );
      console.log(response.data);
      return response.data; // Retourne les données en cas de succès
    } catch (error) {
      console.log(
        "********************************  search users error************************ "
      );
      console.log(error);
      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone getUserVerificationRequest
export const getUserVerificationRequest = createAsyncThunk(
  "user/getUserVerificationRequest", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${API_ENDPOINTS.GET_USER_VERIFICATION_REQUEST}/${data.parameters.idUser}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API

      console.log(
        "********************************  GET_USER_VERIFICATION_REQUEST ************************ "
      );
      console.log(response.data);
      return response.data; // Retourne les données en cas de succès
    } catch (error) {
      console.log(
        "******************************** GET_USER_VERIFICATION_REQUEST ************************ "
      );
      console.log(error);
      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone certifyUserAsConfirmedDriver
export const certifyUserAsConfirmedDriver = createAsyncThunk(
  "user/certifyUserAsConfirmedDriver", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${API_ENDPOINTS.CERTIFY_USER_AS_CONFIRMED_DRIVER}/${data.idUser}`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API

      console.log(
        "********************************  GET_USER_VERIFICATION_REQUEST ************************ "
      );
      console.log(response.data);
      return { id: data.idUser }; // Retourne les données en cas de succès
    } catch (error) {
      console.log(
        "******************************** GET_USER_VERIFICATION_REQUEST ************************ "
      );
      console.log(error);
      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone rejectUserAsConfirmedDriver
export const rejectUserAsConfirmedDriver = createAsyncThunk(
  "user/rejectUserAsConfirmedDriver", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${API_ENDPOINTS.REJECT_USER_AS_CONFIRMED_DRIVER}/${data.idUser}`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API

      console.log(
        "********************************  GET_USER_VERIFICATION_REQUEST ************************ "
      );
      console.log(response.data);
      return { id: data.idUser }; // Retourne les données en cas de succès
    } catch (error) {
      console.log(
        "******************************** GET_USER_VERIFICATION_REQUEST ************************ "
      );
      console.log(error);
      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone getAllAdmins
export const getAllAdmins = createAsyncThunk(
  "user/getAllAdmins", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (token, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_ADMINS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Appel API
      console.log(
        "********************************  get All users ************************ "
      );
      console.log(response.data);
      return response.data; // Retourne les données en cas de succès
    } catch (error) {
      console.log(
        "********************************  get All users error************************ "
      );
      console.log(error.response.data);
      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone addRole
export const addRole = createAsyncThunk(
  "user/addRole", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      console.log("Data to send");
      console.log(data);
      const response = await axiosInstance.post(
        `${API_ENDPOINTS.ADD_ROLE}`,
        data.data,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API
      console.log("Ok");
      console.log(response);
      return response.data; // Retourne les données en cas de succès
    } catch (error) {
      console.log("No");
      console.log(error);

      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone addRole
export const updateRole = createAsyncThunk(
  "user/updateRole", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      console.log("Data to send");
      console.log(data);
      const response = await axiosInstance.put(
        API_ENDPOINTS.UPDATE_ROLE.replace("{roleName}", data.data.roleName),
        data.data,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API
      console.log("Ok");
      console.log(response);
      return {
        name: data.data.roleName,
        permissions: response.data.permissions,
      }; // Retourne les données en cas de succès
    } catch (error) {
      console.log("No");
      console.log(error);

      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone getAllRoles
export const getAllRoles = createAsyncThunk(
  "user/getAllRoles", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (token, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_ROLES, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Appel API
      console.log(
        "********************************  get All roles ************************ "
      );
      console.log(response.data);
      return response.data; // Retourne les données en cas de succès
    } catch (error) {
      console.log(
        "********************************  get All roles error************************ "
      );
      console.log(error.response);
      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone deleteRole
export const deleteRole = createAsyncThunk(
  "user/deleteRole", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        API_ENDPOINTS.DELETE_ROLE.replace("{roleName}", data.roleName),
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API
      console.log(
        "********************************  delete role response ************************ "
      );
      console.log(response.data);
      return { roleName: data.roleName }; // Retourne les données en cas de succès
    } catch (error) {
      console.log(
        "********************************  delete role response error************************ "
      );
      console.log(error.response);
      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone getAllRoles
export const getAllPermissions = createAsyncThunk(
  "user/getAllPermissions", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (token, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(API_ENDPOINTS.GET_PERMISSIONS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Appel API
      console.log(
        "********************************  get All permissions ************************ "
      );
      console.log(response.data);
      return response.data; // Retourne les données en cas de succès
    } catch (error) {
      console.log(
        "********************************  get All users permissions************************ "
      );
      console.log(error.response);
      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone addUser
export const addUser = createAsyncThunk(
  "user/addUSer", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      console.log("Data to send");
      console.log(data);
      const response = await axiosInstance.post(
        `${API_ENDPOINTS.ADD_USER}`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API
      console.log("Ok");
      console.log(response);
      return response.data; // Retourne les données en cas de succès
    } catch (error) {
      console.log("No");
      console.log(error);

      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone updateUser
export const updateUser = createAsyncThunk(
  "user/updateUser", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      console.log("Data to send");
      console.log(data);
      const response = await axiosInstance.put(
        `${API_ENDPOINTS.UPDATE_USER}/${data.userId}`,
        data.bodyData,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API
      console.log("Ok");
      console.log(response);
      return response.data; // Retourne les données en cas de succès
    } catch (error) {
      console.log("No");
      console.log(error);

      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone updateAdmin
export const updateAdmin = createAsyncThunk(
  "user/updateAdmin", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      console.log("Data to send");
      console.log(data);
      const response = await axiosInstance.put(
        `${API_ENDPOINTS.UPDATE_ADMIN}/${data.userId}`,
        data.bodyData,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API
      console.log("Ok");
      console.log(response);
      return response.data; // Retourne les données en cas de succès
    } catch (error) {
      console.log("No");
      console.log(error);

      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone deleteUser
export const deleteUser = createAsyncThunk(
  "user/deleteUser", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      console.log("Data to send");
      console.log(data);
      const response = await axiosInstance.delete(
        `${API_ENDPOINTS.DELETE_USER}/${data.userId}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API
      console.log("Ok");
      console.log(response);
      return { id: data.userId }; // Retourne les données en cas de succès
    } catch (error) {
      console.log("No");
      console.log(error);

      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone deleteAdmin
export const deleteAdmin = createAsyncThunk(
  "user/deleteAdmin", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      console.log("Data to send");
      console.log(data);
      const response = await axiosInstance.delete(
        `${API_ENDPOINTS.DELETE_ADMIN}/${data.userId}`,
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API
      console.log("Ok");
      console.log(response);
      return { id: data.userId }; // Retourne les données en cas de succès
    } catch (error) {
      console.log("No");
      console.log(error);

      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone activateAdmin
export const activateAdmin = createAsyncThunk(
  "user/activateAdmin", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      console.log("Data to send");
      console.log(data);
      const response = await axiosInstance.put(
        `${API_ENDPOINTS.ACTIVATE_DEACTIVATE_ADMIN}/${data.userId}`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API
      console.log("Ok");
      console.log(response);
      return { id: data.userId, status: data.status }; // Retourne les données en cas de succès
    } catch (error) {
      console.log("No");
      console.log(error);

      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Définir l'action asynchrone activateUser
export const activateUser = createAsyncThunk(
  "user/activateUser", // Nom de l'action suivant la convention de nommage <sliceName>/<actionName>
  async (data, { rejectWithValue }) => {
    try {
      console.log("Data to send");
      console.log(data);
      const response = await axiosInstance.put(
        `${API_ENDPOINTS.ACTIVATE_DEACTIVATE_USER}/${data.userId}`,
        data.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${data.token}`,
          },
        }
      ); // Appel API
      console.log("Ok");
      console.log(response);
      return { id: data.userId, status: data.status }; // Retourne les données en cas de succès
    } catch (error) {
      console.log("No");
      console.log(error);

      return rejectWithValue(error.response.data); // Retourne l'erreur en cas d'échec
    }
  }
);

// Créer le slice user
export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    setUser: (state, action) => {
      const json = getStorage(state.storeKey);
      if (json === "none") state.saveLocal = "none";
      if (json !== null && json !== "none") {
        state.saveLocal = json.saveLocal;
        state.user = json.user;
      }
      console.log("Valeur du state: ");
      console.log(state.user);
      updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
    },
    saveLocal: (state, action) => {
      if (typeof action.payload !== typeof undefined) {
        state.saveLocal = action.payload;
      }
      const userObj = {
        saveLocal: state.saveLocal,
        storeKey: state.storeKey,
        user: _.cloneDeep(state.user),
      };
      updateStorage(state.saveLocal, state.storeKey, userObj);
    },

    setUserVerificationData: (state, action) => {
      state.user.manage.userVerification.data = action.payload;

      updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
    },
    // Vous pouvez ajouter des reducers synchrones ici si nécessaire
  },
  extraReducers: (builder) => {
    builder
      // Gérer l'état "pending"  (en cours)
      .addCase(loginUser.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(logoutUser.pending, (state) => {
        state.user.operation.loading = false;
        state.user.operation.error = null;
      })
      .addCase(addUser.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(searchUsers.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(getUserVerificationRequest.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(certifyUserAsConfirmedDriver.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(rejectUserAsConfirmedDriver.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(getAllAdmins.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(addRole.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(updateRole.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(deleteRole.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(getAllRoles.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(getAllPermissions.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(deleteUser.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(deleteAdmin.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(updateUser.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(updateAdmin.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })
      .addCase(activateAdmin.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })

      .addCase(activateUser.pending, (state) => {
        state.user.operation.loading = true;
        state.user.operation.error = null;
      })

      // Gérer l'état "fulfilled" (succès)
      .addCase(loginUser.fulfilled, (state, action) => {
        console.log("state avant login: ");
        console.log(state.user.data);
        state.user.operation.loading = false;
        state.user.data = action.payload;

        console.log("state après login: ");
        console.log(state.user.data);

        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));

        //Navigation vers la page de vérification d'otp
        window.location.href = "/auth/two-factor";
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        console.log("state avant login: ");
        console.log(state.user.data);
        //Navigation vers la page de vérification d'otp
        window.location.href = "/auth/sign-in";
        state.user.operation.loading = false;
        state.user = initialUserState.user;

        console.log("state après login: ");
        console.log(state.user.data);

        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.user.operation.loading = false;
        const currentUserListEmployees = state.user.manage.userListEmployees;
        currentUserListEmployees.push(action.payload);
        state.user.manage.userListEmployees = currentUserListEmployees;
        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
        Swal.fire({
          icon: "success",
          title: "Succès...",
          text: "Utilisateur enregistré!",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user.operation.loading = false;

        //mise à jour local sans appel
        const userId = action.payload.id;
        const currentUserListMobile = state.user.manage.userListMobile.filter(
          (userMobile) => userMobile.id !== userId
        );

        currentUserListMobile.push(action.payload);

        state.user.manage.userListMobile = currentUserListMobile;
        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));

        Swal.fire({
          icon: "success",
          title: "Succès...",
          text: "Utilisateur modifié!",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        state.user.operation.loading = false;

        //mise à jour local sans appel
        const userId = action.payload.id;
        const currentUserListEmployees =
          state.user.manage.userListEmployees.filter(
            (userEmployee) => userEmployee.id !== userId
          );

        currentUserListEmployees.push(action.payload);

        state.user.manage.userListEmployees = currentUserListEmployees;
        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));

        Swal.fire({
          icon: "success",
          title: "Succès...",
          text: "Utilisateur modifié!",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.user.operation.loading = false;
        state.user.manage.userListMobile = action.payload;

        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
      })
      .addCase(searchUsers.fulfilled, (state, action) => {
        state.user.operation.loading = false;
        state.user.manage.userListMobile = action.payload;

        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
      })
      .addCase(getUserVerificationRequest.fulfilled, (state, action) => {
        state.user.operation.loading = false;
        state.user.manage.userVerification.requests = action.payload;

        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
      })

      .addCase(getAllAdmins.fulfilled, (state, action) => {
        state.user.operation.loading = false;
        state.user.manage.userListEmployees = action.payload;

        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
      })
      .addCase(addRole.fulfilled, (state, action) => {
        state.user.operation.loading = false;
        state.user.manage.roles.push(action.payload);
        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
        console.log(
          "$$$$$$$$$$$$$$$$$$$$$$$$$$**************************$$$$$$$$$$$$$$$$$$$$$$$$$$"
        );
        console.log(state.user.manage.roles);
        console.log(action.payload);
        Swal.fire({
          icon: "success",
          title: "Succès...",
          text: "Rôle enregistré!",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.user.operation.loading = false;

        const roleName = action.payload.roleName;
        // const currentroles = state.user.manage.roles.filter(
        //   (role) => role.name != roleName
        // );

        // currentroles.push(action.payload)

        // state.user.manage.roles = currentroles
        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
        console.log(
          "$$$$$$$$$$$$$$$$$$$$$$$$$$**************************$$$$$$$$$$$$$$$$$$$$$$$$$$"
        );
        console.log(state.user.manage.roles);
        console.log(action.payload);
        Swal.fire({
          icon: "success",
          title: "Succès...",
          text: "Rôle enregistré!",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.user.operation.loading = false;
        state.user.manage.roles = action.payload;

        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = { deleteRole: "ok" };
        window.location.reload();
        // alert(action.payload.roleName)
        const roleName = action.payload.roleName;
        const currentroles = state.user.manage.roles.filter(
          (role) => role.name != roleName
        );
        state.user.manage.roles = currentroles;

        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));

        // state.user.manage.roles = action.payload;

        // updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.user.operation.loading = false;
        state.user.manage.permissions = action.payload;

        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.user.operation.loading = false;

        //mise à jour local sans appel
        const userId = action.payload.id;
        const currentUserListMobile = state.user.manage.userListMobile.filter(
          (userMobile) => userMobile.id !== userId
        );

        state.user.manage.userListMobile = currentUserListMobile;
        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));

        Swal.fire({
          icon: "success",
          title: "Succès...",
          text: "Utilisateur supprimé!",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })

      .addCase(certifyUserAsConfirmedDriver.fulfilled, (state, action) => {
        state.user.operation.loading = false;

        //mise à jour local sans appel
        const userId = action.payload.id;
        const currentuserVerificationRequests =
          state.user.manage.userVerification.requests.map((request) => {
            if (request.idUser === userId) {
              request.status = "ACCEPTED";
            }
            return request;
          });

        state.user.manage.userVerification.requests =
          currentuserVerificationRequests;
        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));

        Swal.fire({
          icon: "success",
          title: "Succès...",
          text: "Utilisateur certifié!",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(rejectUserAsConfirmedDriver.fulfilled, (state, action) => {
        state.user.operation.loading = false;

        //mise à jour local sans appel
        const userId = action.payload.id;
        const currentuserVerificationRequests =
          state.user.manage.userVerification.requests.map((request) => {
            if (request.idUser === userId) {
              request.status = "REJECTED";
            }
            return request;
          });

        state.user.manage.userVerification.requests =
          currentuserVerificationRequests;
        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));

        Swal.fire({
          icon: "success",
          title: "Succès...",
          text: "Utilisateur rejété!",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(deleteAdmin.fulfilled, (state, action) => {
        state.user.operation.loading = false;

        //mise à jour local sans appel
        const userId = action.payload.id;
        const currentUserListEmployees =
          state.user.manage.userListEmployees.filter(
            (userEmployee) => userEmployee.id !== userId
          );

        state.user.manage.userListEmployees = currentUserListEmployees;
        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));

        Swal.fire({
          icon: "success",
          title: "Succès...",
          text: "Employé supprimé!",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })

      .addCase(activateAdmin.fulfilled, (state, action) => {
        state.user.operation.loading = false;

        //mise à jour local sans appel
        const userId = action.payload.id;
        const status = action.payload.status;
        const currentUserListEmployees =
          state.user.manage.userListEmployees.map((userEmployee) => {
            if (userEmployee.id === userId) {
              console.log("Quel est la nouvelle valeur à mettre : " + status);
              userEmployee.accountActif = status;
            }
            return userEmployee;
          });

        state.user.manage.userListEmployees = currentUserListEmployees;
        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));

        Swal.fire({
          icon: "success",
          title: "Succès...",
          text: status ? "Employé activé!" : "Employé désactivé!",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(activateUser.fulfilled, (state, action) => {
        state.user.operation.loading = false;

        //mise à jour local sans appel
        const userId = action.payload.id;
        const status = action.payload.status;
        const currentUserListMobile = state.user.manage.userListMobile.map(
          (userMobile) => {
            if (userMobile.id === userId) {
              console.log("Quel est la nouvelle valeur à mettre : " + status);
              userMobile.accountActif = status;
            }
            return userMobile;
          }
        );

        state.user.manage.userListMobile = currentUserListMobile;
        updateStorage(state.saveLocal, state.storeKey, createUserObj(state));

        Swal.fire({
          icon: "success",
          title: "Succès...",
          text: status ? "Utilisateur activé!" : "Utilisateur désactivé!",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })

      // Gérer l'état "rejected" (échec)
      .addCase(loginUser.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        Swal.fire({
          icon: "error",
          title: "Échec...",
          text: "Utilisateur non enregistré! \n ",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        Swal.fire({
          icon: "error",
          title: "Échec...",
          text: `${action.payload.message}`,
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        Swal.fire({
          icon: "error",
          title: "Échec...",
          text: `${action.payload.message}`,
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
      })
      .addCase(searchUsers.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        state.user.manage.userListMobile = [];

        console.log("error search ");
        console.log(action.payload);
      })
      .addCase(getUserVerificationRequest.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
      })
      .addCase(getAllAdmins.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
      })
      .addCase(addRole.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        Swal.fire({
          icon: "error",
          title: "Échec...",
          text: "Rôle non enregistré! \n ",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        Swal.fire({
          icon: "error",
          title: "Échec...",
          text: "Rôle non enregistré! \n ",
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(getAllRoles.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        // alert("________________________________________________________________________")
        Swal.fire({
          icon: "error",
          title: "Échec...",
          text: `${action.payload.message}`,
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(getAllPermissions.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        Swal.fire({
          icon: "error",
          title: "Échec...",
          text: `${action.payload.message}`,
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })

      .addCase(certifyUserAsConfirmedDriver.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        Swal.fire({
          icon: "error",
          title: "Échec...",
          text: `${action.payload.message}`,
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })

      .addCase(rejectUserAsConfirmedDriver.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        Swal.fire({
          icon: "error",
          title: "Échec...",
          text: `${action.payload.message}`,
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })

      .addCase(deleteAdmin.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        Swal.fire({
          icon: "error",
          title: "Échec...",
          text: `${action.payload.message}`,
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(activateAdmin.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        Swal.fire({
          icon: "error",
          title: "Échec...",
          text: `${action.payload.message}`,
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      })
      .addCase(activateUser.rejected, (state, action) => {
        state.user.operation.loading = false;
        state.user.operation.error = action.payload;
        Swal.fire({
          icon: "error",
          title: "Échec...",
          text: `${action.payload.message}`,
          backdrop: `rgba(60,60,60,0.8)`,
          footer: "",
        });
      });
  },
});

export default userSlice.reducer;
