import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

//router
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";

import Index from "./views/index";
import { LandingModulesRouter } from "./views/modules/landing-pages/router/landing-modules-router";

//store

import { Provider } from "react-redux";
//reducer
import { store } from "./store";
import { IndexRouters } from "./router";

const router = createBrowserRouter(
  [
    {
      path: "/",
      //element: <Index />,
      element: <Navigate to="/auth/sign-in" replace />, // Redirige vers /auth/signauth
    },
    ...IndexRouters,
    ...LandingModulesRouter,
  ],
  { basename: process.env.PUBLIC_URL }
);
ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <Provider store={store}>
      <App>
        <RouterProvider router={router}></RouterProvider>
      </App>
    </Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
