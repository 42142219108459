import React, { memo, Fragment, useRef, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Image, Collapse } from "react-bootstrap";

//components
import Card from "../../../components/bootstrap/card";

//Hooks
import useDataTable from "../../../components/hooks/useDatatable";

import $ from "jquery";
// img
import shap1 from "../../../assets/images/shapes/01.png";
import shap2 from "../../../assets/images/shapes/02.png";
import shap3 from "../../../assets/images/shapes/03.png";
import shap4 from "../../../assets/images/shapes/04.png";
import shap5 from "../../../assets/images/shapes/05.png";
import shap6 from "../../../assets/images/shapes/06.png";
import { Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import ActivityIndicator from "../../../components/partials/dashboard/mainstyle/activity-indicator";

import { Sweetalert } from "../../../components/partials/dashboard/mainstyle/sweet-alert";

import avatars11 from "../../../assets/images/avatars/01.png";
import avatars22 from "../../../assets/images/avatars/avtar_1.png";
import avatars33 from "../../../assets/images/avatars/avtar_2.png";
import avatars44 from "../../../assets/images/avatars/avtar_3.png";
import avatars55 from "../../../assets/images/avatars/avtar_4.png";
import avatars66 from "../../../assets/images/avatars/avtar_5.png";

import Circularprogressbar from "../../../components/circularprogressbar";

//Img
import img1 from "../../../assets/images/table/1.png";
import img2 from "../../../assets/images/table/2.png";
import img3 from "../../../assets/images/table/3.png";
import img4 from "../../../assets/images/table/4.png";
import img5 from "../../../assets/images/table/5.png";
import img6 from "../../../assets/images/table/6.png";
import img7 from "../../../assets/images/table/7.png";
import img8 from "../../../assets/images/table/8.png";
import img9 from "../../../assets/images/table/9.png";

// Router
import { Link } from "react-router-dom";

//Components
import FilterRating from "../components/filter-rating";
import FilterOptions from "../components/filter-options";
import RangeSlider from "../components/range-slider";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/css";

SwiperCore.use([Autoplay]);

const RatingFollowUpList = memo(() => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(true);
  let history = useNavigate();
  const [originalData, setOriginalData] = useState([
    {
      id: 1,
      image: img1,
      name: "Anna Sthesia",
      contact: "(760) 756 7568",
      email: "annasthesia@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "conducteur",
      rate: 4,
    },
    {
      id: 2,
      image: img2,
      name: "Brock Lee",
      contact: "+62 5689 458 658",
      email: "brocklee@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "conducteur",
      rate: 3,
    },
    {
      id: 3,
      image: img3,
      name: "Dan Druff",
      contact: "+55 6523 456 856",
      email: "dandruff@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "passager",
      rate: 2,
    },
    {
      id: 4,
      image: img4,
      name: "Hans Olo",
      contact: "+91 2586 253 125",
      email: "hansolo@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "passager",
      rate: 5,
    },
    {
      id: 5,
      image: img5,
      name: "Lynn Guini",
      contact: "+27 2563 456 589",
      email: "lynnguini@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "passager",
      rate: 1,
    },
    {
      id: 6,
      image: img6,
      name: "Eric Shun",
      contact: "+55 25685 256 589",
      email: "ericshun@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "passager",
      rate: 0,
    },
    {
      id: 7,
      image: img7,
      name: "aaronottix",
      contact: "(760) 756 7568",
      email: "budwiser@ymail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "passager",
      rate: 2,
    },
    {
      id: 8,
      image: img8,
      name: "Marge Arita",
      contact: "+27 5625 456 589",
      email: "margearita@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "conducteur",
      rate: 1,
    },
    {
      id: 9,
      image: img6,
      name: "Bill Dabear",
      contact: "+55 2563 456 589",
      email: "billdabear@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "conducteur",
      rate: 2,
    },
  ]);
  const [tableData, setTableData] = useState([
    {
      id: 1,
      image: img1,
      name: "Anna Sthesia",
      contact: "(760) 756 7568",
      email: "annasthesia@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "conducteur",
      rate: 4,
    },
    {
      id: 2,
      image: img2,
      name: "Brock Lee",
      contact: "+62 5689 458 658",
      email: "brocklee@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "conducteur",
      rate: 3,
    },
    {
      id: 3,
      image: img3,
      name: "Dan Druff",
      contact: "+55 6523 456 856",
      email: "dandruff@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "passager",
      rate: 2,
    },
    {
      id: 4,
      image: img4,
      name: "Hans Olo",
      contact: "+91 2586 253 125",
      email: "hansolo@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "passager",
      rate: 5,
    },
    {
      id: 5,
      image: img5,
      name: "Lynn Guini",
      contact: "+27 2563 456 589",
      email: "lynnguini@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "passager",
      rate: 1,
    },
    {
      id: 6,
      image: img6,
      name: "Eric Shun",
      contact: "+55 25685 256 589",
      email: "ericshun@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "passager",
      rate: 0,
    },
    {
      id: 7,
      image: img7,
      name: "aaronottix",
      contact: "(760) 756 7568",
      email: "budwiser@ymail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "passager",
      rate: 2,
    },
    {
      id: 8,
      image: img8,
      name: "Marge Arita",
      contact: "+27 5625 456 589",
      email: "margearita@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "conducteur",
      rate: 1,
    },
    {
      id: 9,
      image: img6,
      name: "Bill Dabear",
      contact: "+55 2563 456 589",
      email: "billdabear@gmail.com",
      status: "Activé",
      date: "2019/12/01",
      color: "success",
      role: "conducteur",
      rate: 2,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [typeOfUser, setTypeOfUser] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //footer: '<a href="">Voir les détails de l\'utilisateur?</a>',

  const typeUserOptions = [
    { value: "tout", label: "Conducteur && Passager" },
    { value: "conducteur", label: "Conducteur" },
    { value: "passager", label: "Passager" },
  ];

  function convertTo100Scale(scoreOutOf5) {
    if (scoreOutOf5 < 0 || scoreOutOf5 > 5) {
      return "Error: The score must be between 0 and 5.";
    }
    return (scoreOutOf5 / 5) * 100;
  }

  const ShowRowRating = (scoreOutOf5) => {
    let rating1 = "gary";
    let rating2 = "gary";
    let rating3 = "gary";
    let rating4 = "gary";
    let rating5 = "gary";
    switch (scoreOutOf5) {
      case 0:
        break;

      case 1:
        rating1 = "orange";
        break;

      case 2:
        rating1 = "orange";
        rating2 = "orange";
        break;

      case 3:
        rating1 = "orange";
        rating2 = "orange";
        rating3 = "orange";
        break;

      case 4:
        rating1 = "orange";
        rating2 = "orange";
        rating3 = "orange";
        rating4 = "orange";
        break;

      case 5:
        rating1 = "orange";
        rating2 = "orange";
        rating3 = "orange";
        rating4 = "orange";
        rating5 = "orange";
        break;
      default:
        console.log("La note n'est pas bonne");
    }

    return (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 20 20"
          fill={rating1}
          className="ms-1"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 20 20"
          fill={rating2}
          className="ms-1"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 20 20"
          fill={rating3}
          className="ms-1"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 20 20"
          fill={rating4}
          className="ms-1"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20px"
          height="20px"
          viewBox="0 0 20 20"
          fill={rating5}
          className="ms-1"
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
      </>
    );
  };

  const handleFilter = (e) => {
    // Ici, vous pouvez ajouter la logique pour filtrer les données en fonction des champs de filtre
    const filteredData = tableData.filter((user) => {
      return (
        //(!startDate || user.date >= startDate) &&
        //(!endDate || user.date <= endDate) &&
        (!typeOfUser || user.role === typeOfUser.value) &&
        (!userName || user.name.includes(userName)) &&
        (!email || user.email.includes(email)) &&
        (!birthdate || user.birthdate === birthdate)
      );
    });

    setTimeout(() => {
      setIsLoading(false);
      setLoadingText("");
      setTableData(filteredData);
    }, 5000);
  };

  // Fonction pour afficher les détails d'un utilisateur
  const handleDetails = (row) => {
    history("/dashboard/app/user-profile", { state: row });
  };

  useEffect(() => {
    handleFilter();
  }, [typeOfUser]);

  return (
    <Fragment>
      <Row>
        <Col lg="9">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0">Suivi des évaluations</h4>
            <ul
              className="nav nav-pills mb-0 iq-product-filter d-flex bg-transparent align-items-center"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item dropdown d-none d-xl-block">
                <div className="form-group input-group mb-0 search-input ">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <span className="input-group-text">
                    <svg
                      width="20"
                      className="icon-20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="11.7669"
                        cy="11.7666"
                        r="8.98856"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></circle>
                      <path
                        d="M18.0186 18.4851L21.5426 22"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <span>Filter</span>
          </div>
          {/* <div className="d-flex mb-4 mt-2 align-items-center">
            <div className="bg-primary-subtle rounded-pill iq-custom-badge">
              5 Star
              <Button className="btn btn-primary btn-sm rounded-pill iq-cancel-btn">
                <svg
                  width="14"
                  height="14"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="icon-14"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </Button>
            </div>
            <div className="bg-primary-subtle rounded-pill iq-custom-badge">
              Watch
              <Button className="btn btn-primary btn-sm rounded-pill iq-cancel-btn btn btn-primary">
                <svg
                  width="14"
                  height="14"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="icon-14"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </Button>
            </div>
            <div className="bg-primary-subtle rounded-pill iq-custom-badge">
              10% off
              <Button className="btn btn-primary btn-sm rounded-pill iq-cancel-btn btn btn-primary">
                <svg
                  width="14"
                  height="14"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="icon-14"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </Button>
            </div>
          </div> */}
          <div className=" row tab-content" id="pills-tabContent">
            {tableData.map((user) => {
              return (
                <Col
                  lg="4"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDetails(user)}
                >
                  <Card>
                    <Card.Body>
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="d-flex flex-column text-center align-items-center justify-content-between ">
                          <div className="fs-italic">
                            <h5> {user.name}</h5>
                            <div className="text-muted-50 mb-1">
                              <small>{user.role}</small>
                            </div>
                          </div>
                          <div className="text-black-50 text-warning">
                            {ShowRowRating(user.rate)}
                          </div>
                          <div className="card-profile-progress">
                            <div className="circle-progress  circle-progress-basic circle-progress-primary">
                              <Circularprogressbar
                                stroke="#3A57E8"
                                Linecap="rounded"
                                trailstroke="#ddd"
                                strokewidth="4px"
                                width="100"
                                height="100"
                                value={convertTo100Scale(user.rate)}
                              ></Circularprogressbar>
                            </div>
                            <Image
                              className="theme-color-default-img  img-fluid rounded-circle card-img"
                              src={user.image}
                              alt="profile-pic"
                            />
                            <Image
                              className="theme-color-purple-img img-fluid rounded-circle card-img"
                              src={avatars22}
                              alt="profile-pic"
                            />
                            <Image
                              className="theme-color-blue-img img-fluid rounded-circle card-img"
                              src={avatars33}
                              alt="profile-pic"
                            />
                            <Image
                              className="theme-color-green-img img-fluid rounded-circle card-img"
                              src={avatars55}
                              alt="profile-pic"
                            />
                            <Image
                              className="theme-color-yellow-img img-fluid rounded-circle card-img"
                              src={avatars66}
                              alt="profile-pic"
                            />
                            <Image
                              className="theme-color-pink-img img-fluid rounded-circle card-img"
                              src={avatars44}
                              alt="profile-pic"
                            />
                          </div>
                          <div className="mt-3 text-center text-muted-50">
                            <p>
                              Slate helps you see how many more days you need
                            </p>
                          </div>
                          {/* <div className="d-flex icon-pill">
                            <Link
                              to="#"
                              className="btn btn-sm rounded-pill px-2 py-2 ms-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-primary"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-sm rounded-pill px-2 py-2  ms-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-danger"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                              </svg>
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-sm rounded-pill px-2 py-2 ms-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="text-success"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </Link>
                          </div> */}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </div>
          <div className="text-center mb-4">
            <Button className="btn btn-primary">Load More</Button>
          </div>
        </Col>
        <Col lg="3">
          <Card className="iq-filter-card">
            <Card.Header className="border-bottom px-0 py-4 mx-4">
              <h4 className="list-main mb-0">Filters</h4>
            </Card.Header>
            <Card.Body>
              {/* Filtres existants */}
              <Button
                href="#"
                onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                bsPrefix="bg-transparent iq-custom-collapse w-100 d-flex justify-content-between pb-3"
              >
                <h5 className="mb-0">Categories</h5>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    ></path>
                  </svg>
                </i>
              </Button>
              <Collapse in={open}>
                <div id="iq-product-filter-01">
                  <div className="mt-2">
                    {/* <span>Price</span>
                    <RangeSlider /> */}
                    <div className="product-ratings mt-2">
                      <h5 className="py-3 mb-0">Avg. Customer Review</h5>
                      <div>
                        <FilterRating
                          id="01"
                          count={5}
                          count1={0}
                          productChecked="Checked"
                        />
                        <FilterRating id="02" count={4} count1={1} />
                        <FilterRating id="03" count={3} count1={2} />
                        <FilterRating id="04" count={2} count1={3} />
                        <FilterRating id="05" count={1} count1={4} />
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>

              <Button
                href="#"
                onClick={() => setOpen2(!open2)}
                aria-controls="example-collapse-text"
                aria-expanded={open2}
                bsPrefix="bg-transparent iq-custom-collapse w-100 d-flex justify-content-between pb-3"
              >
                <h5 className="mb-0">User Informations</h5>
                <i className="right-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    ></path>
                  </svg>
                </i>
              </Button>
              <Collapse in={open2}>
                <div id="iq-product-filter-01">
                  <div className="mt-2">
                    <Row>
                      <Col className="mb-3">
                        <Form.Label htmlFor="startDate">
                          {t("startDate")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          id="startDate"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <Form.Label htmlFor="endDate">
                          {t("endDate")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          id="endDate"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {" "}
                      <Col className="mb-3">
                        <Form.Label htmlFor="typeOfUser">
                          {t("typeOfUser")}
                        </Form.Label>
                        <Select
                          data-trigger
                          name="choices-single-default"
                          id="typeOfUser"
                          options={typeUserOptions}
                          value={typeOfUser}
                          onChange={(selectedOption) =>
                            setTypeOfUser(selectedOption)
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <Form.Label htmlFor="userName">
                          {" "}
                          {t("userName")}{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          id="userName"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <Form.Label htmlFor="email"> {t("email")} </Form.Label>
                        <Form.Control
                          type="email"
                          id="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <Form.Label htmlFor="birthdate">
                          {t("birthdate")}
                        </Form.Label>
                        <Form.Control
                          type="date"
                          id="birthdate"
                          value={birthdate}
                          onChange={(e) => setBirthdate(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Collapse>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

RatingFollowUpList.displayName = "RatingFollowUpList";
export default RatingFollowUpList;
